import { useEffect, useState } from "react";
import AOS from "aos";
import { useDispatch, useSelector } from "react-redux";
import AppUrlListener from "./AppUrlListener";
import { App as CapacitorApp } from "@capacitor/app";

import "aos/dist/aos.css";
import "./assets/scss/themes.scss";
import "react-image-gallery/styles/scss/image-gallery.scss";
import "animate.css";

import Routes from "./config/routes/routes";
import Popups from "components/Popups";
import PopupsListener from "components/PopupsListener";
import { initApp } from "redux/auth/actions";
import "./helpers/momentInit";
import ProfileBlockedListener from "components/ProfileBlockedListener";
import FeedbackButton from "components/FeedbackButton";
import Afilliate from "components/Afilliate";
import NotificationWrapper from "components/notification/NotificationWrapper";
import localStorage from "services/localStorage";
import connectingService from "services/connectingService";
import AppServices from "services/app";
import { getIsDesktopWeb, getIsMobileWeb, getIsPlatformWeb } from "redux/init/selectors";
import logoD4d from "assets/images/icons/logoD4d22.png";
import { PushNotifications } from "@capacitor/push-notifications";
import useNavigator from "hooks/useNavigator";
import { Capacitor } from "@capacitor/core";

const App = () => {
	const dispatch = useDispatch();
	const isPlatformWeb = useSelector(getIsDesktopWeb);
	const navigator = useNavigator();
	const [isConnectToDb, setIsConnectToDb] = useState("");
	const loginFromManager = localStorage.get("loginFrommanager");

	useEffect(() => {
		AOS.init({
			//disable: "phone",
		});
		AOS.refresh();
	}, []);

	useEffect(() => {
		dispatch(initApp());
	}, []);

	/* 	useEffect(() => {
		let subscription;
		if (subscription) {
			subscription.unsubscribe();
		}
		subscription = connectingService.checkConnections().subscribe((data) => {
			if (data) {
				setIsConnectToDb(true);
			} else {
				if (isConnectToDb) {
					window.location.reload();
				}
			}
		});
	}, [isConnectToDb]); */

	useEffect(() => {
		let subscription;
		if (subscription) {
			subscription.unsubscribe();
		}
		subscription = connectingService.checkConnections().subscribe((data) => {
			if (data) {
				setIsConnectToDb(true);
			} else {
				/* if (isConnectToDb)
					alert(
						"ה​יי, נראה שיש איזושהי בעיית תקשורת במכשיר שלך, עליך לנסות להתחבר / לפתוח את האפליקציה מחדש."
					); */
				//setIsConnectToDb(false);
			}
		});
	}, [isConnectToDb]);

	useEffect(() => {
		CapacitorApp.addListener("backButton", ({ canGoBack }) => {
			debugger;
			if (!canGoBack) {
				CapacitorApp.exitApp();
			} else {
				if (window.history.length < 4) {
					CapacitorApp.exitApp();
				}
				window.history.back();
			}
		});
		//prevents the browser from scrolling when you press on go back btn
		if ("scrollRestoration" in window.history) {
			window.history.scrollRestoration = "manual";
		}
	}, []);

	useEffect(() => {
		try {
			const isPushNotificationsAvailable = Capacitor.isPluginAvailable("PushNotifications");
			if (isPushNotificationsAvailable)
				PushNotifications.addListener("pushNotificationActionPerformed", async (notification) => {
					if (notification?.notification?.data?.route) {
						navigator.redirectWithRefreshTo("/" + notification?.notification?.data?.route);
					}
				});
		} catch (error) {}
	}, []);

	return (
		<div className={loginFromManager ? `isManager` : ""}>
			{!false ? (
				<Routes>
					<Afilliate />
					<AppUrlListener />
					<Popups />
					<PopupsListener />
					<ProfileBlockedListener />
					<FeedbackButton />
					<NotificationWrapper />
				</Routes>
			) : (
				<>
					<div style={{ textAlign: "center", marginTop: "80px", fontSize: "40px" }}>
						<img style={{ marginBottom: "30px" }} src={logoD4d}></img>
						<div>האתר אינו זמין כרגע בגרסת דסקטופ</div>
					</div>
				</>
			)}
		</div>
	);
};

export default App;
