import { Box } from "@mui/material";
import giftCard from "assets/images/giftCard/giftCard.png";

export default function HeaderPurchaseGiftCard({ data = null }) {
	return (
		<Box className={`headerPurchaseGiftCard ${data?.url ? "headerPurchaseGiftCardCardcom" : ""}`}>
			<img src={giftCard} className="giftCardImg" />
			{!data?.url ? (
				<>
					<span style={{ maxWidth: "320px" }}>{"איזה כיף שיש אנשים שחושבים על אחרים"}</span>
					<b className="boldText" style={{ fontWeight: "400", fontSize: "46px" }}>
						{"ומוכנים"}
					</b>
					<b className="boldText" style={{ width: "max-content" }}>
						{"לעשות המון"}
					</b>
					<b className="boldText">{"בשביל שיצליחו!"}</b>
				</>
			) : (
				<span
					style={{
						maxWidth: "320px",
					}}>{`שובר מנוי מתנה לשליש גן עדן סמארט ל-3 חודשים עבור ${data?.phone_receiver}`}</span>
			)}
		</Box>
	);
}
