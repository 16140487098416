import { getCountryCallingCode, isValidNumber as isValid } from "libphonenumber-js";
import { isValidPhoneNumber } from "react-phone-number-input";

const COUNTRY = "IL";

export const getPhoneWithPrefix = (phone) => {
	return `+${getCountryCallingCode(COUNTRY)} ${phone}`;
};

export const isValidNumber = (number) => {
	return isValid(number, COUNTRY);
};

export const isValidSelectNumber = (number) => {
	return isValidPhoneNumber(number);
};

export const isIsraeliPhoneNumber = (phone) => {
	if (!phone) return false;
	const israelPhoneRegex =
		/^(?:(?:(\+?972|\(\+?972\)|\+?\(972\))(?:\s|\.|-)?([1-9]\d?))|(0[23489]{1})|(0[57]{1}[0-9]))(?:\s|\.|-)?([^0\D]{1}\d{2}(?:\s|\.|-)?\d{4})$/;
	return israelPhoneRegex.test(phone);
};
