import produce from "immer";

import * as types from "./actionTypes";
import * as authTypes from "redux/auth/actionTypes";
import * as blocksTypes from "../blocks/actionTypes";
import ApiRequestStatus from "consts/apiRequestStatus";

const initialState = {
	users: {},
	status: ApiRequestStatus.INIT,
	loadChatsNumber: 30,
	hasMoreChats: false,
	resetUsersOnce: false,
};

export default produce((state, action) => {
	switch (action.type) {
		case types.CHAT_RECEIVED_FETCH_SUCCESSFULLY:
			return { ...state, ...action.payload, status: ApiRequestStatus.SUCCESSFULLY };
		case types.CHAT_RECEIVED_UPDATED:
		case types.CHAT_RECEIVED_ADDED:
			return { ...state, users: { ...state.users, ...action.payload } };
		case types.CHAT_RECEIVED_REMOVED:
			delete state.users[action.payload];
			break;
		case types.RESET_CHAT_RECEIVED_USERS_SUCCESSFULLY:
			return {
				...action.payload,
				users: { ...state.users, ...action.payload.users },
			};

		case blocksTypes.BLOCKS_BLOCK_USER:
			delete state.users[action.user];
			break;
		case authTypes.AUTH_LOG_OUT:
			return initialState;
		case types.UPDATE_MORE_NUMBER:
			return {
				...state,
				hasMoreChats: !!action.payload?.hasMoreChats,
				loadChatsNumber: action.payload?.loadChatsNumber,
			};
	}
}, initialState);
