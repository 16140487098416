import i18next from "i18next";
import { Button, Modal, ModalHeader, ModalFooter, ModalBody } from "reactstrap";

import usePopups from "hooks/usePopups";

const DELETE_REASONS = [
	"FoundLove",
	"MarriedThanksToJSMART",
	"WantABreak",
	"NotLikeJSMART",
	"WantToStartOver",
	"Other",
];

export default () => {
	const { closePopup, showDeleteAccountAddMessagePopup } = usePopups();

	const handleOnReasonClick = (reasonKey) => {
		showDeleteAccountAddMessagePopup({
			reason: i18next.t(`Delete account.Options.${reasonKey}`),
		});
	};

	return (
		<Modal isOpen toggle={closePopup} className="simpleDialog">
			<div className="user-chat-nav ">
				<Button
					color="none"
					type="button"
					onClick={closePopup}
					className="nav-btn"
					id="user-profile-hide">
					<i className="ri-close-line"></i>
				</Button>
			</div>
			<ModalHeader>{i18next.t("Delete account.Choose Delete reason")}</ModalHeader>
			<ModalBody>
				{DELETE_REASONS.map((reasonKey) => (
					<h1
						onClick={() => handleOnReasonClick(reasonKey)}
						className="font-size-16 select-reason-delete-account">
						{process.env.REACT_APP_ENV === "shlish"
							? i18next.t(`Delete account.OptionsD4d.${reasonKey}`)
							: i18next.t(`Delete account.Options.${reasonKey}`)}
					</h1>
				))}
			</ModalBody>
			<ModalFooter>
				<Button
					outline
					size="sm"
					color="primary"
					onClick={closePopup}
					className="btn-rounded littleBtn">
					{i18next.t("Delete account.Cancel")}
				</Button>
			</ModalFooter>
		</Modal>
	);
};
