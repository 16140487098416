import { createSelector } from "reselect";
import AppPlatforms from "consts/AppPlatforms";

export const getInit = (state) => state.init;

export const getIsPlatformWeb = createSelector(getInit, (init) => {
	return init.platform === AppPlatforms.WEB || init.platform === AppPlatforms.MOBILEWEB;
});

export const getIsMobileWeb = createSelector(getInit, (init) => {
	return init.platform === AppPlatforms.MOBILEWEB;
});

export const getIsDesktopWeb = createSelector(getInit, (init) => {
	console.log(init.platform);
	return init.platform === AppPlatforms.WEB;
});

export const getIsPlatformIOS = createSelector(getInit, (init) => {
	return init.platform === AppPlatforms.IOS;
});

export const getIsPlatformAndroid = createSelector(getInit, (init) => {
	return init.platform === AppPlatforms.ANDROID;
});

export const getPlatform = createSelector(getInit, (init) => {
	return init.platform;
});
