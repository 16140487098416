import { Modal, ModalBody, ModalHeader, Button, ModalFooter } from "reactstrap";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isNil } from "lodash";
import usePopups from "hooks/usePopups";
import { purchaseWithCardcom, purchaseWithGoogle } from "redux/subscription/actions";
import {
	getCardComUrl,
	getFetchCardcomApiStatus,
	getSale,
	getSubscriptionPlans,
	getUserSubscriptionType,
} from "redux/subscription/selectors";
import IframeContainer from "./IframeContainer";
import { getUserProfile } from "redux/profile/selectors";
import { getGoogleApiStatus } from "redux/subscription/selectors";
import ApiRequestStatus from "consts/apiRequestStatus";
import Loader from "components/Loader";
import { getPopups, isSale } from "redux/popups/selectors";
import tokenService from "services/token.service";
import "assets/scss/popups/buyWithToken.scss";
import { Box } from "@mui/material";
import whiteLogo from "assets/images/whiteLogo.png";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import subscriptionTypesConst from "consts/subscription";
import i18next from "i18next";

export default function SaleModal() {
	const ref = useRef(false);

	const fetchCardcomApiStatus = useSelector(getFetchCardcomApiStatus);
	const cardComUrl = useSelector(getCardComUrl);
	const googleApiStatus = useSelector(getGoogleApiStatus);
	const sale = useSelector(getSale);

	const [hasToken, setHasToken] = useState("");
	const [tokenData, setTokenData] = useState("");
	const [selectedPlan, setSelectedPlan] = useState();
	const [showIframe, setShowIframe] = useState(false);

	const loggedInUserProfile = useSelector(getUserProfile);
	const { cardcomClearing } = useSelector(getPopups);

	const dispatch = useDispatch();

	const { closePopup, showSubscriptionSuccessfullyPopup, showBuyWithTokenPopup } = usePopups();

	const onContinuePurchase = () => {
		if (hasToken) {
			closePopup();
			return showBuyWithTokenPopup({ tokenData, selectedPlan, withoutReturn: true });
		}

		if (selectedPlan?.google_only) {
			dispatch(purchaseWithGoogle(selectedPlan));
		} else {
			dispatch(purchaseWithCardcom(selectedPlan, showSubscriptionSuccessfullyPopup));
			setShowIframe(true);
		}
	};
	const openInNewTab = (e) => {
		e.preventDefault();
		window.open("/terms", "_blank", "toolbar=0,location=0,menubar=0,width=600,height=600");
	};

	const getHeaderText = () => {
		const saleHeader = (sale?.content?.title || "").replace(
			"{userName}",
			loggedInUserProfile?.name ?? ""
		);
		const saleSubTitle1 = (sale?.content?.subTitle1 || "").replace(
			"{userName}",
			loggedInUserProfile?.name ?? ""
		);

		return (
			<div style={{ fontSize: "22px", lineHeight: "1.3" }}>
				<b style={{ fontSize: "26px", fontWeight: "bold" }}>{saleHeader}</b>
				<br />
				{saleSubTitle1}
				<b style={{ fontSize: "32px", marginTop: "30px", display: "block" }}>
					{`מנוי ל ${selectedPlan?.sub_long} ${i18next.t(
						`Subscription.Times.${selectedPlan?.sub_time}`
					)}`}
					<br />
					{`ב ${selectedPlan?.monthly_price} ש"ח לחודש בלבד`}
				</b>
			</div>
		);
	};
	const getBodyText = () => {
		const saleSubTitle2 = (sale?.content?.subTitle2 || "").replace(
			"{userName}",
			loggedInUserProfile?.name ?? ""
		);

		return (
			<div className="termsPurch" style={{ fontSize: "17px", marginTop: "30px" }}>
				{saleSubTitle2}
				<br />
				{`ההצעה בתוקף לזמן מוגבל בלבד ובכפוף`}
				<a className="underLine" onClick={openInNewTab}>
					{" לתקנון."}
				</a>
				<br />

				<br />
				<b
					style={{
						fontSize: "19px",
						display: "block",
					}}>{`סיפור אהבה גדול, יכול להתחיל מצעד אחד קטן 💘`}</b>
				<b
					style={{
						fontSize: "19px",
						display: "block",
					}}>{`שליש גן עדן - מחתנים הכי הרבה זוגות בישראל - בהתחייבות!`}</b>
			</div>
		);
	};

	useEffect(() => {
		if (!!cardcomClearing?.selectedPlan) {
			dispatch(
				purchaseWithCardcom(cardcomClearing.selectedPlan, showSubscriptionSuccessfullyPopup)
			);
			setShowIframe(true);
		}
	}, [cardcomClearing]);

	useEffect(async () => {
		const res = await tokenService.getUserTokenData();
		setHasToken(!!res.data?.success);

		if (res.data?.success) {
			setTokenData(res.data?.data || {});
		}
	}, []);

	useEffect(async () => {
		if (!sale || !sale?.plans[0]) return;
		setSelectedPlan(sale?.plans[0]);
	}, [sale]);

	useEffect(() => {
		if (!ref.current) {
			ref.current = true;
		} else {
			if (googleApiStatus === ApiRequestStatus.SUCCESSFULLY) {
				closePopup();
			}
		}
	}, [googleApiStatus]);

	return (
		<Modal
			isOpen={true}
			toggle={closePopup}
			className={showIframe ? "subscriptionDialog" : "simpleDialog buyWithToken"}>
			{!showIframe ? (
				<>
					{googleApiStatus === ApiRequestStatus.PENDING && <Loader />}
					<div className="user-chat-nav">
						<Button
							color="none"
							type="button"
							onClick={closePopup}
							className="nav-btn"
							id="user-profile-hide">
							<ArrowBackIosNewIcon style={{ color: "#fff", transform: "rotate(180deg)" }} />
						</Button>
					</div>
					<Box className="colorHeader">
						<img src={whiteLogo} alt="" height={"50px"} />
					</Box>

					<ModalHeader style={{ marginTop: "20px" }}>{getHeaderText()}</ModalHeader>
					<ModalBody style={{ flex: "1", padding: "1.5rem" }}>{getBodyText()}</ModalBody>

					<ModalFooter style={{ display: "flex", flexDirection: "column", marginBottom: "56px" }}>
						<Button
							outline
							size="sm"
							color="primary"
							style={{ minWidth: "fit-content", padding: "8px 20px" }}
							onClick={onContinuePurchase}
							className="btn-rounded approvePurchesBtn"
							disabled={isNil(googleApiStatus !== ApiRequestStatus.PENDING)}>
							{`${loggedInUserProfile.gender ? "רכשי" : "רכוש"} עכשיו את המנוי במבצע!`}
						</Button>
					</ModalFooter>
				</>
			) : (
				<ModalBody>
					<IframeContainer
						apiStatus={fetchCardcomApiStatus}
						url={cardComUrl}
						onBack={() => setShowIframe(false)}
					/>
				</ModalBody>
			)}
		</Modal>
	);
}
