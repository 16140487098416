import { combineReducers } from "redux";

import chat from "./chat/reducer";
import chatReceived from "./chatReceived/reducer";
import chatReceivedFiltered from "./chatReceivedFiltered/reducer";
import chatSent from "./chatSent/reducer";
import publicProfiles from "./publicProfiles/reducer";
import chatMeta from "./chatMeta/reducer";
import blocks from "./blocks/reducer";
import presence from "./presence/reducer";
import chatMessages from "./chatMessages/reducer";
import subscription from "./subscription/reducer";
import system from "./system/reducer";
import likes from "./likes/reducer";
import skips from "./skips/reducer";
import userProfile from "./profile/reducer";
import general from "./general/reducer";
import promotions from "./promotions/reducer";
import auth from "./auth/reducer";
import galleryModal from "./galleryModal/reducer";
import popups from "./popups/reducer";
import qrcode from "./qrcode/reducer";
import likedMe from "./likedMe/reducer";
import search from "./search/reducer";
import matches from "./matches/reducer";
import massletter from "./massLetter/reducer";
import viewedMe from "./viewedMe/reducer";
import views from "./views/reducer";
import letPictures from "./letPictures/reducer";
import letPicturesMe from "./letPicturesMe/reducer";
import init from "./init/reducer";
import notification from "./notification/reducers";
import promotionsSale from "./promotionsSale/reducer";

export default combineReducers({
	notification,
	auth,
	chat,
	chatReceived,
	chatReceivedFiltered,
	chatSent,
	publicProfiles,
	chatMeta,
	blocks,
	presence,
	chatMessages,
	subscription,
	system,
	likes,
	skips,
	userProfile,
	general,
	promotions,
	promotionsSale,
	galleryModal,
	popups,
	qrcode,
	likedMe,
	search,
	matches,
	massletter,
	viewedMe,
	views,
	letPictures,
	letPicturesMe,
	init,
});
