import { Card } from "reactstrap";
import { useState } from "react";
import { useLongPress } from "use-long-press";

import CardBody from "./CardBody";
import BasicDetails from "./BasicDetails";
import ActionButtons from "./ActionButtons";
import isMobile from "helpers/isMobile";
import { isUserLoggedIn } from "redux/auth/selectors";
import { useSelector } from "react-redux";

export default ({
	user,
	likedMePage = false,
	viewedMePage = false,
	effortBtn = "",
	templates,
	...rest
}) => {
	const [mouseIsOverCard, setMouseIsOverCard] = useState(false);
	const { updateSelectedUser, selectedUsers, deletable } = rest;
	const isLoggedIn = useSelector(isUserLoggedIn);

	const onMouseOver = () => !isMobile() && setMouseIsOverCard(true);

	const onMouseOut = () => !isMobile() && setMouseIsOverCard(false);

	const onLongPress =
		deletable && isMobile()
			? useLongPress(() => {
					if (!isMobile() || selectedUsers.size > 0) return;
					updateSelectedUser(true, user.uid);
			  })
			: {};

	const isNewCard = (likedMePage && user.likes_unviewed) || (viewedMePage && user.views_unviewed);

	return (
		<Card
			className={`imgContainer ${isNewCard ? "newCard" : ""}`}
			onMouseOver={onMouseOver}
			onMouseLeave={onMouseOut}
			{...onLongPress}>
			<div className={`cardImgBtnTxt`}>
				<BasicDetails user={user} mouseIsOverCard={mouseIsOverCard} {...rest} />
				{isLoggedIn && <ActionButtons user={user} effortBtn={effortBtn} templates={templates} />}
			</div>
			<CardBody user={user} />
		</Card>
	);
};
