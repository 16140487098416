import { QRCodeSVG } from "qrcode.react";

const CustomQRCode = ({ url, ...rest }) => {
	return (
		<div>
			<QRCodeSVG
				value={url}
				size={256} // Size of the QR code
				bgColor="#ffffff" // Background color
				fgColor="#000000" // Foreground color (QR color)
				level="H" // Error correction level (L, M, Q, H)
				includeMargin={true} // Includes margin around QR code
				{...rest}
			/>
		</div>
	);
};

export default CustomQRCode;
