import firebase from "firebase/app";
import "firebase/database";
import httpsCallable from "./httpsCallable";
import QueueService from "./queue";
class SwipeService {
	async addSwipe(uid, uidColleague, value) {
		QueueService.sendEvent(uid, "Swipe", {
			uidColleague,
			value,
		});
		return;
	}
}

export default new SwipeService();
