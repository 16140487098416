import firebase from "firebase/app";
import "firebase/database";
import { Observable } from "rxjs";

class PresenceService {
	DELAY_PRESENCE_REF = "delay_presence";

	async fetchOnlineUsers() {
		return null;
		return (await firebase.database().ref(this.DELAY_PRESENCE_REF).once("value")).val();
	}

	listenOnlineUsersChildAdded() {
		return new Observable((subscriber) => {
			const ref = firebase.database().ref(this.DELAY_PRESENCE_REF);

			const callbackFn = ref.on(
				"child_added",
				(snapshot) => subscriber.next(snapshot.key),
				(error) => subscriber.error(error)
			);
			return () => ref.off("child_added", callbackFn);
		});
	}

	listenOnlineUsersChildRemoved() {
		return new Observable((subscriber) => {
			const ref = firebase.database().ref(this.DELAY_PRESENCE_REF);

			const callbackFn = ref.on(
				"child_removed",
				(snapshot) => subscriber.next(snapshot.key),
				(error) => subscriber.error(error)
			);
			return () => ref.off("child_removed", callbackFn);
		});
	}
}

export default new PresenceService();
