import { Box, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BlogCommentBox from "./BlogCommentBox";

export default function BlogCommentsList({
	comments = [],
	articleData = {},
	commentsProfiles = [],
}) {
	const getUserProfileFromList = (uid) => {
		if (!uid || !commentsProfiles?.length) return {};
		return commentsProfiles.find((comProfile) => comProfile.uid === uid);
	};

	return (
		<Box className="commentsListWrapper">
			{!!comments?.length && <h5>{`${comments?.length} תגובות`}</h5>}
			<Box className="commentsList">
				{!!comments &&
					comments.map((comment) => {
						return (
							<BlogCommentBox
								comProfile={getUserProfileFromList(comment?.uid)}
								articleData={articleData}
								key={comment.key}
								comment={comment}></BlogCommentBox>
						);
					})}
			</Box>
		</Box>
	);
}
