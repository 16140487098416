import { mergeMap, map, takeUntil, catchError, repeat, switchMap } from "rxjs/operators";
import { ofType } from "redux-observable";
import { of } from "rxjs";

import ChatSentService from "services/chatSent";

import * as types from "./actionTypes";
import * as authTypes from "../auth/actionTypes";
import { getLoggedInUserUID } from "../auth/selectors";

export const fetchChatSent = (action$, store) =>
	action$.pipe(
		ofType(authTypes.AUTH_LOGGED_IN),
		switchMap(async () => {
			try {
				const payload = {};
				return {
					type: types.CHAT_SENT_FETCH_SUCCESSFULLY,
					payload: {
						users: { ...payload },
						hasMoreChats: !(Object.keys(payload || {})?.length < 30),
					},
				};
			} catch (error) {
				return {
					type: types.CHAT_SENT_FETCH_FAILED,
					error: error?.message,
				};
			}
		}),
		catchError((error) => {
			return of({
				type: types.CHAT_SENT_FETCH_FAILED,
				payload: error?.message,
			});
		})
	);
export const resetChatSent = (action$, store) =>
	action$.pipe(
		ofType(types.RESET_CHAT_SENT_USERS),
		switchMap(async () => {
			try {
				const payload = await ChatSentService.fetchChatSent();

				return {
					type: types.RESET_CHAT_SENT_USERS_SUCCESSFULLY,
					payload: {
						users: { ...payload },
						hasMoreChats: !(Object.keys(payload || {})?.length < 30),
						loadChatsNumber: 30,
						status: "SUCCESSFULLY",
						resetUsersOnce: true,
					},
				};
			} catch (error) {
				return {
					type: types.CHAT_SENT_FETCH_FAILED,
					error: error?.message,
				};
			}
		}),
		catchError((error) => {
			return of({
				type: types.CHAT_SENT_FETCH_FAILED,
				payload: error?.message,
			});
		})
	);

export const fetchChatSentAdded = (action$, store) =>
	action$.pipe(
		ofType(types.CHAT_SENT_FETCH_SUCCESSFULLY),
		switchMap(() => {
			const state = store.value;
			const uid = getLoggedInUserUID(state);
			console.log("listenChatSentAdded");

			return ChatSentService.listenChatSentAdded(uid).pipe(
				map((change) => ({
					type: types.CHAT_SENT_ADDED_SUCCESSFULLY,
					payload: change,
				}))
			);
		}),
		takeUntil(action$.pipe(ofType(authTypes.AUTH_LOG_OUT))),
		catchError((error) => {
			return of({
				type: types.CHAT_SENT_ADDED_ERROR,
				payload: error?.message,
			});
		}),
		repeat()
	);

export const fetchChatSentUpdate = (action$, store) =>
	action$.pipe(
		ofType(types.CHAT_SENT_FETCH_SUCCESSFULLY),
		mergeMap(() => {
			const state = store.value;
			const uid = getLoggedInUserUID(state);
			return ChatSentService.listenChatSentUpdates(uid).pipe(
				map((change) => ({
					type: types.CHAT_SENT_UPDATED_SUCCESSFULLY,
					payload: change,
				}))
			);
		}),
		takeUntil(action$.pipe(ofType(authTypes.AUTH_LOG_OUT))),
		catchError((error) => {
			return of({
				type: types.CHAT_SENT_UPDATED_ERROR,
				payload: error?.message,
			});
		}),
		repeat()
	);

export const fetchChatSentRemoved = (action$, store) =>
	action$.pipe(
		ofType(types.CHAT_SENT_FETCH_SUCCESSFULLY),
		mergeMap(() => {
			const state = store.value;
			const uid = getLoggedInUserUID(state);
			return ChatSentService.listenChatSentRemoved(uid).pipe(
				map((change) => ({
					type: types.CHAT_SENT_REMOVED_SUCCESSFULLY,
					payload: change,
				}))
			);
		}),
		takeUntil(action$.pipe(ofType(authTypes.AUTH_LOG_OUT))),
		catchError((error) => {
			return of({
				type: types.CHAT_SENT_REMOVED_ERROR,
				payload: error?.message,
			});
		}),
		repeat()
	);
