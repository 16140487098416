import "firebase/database";
import { Observable } from "rxjs";
import httpsCallable from "./httpsCallable";
class TokenService {
	async payByUserWithCardcomToken(numOfPayments, planId) {
		const result = await httpsCallable.call("payByUserWithCardcomToken", {
			numOfPayments,
			planId,
		});
		return result;
	}
	async payPromotionWithCardcomToken(numOfPayments, planId) {
		const result = await httpsCallable.call("payPromotionWithCardcomToken", {
			numOfPayments,
			planId,
		});
		return result;
	}
	async payVideoDateWithToken(numOfPayments, event) {
		const result = await httpsCallable.call("payVideoDateWithToken", {
			numOfPayments,
			event,
		});
		return result;
	}
	async getUserTokenData() {
		const result = await httpsCallable.call("getUserTokenData", {});

		return result;
	}
}

export default new TokenService();
