import "assets/scss/blog/blogComments.scss";
import BlogAddComment from "./BlogAddComment";
import BlogCommentsList from "./BlogCommentsList";
import { useEffect, useState } from "react";
import blogService from "services/blogService";
import { getUserProfile } from "redux/profile/selectors";
import { useSelector } from "react-redux";
import publicProfile from "services/publicProfile";

export default function BlogComments({ articleData = {} }) {
	const [articleComments, setArticleComments] = useState([]);
	const [commentsProfiles, setCommentsProfiles] = useState([]);
	const userProfile = useSelector(getUserProfile) || {};

	const isUserAlreadyWriteComment = () => {
		if (!articleComments?.length) return false;
		const userComment = articleComments.find((comment) => comment?.uid === userProfile?.uid);
		if (!userComment) return false;
		return true;
	};

	useEffect(() => {
		if (!articleData?.key) return;

		const subscription = blogService.listenComments(articleData.key).subscribe((artComments) => {
			if (artComments) {
				const artCommentsList = Object.values(artComments || {});
				setArticleComments(artCommentsList.reverse());

				const uidsCommentsList = artCommentsList
					.map((comment) => comment?.uid)
					.filter((uid) => !!uid);

				const promises = [];
				uidsCommentsList.map((uid) => promises.push(publicProfile.fetchProfile(uid)));
				Promise.all(promises).then((profiles) => {
					setCommentsProfiles(profiles);
				});
			} else {
				setArticleComments([]);
			}
		});
		return () => {
			subscription?.unsubscribe();
		};
	}, []);

	return (
		<div className="blogComments">
			{!!userProfile?.uid && !isUserAlreadyWriteComment() && (
				<BlogAddComment articleData={articleData} />
			)}
			<BlogCommentsList
				articleData={articleData}
				comments={articleComments}
				commentsProfiles={commentsProfiles}
			/>
		</div>
	);
}
