import { mergeMap, map, takeUntil, catchError, repeat, switchMap } from "rxjs/operators";
import { ofType } from "redux-observable";
import { of } from "rxjs";

import ChatReceivedFilteredService from "services/chatReceivedFiltered";

import * as types from "./actionTypes";
import * as authTypes from "../auth/actionTypes";
import { getLoggedInUserUID } from "../auth/selectors";

export const fetchChatReceivedFiltered = (action$, store) =>
	action$.pipe(
		ofType(authTypes.AUTH_LOGGED_IN),
		switchMap(async () => {
			try {
				const payload = {};
				return {
					type: types.CHAT_RECEIVED_FILTERED_FETCH_SUCCESSFULLY,
					payload: {
						users: { ...payload },
						hasMoreChats: !(Object.keys(payload || {})?.length < 30),
					},
				};
			} catch (error) {
				return {
					type: types.CHAT_RECEIVED_FILTERED_FETCH_FAILED,
					error: error?.message,
				};
			}
		}),
		catchError((error) => {
			return of({
				type: types.CHAT_RECEIVED_FILTERED_FETCH_FAILED,
				payload: error?.message,
			});
		})
	);
export const resetChatReceivedFiltered = (action$, store) =>
	action$.pipe(
		ofType(types.RESET_CHAT_RECEIVED_FILTERED_USERS),
		switchMap(async () => {
			try {
				const payload = await ChatReceivedFilteredService.fetchChatReceivedFiltered();

				return {
					type: types.RESET_CHAT_RECEIVED_FILTERED_USERS_SUCCESSFULLY,
					payload: {
						users: { ...payload },
						hasMoreChats: !(Object.keys(payload || {})?.length < 30),
						loadChatsNumber: 30,
						status: "SUCCESSFULLY",
						resetUsersOnce: true,
					},
				};
			} catch (error) {
				return {
					type: types.CHAT_RECEIVED_FILTERED_FETCH_FAILED,
					error: error?.message,
				};
			}
		}),
		catchError((error) => {
			return of({
				type: types.CHAT_RECEIVED_FILTERED_FETCH_FAILED,
				payload: error?.message,
			});
		})
	);

export const fetchChatReceivedFilteredAdded = (action$, store) =>
	action$.pipe(
		ofType(authTypes.AUTH_LOGGED_IN),
		switchMap(() => {
			const state = store.value;
			const uid = getLoggedInUserUID(state);
			return ChatReceivedFilteredService.listenChatReceivedFilteredAdded(uid).pipe(
				map((change) => ({
					type: types.CHAT_RECEIVED_FILTERED_ADDED_SUCCESSFULLY,
					payload: change,
				}))
			);
		}),
		takeUntil(action$.pipe(ofType(authTypes.AUTH_LOG_OUT))),
		catchError((error) => {
			return of({
				type: types.CHAT_RECEIVED_FILTERED_ADDED_FAILED,
				payload: error?.message,
			});
		}),
		repeat()
	);

export const fetchChatReceivedFilteredUpdated = (action$, store) =>
	action$.pipe(
		ofType(authTypes.AUTH_LOGGED_IN),
		mergeMap(() => {
			const state = store.value;
			const uid = getLoggedInUserUID(state);
			return ChatReceivedFilteredService.listenChatReceivedFilteredUpdates(uid).pipe(
				map((change) => ({
					type: types.CHAT_RECEIVED_FILTERED_UPDATED_SUCCESSFULLY,
					payload: change,
				}))
			);
		}),
		takeUntil(action$.pipe(ofType(authTypes.AUTH_LOG_OUT))),
		catchError((error) => {
			return of({
				type: types.CHAT_RECEIVED_FILTERED_UPDATED_FAILED,
				payload: error?.message,
			});
		}),
		repeat()
	);

export const fetchChatReceivedFilteredRemoved = (action$, store) =>
	action$.pipe(
		ofType(authTypes.AUTH_LOGGED_IN),
		mergeMap(() => {
			const state = store.value;
			const uid = getLoggedInUserUID(state);
			return ChatReceivedFilteredService.listenChatReceivedFilteredRemoved(uid).pipe(
				map((change) => ({
					type: types.CHAT_RECEIVED_FILTERED_REMOVED_SUCCESSFULLY,
					payload: change,
				}))
			);
		}),
		takeUntil(action$.pipe(ofType(authTypes.AUTH_LOG_OUT))),
		catchError((error) => {
			return of({
				type: types.CHAT_RECEIVED_FILTERED_REMOVED_FAILED,
				payload: error?.message,
			});
		}),
		repeat()
	);
