import "assets/scss/soon.scss";
import React, { useState, useEffect } from "react";

import image from "assets/images/soon/soon.png";
import logo from "assets/images/soon/logo.png";
export default () => {
	const targetDate = "2024-01-04T13:00:00";

	return (
		<div className="soonPage bg-color">
			<img className="top-image" src={image} />
			<div className="bottom-div">
				<div className="wrap-img">
					<img src={logo} />
				</div>
				<div className="desc">
					אנחנו עובדים על פינישים אחרונים וממש בקרוב האפליקציה תהיה זמינה. נעדכן אותך מיד כשזה יקרה.
				</div>

				<Timer targetDate={targetDate} />
			</div>
		</div>
	);
};

const Timer = ({ targetDate }) => {
	const calculateTimeRemaining = () => {
		const now = new Date().getTime();
		const targetTime = new Date(targetDate).getTime();
		const timeRemaining = targetTime - now;

		if (timeRemaining <= 0) {
			// Timer expired
			return { days: 0, hours: 0, minutes: 0, seconds: 0 };
		}

		const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
		const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
		const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
		const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

		return { days, hours, minutes, seconds };
	};

	const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

	useEffect(() => {
		const timerInterval = setInterval(() => {
			setTimeRemaining(calculateTimeRemaining());
		}, 1000);

		// Cleanup interval on component unmount
		return () => clearInterval(timerInterval);
	}, [targetDate]);

	return (
		<div className="timer">
			<div className="timer-box">
				<div className="number">{timeRemaining.seconds}</div>
				<div className="type-timer">שניות</div>
			</div>
			<div className="timer-box">
				<div className="number">{timeRemaining.minutes}</div>
				<div className="type-timer">דקות</div>
			</div>
			<div className="timer-box">
				<div className="number">{timeRemaining.hours}</div>
				<div className="type-timer">שעות</div>
			</div>
			<div className="timer-box">
				<div className="number">{timeRemaining.days}</div>
				<div className="type-timer">ימים</div>
			</div>
		</div>
	);
};
