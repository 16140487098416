import firebase from "firebase/app";
import "firebase/storage";
import httpsCallable from "./httpsCallable";

class dynemicPopupsService {
	BASE_REF = "site_popups";
	USERS_SITE_POPUPS = "users_site_popups";

	async getDynemicPopups() {
		return (
			await firebase
				.database()
				.ref(this.BASE_REF)
				.orderByChild("active")
				.equalTo(true)
				.once("value")
		).val();
	}

	async getDynemicPopup() {
		return (
			await firebase
				.database()
				.ref(this.BASE_REF)
				.orderByChild("active")
				.equalTo(true)
				.limitToLast(1)
				.once("value")
		).val();
	}

	async getUserDynemicPopups(uid) {
		if (!uid) return;
		return (
			await firebase
				.database()
				.ref(this.USERS_SITE_POPUPS)
				.child(uid)

				.once("value")
		).val();
	}
	async getUserDynemicPopup(uid, popupKey) {
		if (!uid || !popupKey) return;
		return (
			await firebase
				.database()
				.ref(this.USERS_SITE_POPUPS)
				.child(popupKey)
				.child(uid)

				.once("value")
		).val();
	}

	async setUserShowPopup(key) {
		const result = await httpsCallable.call("setUserShowPopup", { key });
		return result;
	}
}

export default new dynemicPopupsService();
