import "assets/scss/custom/pages/commonTemplateSpeedDate.scss";
import { Box } from "@mui/material";
import videoDatingHome from "assets/images/videoDatingHome.png";
import videoDatingHomeNarrow from "assets/images/videoDatingHomeNarrow.png";
import videoDatingLogoBlue from "assets/images/videoDatingLogoBlue.png";
import { useEffect } from "react";

export default function CommonTemplate({
	bluePage = false,
	wideImg = true,
	withBlueImg = false,
	children,
}) {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Box
			className={`commonTemplateSpeedDateWrapper ${bluePage ? "bluePage" : "whitePage"} ${
				wideImg ? "wideImg" : "narrowImg"
			}`}>
			<Box className="imgHeader">
				<img src={wideImg ? videoDatingHome : videoDatingHomeNarrow} alt="image" />
				{withBlueImg && wideImg && (
					<img className="logoBlue" src={videoDatingLogoBlue} alt="image" />
				)}
			</Box>
			{children}
		</Box>
	);
}
