import { Button, ModalBody, Modal, ModalHeader, ModalFooter } from "reactstrap";
import usePopups from "hooks/usePopups";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getPopups } from "redux/popups/selectors";
import { getIsPlatformAndroid, getIsPlatformIOS } from "redux/init/selectors";
import { getUserProfile } from "redux/profile/selectors";
import { NativeSettings, AndroidSettings, IOSSettings } from "capacitor-native-settings";
export default function PhoneNotificationSettingsModal() {
	const { closePopup } = usePopups();
	const isPlatformIOS = useSelector(getIsPlatformIOS);
	const isPlatformAndroid = useSelector(getIsPlatformAndroid);
	const currentUser = useSelector(getUserProfile);
	const [loading, setLoading] = useState(false);

	const openPhoneSettings = async () => {
		if (!!isPlatformAndroid) {
			await NativeSettings.openAndroid({ option: AndroidSettings.ApplicationDetails });
		} else if (!!isPlatformIOS) {
			await NativeSettings.openIOS({
				option: IOSSettings.App,
			});
		}
		closePopup();
	};

	useEffect(() => {}, []);
	return (
		<Modal
			isOpen={true}
			toggle={closePopup}
			className={`simpleDialog PhoneNotificationSettingsModal`}>
			{/* <div className="user-chat-nav ">
				<Button
					color="none"
					type="button"
					onClick={closePopup}
					className="nav-btn"
					id="user-profile-hide">
					<i className="ri-close-line"></i>
				</Button>
			</div> */}

			<ModalHeader>
				<div style={{ whiteSpace: "pre-wrap" }}>
					{`היי ${currentUser?.name ?? ""},`}
					<h2>{`${currentUser?.gender ? "את מפספסת" : "אתה מפספס"} בענק!`}</h2>
				</div>
			</ModalHeader>

			<ModalBody>
				<div style={{ whiteSpace: "pre-wrap", lineHeight: "1.3" }}>{`כדי לא לפספס הודעות והתאמות ❤️
עליך להדליק את ההתראות בהגדרות המכשיר שלך.`}</div>
			</ModalBody>
			<ModalFooter>
				<Button
					outline
					size="sm"
					color="primary"
					onClick={openPhoneSettings}
					className="btn-rounded littleBtn">
					{`פתיחת הגדרות המכשיר`}
				</Button>
			</ModalFooter>
		</Modal>
	);
}
