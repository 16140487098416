import { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import heartBl from "assets/images/icons/heartBl.svg";
import { addLikesUser, likeToggle } from "redux/likes/actions";
import { isLike } from "redux/likes/selectors";
import usePopups from "hooks/usePopups";
import { getUserProfile } from "redux/profile/selectors";
import likeService from "services/likes";
import { checkRelevantQuota } from "helpers/quota";
import { getUserQuotaStatus } from "redux/general/selectors";

export default ({ user, forceLike = false }) => {
	const userUid = user.uid;
	const dispatch = useDispatch();
	const userQuotaStatus = useSelector(getUserQuotaStatus);

	const checkIfLikedUser = () => {
		if (!userUid) return false;
		likeService.checkIfLikedUser(userUid).then((res) => {
			if (res) {
				dispatch(addLikesUser(res));
				return true;
			}
			return false;
		});
	};

	const _isLike = (userUid && (useSelector(isLike(userUid)) || checkIfLikedUser())) || false;

	const [like, setLike] = useState(_isLike);
	const { showNotApprovedMessagePopup, showQuotaPopup } = usePopups();
	const { approved } = useSelector(getUserProfile);

	useEffect(() => {
		setLike(_isLike);
	}, [_isLike]);

	const onLike = async () => {
		if (userQuotaStatus && (await checkRelevantQuota())) return showQuotaPopup();
		if (approved) {
			setLike(!like);
			dispatch(likeToggle(user, userUid, forceLike));
		} else {
			showNotApprovedMessagePopup(user);
		}
	};

	return (
		<Button onClick={onLike} outline className={`like ${like ? "liked" : ""}`}>
			<img src={heartBl} />
		</Button>
	);
};
