import { mergeMap, catchError, repeat, map, filter } from "rxjs/operators";
import { ofType } from "redux-observable";
import { of, first } from "rxjs";
import { sortBy } from "lodash";

import SubscriptionService from "services/subscription";
import * as types from "./actionTypes";
import * as AuthTypes from "../auth/actionTypes";
import * as popupTypes from "../popups/actionTypes";
import { getLoggedInUserUID } from "../auth/selectors";
import SubscriptionPlansService from "services/subscriptionPlans";
import AdminActionService from "services/adminAction";
import { purchaseWithCardcomSuccessfully } from "./actions";
import SubscriptionCallService from "services/subscriptionCall";
import InappPurchaseService from "services/inappPurchase";
import { getIsPlatformAndroid } from "redux/init/selectors";
import { getSubscriptionPlans } from "./selectors";
import purchaseService from "services/purchase.service";
import purchaseTypes from "consts/purchaseTypes";

export const fetchSubscription = (action$, store) =>
	action$.pipe(
		ofType(AuthTypes.AUTH_LOGGED_IN),
		mergeMap(() => {
			const state = store.value;
			const loggedInUserUID = getLoggedInUserUID(state);
			return SubscriptionService.fetchUserSubscription(loggedInUserUID).pipe(
				map((payload) => ({
					type: types.SUBSCRIPTIONS_FETCH_SUCCESSFULLY,
					payload,
				}))
			);
		}),
		catchError((error) => {
			return of({
				type: types.SUBSCRIPTIONS_FETCH_FAILED,
				payload: error?.message,
			});
		}),
		repeat()
	);

export const fetchSubscriptionPlans = (action$) =>
	action$.pipe(
		ofType(AuthTypes.AUTH_LOGGED_IN),
		mergeMap(async () => {
			try {
				const plans = await SubscriptionPlansService.fetchSubscriptionPlans();
				const subscriptionsPlansNotSorted = Object.keys(plans || {}).map((key) => ({
					...plans[key],
					key,
				}));
				const payload = sortBy(subscriptionsPlansNotSorted, "index");
				return {
					type: types.SUBSCRIPTIONS_FETCH_PLANS_SUCCESSFULLY,
					payload,
				};
			} catch (error) {
				return {
					type: types.SUBSCRIPTIONS_FETCH_PLANS_FAILED,
					payload: error?.message,
				};
			}
		}),
		catchError((error) => {
			return of({
				type: types.SUBSCRIPTIONS_FETCH_PLANS_FAILED,
				payload: error?.message,
			});
		}),
		repeat()
	);

export const fetchCardCom = (action$) =>
	action$.pipe(
		ofType(types.SUBSCRIPTIONS_PURCHASE_WITH_CARDCOM),
		mergeMap(async ({ plan, showSuccessfullyPopup }) => {
			try {
				const response = await SubscriptionCallService.pay(plan.key);
				purchaseService.updatePurchaseIsStart(purchaseTypes.subscription);

				return {
					type: types.SUBSCRIPTIONS_GET_CARDCOM_RESPONSE_SUCCESSFULLY,
					response,
					showSuccessfullyPopup,
				};
			} catch (error) {
				return {
					type: types.SUBSCRIPTIONS_GET_CARDCOM_RESPONSE_FAILED,
					payload: error?.message,
				};
			}
		}),
		catchError((error) => {
			return of({
				type: types.SUBSCRIPTIONS_GET_CARDCOM_RESPONSE_FAILED,
				payload: error?.message,
			});
		}),
		repeat()
	);

export const orderGoogleIAP = (action$) =>
	action$.pipe(
		ofType(types.SUBSCRIPTIONS_PURCHASE_WITH_GOOGLE),
		mergeMap(async ({ plan }) => {
			try {
				//InappPurchaseService.order(plan.google_play_id);
				return {
					type: "aaa", //types.SUBSCRIPTIONS_GOOGLE_ORDERED_SUCCESSFULLY,
				};
			} catch (error) {
				return {
					type: types.SUBSCRIPTIONS_GOOGLE_ORDERED_FAILED,
					payload: error?.message,
				};
			}
		}),
		catchError((error) => {
			return of({
				type: types.SUBSCRIPTIONS_GOOGLE_APPROVED_FAILED,
				payload: error?.message,
			});
		}),
		repeat()
	);

export const initSubscriptionModal = (action$) =>
	action$.pipe(
		ofType(popupTypes.SHOW_POPUP),
		mergeMap(async ({ payload }) => {
			try {
				if (payload.popupType === "SubscriptionModal") {
					return {
						type: types.SUBSCRIPTION_POPUP_INIT_SUCCESSFULLY,
					};
				} else {
					return {
						type: types.SUBSCRIPTION_POPUP_INIT_UNNECESSARY,
					};
				}
			} catch (error) {
				return {
					type: types.SUBSCRIPTION_POPUP_INIT_FAILED,
					payload: error?.message,
				};
			}
		}),
		catchError((error) => {
			return of({
				type: types.SUBSCRIPTION_POPUP_INIT_FAILED,
				payload: error?.message,
			});
		}),
		repeat()
	);

export const listenGoogleIAPStatusChanged = (action$, store) =>
	action$.pipe(
		ofType(types.SUBSCRIPTIONS_FETCH_PLANS_SUCCESSFULLY),
		mergeMap(() => {
			try {
				const state = store.value;
				const userUid = getLoggedInUserUID(state);
				const isPlatformAndroid = getIsPlatformAndroid(state);

				if (isPlatformAndroid) {
					const plans = getSubscriptionPlans(state);
					const activePlans = plans.filter((plan) => plan.google_only && plan.active);
					InappPurchaseService.register(activePlans, userUid);
					return InappPurchaseService.listenStatusChanged(activePlans).pipe(
						map(({ status, product }) => {
							return {
								type: types.SUBSCRIPTIONS_GOOGLE_STATUS_CHANGED,
								payload: {
									product,
									status,
								},
							};
						})
					);
				} else {
					return {
						type: types.SUBSCRIPTIONS_GOOGLE_LISTEN_UNNECESSARY,
					};
				}
			} catch (error) {
				return {
					type: types.SUBSCRIPTIONS_GOOGLE_LISTEN_FAILED,
					payload: error?.message,
				};
			}
		}),
		catchError((error) => {
			return of({
				type: types.SUBSCRIPTIONS_GOOGLE_LISTEN_FAILED,
				payload: error?.message,
			});
		}),
		repeat()
	);

export const listenGoogleIAPStoreError = (action$, store) =>
	action$.pipe(
		ofType(types.SUBSCRIPTIONS_FETCH_PLANS_SUCCESSFULLY),
		mergeMap(() => {
			try {
				return {
					type: types.SUBSCRIPTIONS_GOOGLE_STORE_ERROR_FAILED,
				};
				const state = store.value;
				const isPlatformAndroid = getIsPlatformAndroid(state);
				if (isPlatformAndroid) {
					return InappPurchaseService.listenStoreErrors().pipe(
						map((err) => {
							return {
								type: types.SUBSCRIPTIONS_GOOGLE_STORE_ERROR,
								payload: err,
							};
						})
					);
				} else {
					return {
						type: types.SUBSCRIPTIONS_GOOGLE_STORE_ERROR_FAILED,
					};
				}
			} catch (error) {
				return {
					type: types.SUBSCRIPTIONS_GOOGLE_STORE_ERROR_FAILED,
					payload: error?.message,
				};
			}
		}),
		catchError((error) => {
			return of({
				type: types.SUBSCRIPTIONS_GOOGLE_LISTEN_FAILED,
				payload: error?.message,
			});
		}),
		repeat()
	);

export const listenerCardcomPurchase = (action$, store) =>
	action$.pipe(
		ofType(types.SUBSCRIPTIONS_GET_CARDCOM_RESPONSE_SUCCESSFULLY),
		mergeMap(({ response, showSuccessfullyPopup }) => {
			try {
				const state = store.value;
				const userUid = getLoggedInUserUID(state);
				return AdminActionService._listenToResponse(userUid, response.responseKey, true).pipe(
					first(),
					map((finishPurchaseResponse) => {
						return purchaseWithCardcomSuccessfully(finishPurchaseResponse, showSuccessfullyPopup);
					})
				);
			} catch (error) {
				return {
					type: types.SUBSCRIPTIONS_CARDCOM_PURCHASE_FAILED,
					payload: error?.message,
				};
			}
		}),
		catchError((error) => {
			return of({
				type: types.SUBSCRIPTIONS_CARDCOM_PURCHASE_FAILED,
				payload: error?.message,
			});
		}),
		repeat()
	);

export const fetchSale = (action$) =>
	action$.pipe(
		ofType(AuthTypes.AUTH_LOGGED_IN),
		mergeMap(async () => {
			try {
				const sale = await SubscriptionCallService.getSale();
				sale.plans = Object.keys(sale.plans || {}).map((key) => ({
					...sale.plans[key],
					key,
				}));
				if (!!sale) {
					return {
						type: types.SUBSCRIPTIONS_FETCH_SALE_SUCCESSFULLY,
						payload: sale,
					};
				} else {
					return {
						type: types.SUBSCRIPTIONS_FETCH_SALE_EMPTY,
					};
				}
			} catch (error) {
				return {
					type: types.SUBSCRIPTIONS_FETCH_SALE_FAILED,
					payload: error?.message,
				};
			}
		}),
		catchError((error) => {
			return of({
				type: types.SUBSCRIPTIONS_FETCH_SALE_FAILED,
				payload: error?.message,
			});
		}),
		repeat()
	);
