const playIcon = `
          <svg xmlns="http://www.w3.org/2000/svg" class="playIcon" width="30" height="30" viewBox="0 0 20 20">
            <g id="Group_3" data-name="Group 3" transform="translate(2607 -2341)">
              <path id="Ellipse_2" data-name="Ellipse 2" d="M9-1A10,10,0,0,1,19,9,10,10,0,0,1,9,19,10,10,0,0,1-1,9,10,10,0,0,1,9-1ZM9,17.182A8.182,8.182,0,1,0,.818,9,8.191,8.191,0,0,0,9,17.182Z" transform="translate(-2606 2342)"/>
              <path id="Path_1" data-name="Path 1" d="M9.875,7a.874.874,0,0,1,.485.147l5.25,3.5a.875.875,0,0,1,0,1.456l-5.25,3.5A.875.875,0,0,1,9,14.875v-7A.875.875,0,0,1,9.875,7Zm3.673,4.375L10.75,9.51v3.73Z" transform="translate(-2609 2339.625)"/>
            </g>
          </svg>
        `;
const pauseIcon = `
          <svg xmlns="http://www.w3.org/2000/svg" class="pauseIcon" width="30" height="30" viewBox="0 0 20 20">
              <path id="Ellipse_1" data-name="Ellipse 1" d="M9-1A10,10,0,0,1,19,9,10,10,0,0,1,9,19,10,10,0,0,1-1,9,10,10,0,0,1,9-1ZM9,17.182A8.182,8.182,0,1,0,.818,9,8.191,8.191,0,0,0,9,17.182Z" transform="translate(1 1)"/>
              <g id="Group_1" data-name="Group 1" transform="translate(2638 -2342)">
                <path id="Line_1" data-name="Line 1" d="M0,7A1,1,0,0,1-1,6V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V6A1,1,0,0,1,0,7Z" transform="translate(-2630 2349)"/>
                <path id="Line_2" data-name="Line 2" d="M0,7A1,1,0,0,1-1,6V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V6A1,1,0,0,1,0,7Z" transform="translate(-2626 2349)"/>
              </g>
          </svg>
        `;
const soundIcon = `
            <svg xmlns="http://www.w3.org/2000/svg" class="soundIcon"  width="18.146" height="14.375" viewBox="0 0 18.146 14.375">
              <g id="Group_2" data-name="Group 2" transform="translate(2573 -2343.415)">
                <path id="Path_2" data-name="Path 2" d="M9.166,4a.817.817,0,0,1,.816.817V16.248a.817.817,0,0,1-1.327.638L4.8,13.8H1.817A.817.817,0,0,1,1,12.982v-4.9a.817.817,0,0,1,.817-.817H4.8L8.655,4.179A.816.816,0,0,1,9.166,4ZM8.349,14.549V6.516L5.593,8.72a.817.817,0,0,1-.51.179H2.633v3.266h2.45a.817.817,0,0,1,.51.179Z" transform="translate(-2574 2340.07)"/>
                <path id="Path_3" data-name="Path 3" class="volumeLine" d="M18.422,4.93a8.166,8.166,0,0,1,0,11.546M15.54,7.812a4.083,4.083,0,0,1,0,5.773" transform="translate(-2576.667 2339.899)" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              </g>
            </svg>
        `;
const muteIcon = `
            <svg xmlns="http://www.w3.org/2000/svg" class="muteIcon" width="18.982" height="13.205" viewBox="0 0 18.982 13.205">
                <g id="_2561500_volume_x_icon" data-name="2561500_volume_x_icon" transform="translate(-1 -4)">
                  <path id="Path_4" data-name="Path 4" d="M9.253,4a.825.825,0,0,1,.825.825V16.38a.825.825,0,0,1-1.341.644l-3.9-3.12H1.825A.825.825,0,0,1,1,13.078V8.127A.825.825,0,0,1,1.825,7.3H4.837l3.9-3.12A.825.825,0,0,1,9.253,4ZM8.428,14.663V6.542L5.642,8.771a.825.825,0,0,1-.516.181H2.651v3.3H5.127a.825.825,0,0,1,.516.181Z"/>
                  <path id="Line_3" data-name="Line 3" d="M-.175,5.6a.823.823,0,0,1-.584-.242.825.825,0,0,1,0-1.167L4.194-.758a.825.825,0,0,1,1.167,0,.825.825,0,0,1,0,1.167L.409,5.361A.823.823,0,0,1-.175,5.6Z" transform="translate(14.38 8.301)"/>
                  <path id="Line_4" data-name="Line 4" d="M4.777,5.6a.823.823,0,0,1-.584-.242L-.758.409a.825.825,0,0,1,0-1.167.825.825,0,0,1,1.167,0L5.361,4.194A.825.825,0,0,1,4.777,5.6Z" transform="translate(14.38 8.301)"/>
                </g>
            </svg>
        `;

export default { playIcon, pauseIcon, soundIcon, muteIcon };
