import "firebase/database";
import httpsCallable from "./httpsCallable";
import localStorage from "./localStorage";

class PurchaseService {
	BASE_REF = "users_purchase_start";

	async updatePurchaseIsStart(type) {
		const isSendSms = localStorage.get("purchStart");
		if (!!isSendSms) return;
		localStorage.set("purchStart", true, 365);

		const result = await httpsCallable.call("updatePurchaseIsStart", { type });
		return result;
	}
}

export default new PurchaseService();
