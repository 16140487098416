import { useState, useEffect } from "react";

import usePopups from "hooks/usePopups";
import CropImageTypes from "consts/cropImageTypes";
import getImageBase64FromURl from "helpers/getImageBase64FromURl";
import useProfileImages from "hooks/useProfileImages";
import profileFields from "consts/profileFields";
import { isIOS, isMobile } from "react-device-detect";
import heic2any from "heic2any";

export default ({ onClick, refInput, handleSaveNewImage, style = {}, urlForAutoOpening }) => {
	const MAX_SIZE_5MB = 1024 * 1024 * 10;
	const MIN_WIDTH_IMAGE = 400;
	const MIN_HEIGHT_IMAGE = 482;

	const [upImg, setUpImg] = useState(null);
	const [openCrop, setOpenCrop] = useState(1);
	const { showCropImagePopup } = usePopups();
	const { showBigImagePopup, showLowQualityImage } = usePopups();
	const { saveNewImage, showLoaderHeicImage } = useProfileImages();

	useEffect(() => {
		if (!upImg) return;

		showCropImagePopup({
			image: upImg,
			cropImageType: CropImageTypes.NewImage,
			handleSaveNewImage,
		});
	}, [openCrop]);

	useEffect(async () => {
		if (!urlForAutoOpening) return;
		setUpImg(await getImageBase64FromURl(urlForAutoOpening));
		setOpenCrop(openCrop + 1);
	}, []);

	const convertWebpHeif2JPEG = async (file) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => {
				const img = new Image();
				img.src = reader.result;
				img.onload = () => {
					const canvas = document.createElement("canvas");
					canvas.width = img.width;
					canvas.height = img.height;
					const ctx = canvas.getContext("2d");
					ctx.drawImage(img, 0, 0);
					canvas.toBlob((blob) => {
						resolve(blob);
					}, "image/jpeg");
				};
				img.onerror = reject;
			};
			reader.onerror = reject;
		});
	};

	const onSelectFileInput = (e) => {
		setTimeout(async () => {
			if (!(e.target.files && e.target.files.length > 0)) return;
			let fileToRead = e.target.files[0];

			if (fileToRead.type === "image/heic") {
				showLoaderHeicImage();
				const heic2png = await heic2any({
					blob: fileToRead,
					toType: "image/jpeg",
				});
				fileToRead = new File([heic2png], `${fileToRead.name}.jpeg`, {
					type: "image/jpeg",
				});
			} else if (fileToRead.type === "image/webp" || fileToRead.type === "image/heif") {
				showLoaderHeicImage();
				const webp2jpeg = await convertWebpHeif2JPEG(fileToRead);
				fileToRead = new File([webp2jpeg], `${fileToRead.name}.jpeg`, {
					type: "image/jpeg",
				});
			}

			// const reader = new FileReader();
			// reader.readAsDataURL(e.target.files[0]);
			// reader.addEventListener("load", () => {

			if ((fileToRead?.size || 0) >= MAX_SIZE_5MB) {
				return showBigImagePopup();
			}

			if (!!isMobile) {
				const reader = new FileReader();
				reader.addEventListener("load", () => {
					// const base64String = reader.result.replace(/^data:image\/(png|jpg);base64,/, "");
					const base64String = reader.result;

					const img = new Image();
					img.onload = () => {
						if (img.naturalWidth < MIN_WIDTH_IMAGE || img.naturalHeight < MIN_HEIGHT_IMAGE) {
							return showLowQualityImage();
						} else {
							if (!!handleSaveNewImage) handleSaveNewImage(base64String);
							return saveNewImage(profileFields.Pictures, base64String);
						}
					};
					img.src = base64String;
				});

				reader.readAsDataURL(fileToRead);

				return (e.target.value = "");
			}

			const data = URL.createObjectURL(fileToRead);
			setUpImg(data);
			setOpenCrop(openCrop + 1);
			e.target.value = "";
		}, 200);
	};

	// const handleFileChange = (e) => {
	// 	const file = e.target.files[0];
	// 	if (file) {
	// 	  if (file.type === 'image/heic') {
	// 		// Use heic2any to convert HEIC to PNG
	// 		heic2any({
	// 		  blob: file,
	// 		  toType: 'image/png',
	// 		}).then((pngBlob) => {
	// 		  const reader = new FileReader();
	// 		  reader.onload = () => {
	// 			const base64String = reader.result;
	// 			const img = new Image();
	// 			img.onload = () => {
	// 			  if (img.naturalWidth < MIN_WIDTH_IMAGE || img.naturalHeight < MIN_HEIGHT_IMAGE) {
	// 				showLowQualityImage();
	// 			  } else {
	// 				if (handleSaveNewImage) handleSaveNewImage(base64String);
	// 				saveNewImage(profileFields.Pictures, base64String);
	// 			  }
	// 			};
	// 			img.src = base64String;
	// 		  };
	// 		  reader.readAsDataURL(pngBlob);
	// 		}).catch((error) => {
	// 		  console.error('Error converting HEIC to PNG:', error);
	// 		});
	// 	  } else {
	// 		// Handle other image formats
	// 		const reader = new FileReader();
	// 		reader.onload = () => {
	// 		  const base64String = reader.result;
	// 		  const img = new Image();
	// 		  img.onload = () => {
	// 			if (img.naturalWidth < MIN_WIDTH_IMAGE || img.naturalHeight < MIN_HEIGHT_IMAGE) {
	// 			  showLowQualityImage();
	// 			} else {
	// 			  if (handleSaveNewImage) handleSaveNewImage(base64String);
	// 			  saveNewImage(profileFields.Pictures, base64String);
	// 			}
	// 		  };
	// 		  img.src = base64String;
	// 		};
	// 		reader.readAsDataURL(file);
	// 	  }
	// 	}
	//   };
	return (
		<input
			style={style}
			ref={refInput}
			className=" empty"
			accept=".heif,.heic,image/*"
			id="contained-button-file"
			type="file"
			onChange={onSelectFileInput}
			onClick={onClick}
		/>
	);
};
