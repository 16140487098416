import { useEffect, useState } from "react";
import Input from "../Input";
import Question from "./Question";

export default ({
	title,
	subTitle,
	onSubmit,
	hasError,
	errMsg,
	onBackButtonClick,
	showBackButton,
	initVal = "",
	...rest
}) => {
	const [value, setValue] = useState();
	const [isValid, setIsValid] = useState();
	const handleOnChange = (val) => {
		setValue(val);
	};
	const handleOnSubmit = () => {
		onSubmit(value);
	};
	const handleOnValidityChange = (_isValid) => {
		setIsValid(_isValid);
	};

	const areAllCharactersSame = (str) => {
		if (str.trim().includes(" ")) {
			return true; // Return true if there are two words
		}

		const firstChar = str[0];
		return !str.split("").every((char) => char === firstChar);
	};

	const validateExpresion = (value) => {
		if (rest?.isAboutMe) {
			//only aboutMe
			let valid = value.trim() !== "" && value?.length > 2;
			if (valid) {
				valid = areAllCharactersSame(value.trim());
			}
			return valid;
		}

		return value.trim() !== "";
	};

	useEffect(() => {
		if (rest?.isAboutMe && value !== undefined && value !== "") return rest.setHasError(!isValid);
	}, [isValid]);

	return (
		<Question
			isValid={isValid}
			title={title}
			subTitle={subTitle}
			onSubmit={handleOnSubmit}
			showBackButton={showBackButton}
			onBackButtonClick={onBackButtonClick}>
			<Input
				onValidityChange={handleOnValidityChange}
				onChange={handleOnChange}
				hasError={hasError}
				errMsg={errMsg}
				onEnterPressed={handleOnSubmit}
				initVal={initVal}
				validateExpresion={validateExpresion}
				{...rest}
			/>
		</Question>
	);
};
