import { Button, ModalBody, Modal, ModalHeader, ModalFooter } from "reactstrap";
import usePopups from "hooks/usePopups";
import { getUserProfile } from "redux/profile/selectors";
import { useSelector } from "react-redux";

export default () => {
	const { closePopup } = usePopups();
	const { name } = useSelector(getUserProfile);

	return (
		<Modal isOpen={true} toggle={closePopup} className="simpleDialog">
			<div className="user-chat-nav ">
				<Button
					color="none"
					type="button"
					onClick={closePopup}
					className="nav-btn"
					id="user-profile-hide">
					<i className="ri-close-line"></i>
				</Button>
			</div>
			<ModalHeader>{`היי${name ? ` ${name}` : ""}, קצת סבלנות...`}</ModalHeader>
			<ModalBody style={{ fontSize: "1.2rem", padding: "0 0.5rem" }}>
				{`הגעת למכסת האינטרקציות היומית המותרת למשתמש, המשך הפעילות תתאפשר החל ממחר בשעה 12 בצהרים :)`}
			</ModalBody>
			<ModalFooter>
				<Button
					outline
					size="sm"
					color="primary"
					onClick={closePopup}
					className="btn-rounded littleBtn">
					{`הבנתי`}
				</Button>
			</ModalFooter>
		</Modal>
	);
};
