import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { getUserProfile } from "redux/profile/selectors";

export default function UpdateResults({ speedData = {}, userData = {} }) {
	const currentUser = useSelector(getUserProfile);

	return (
		<>
			<h3 className="title">{`היי${
				currentUser?.name ? ` ${currentUser.name}` : ""
			}, מקווים שנהנית באירוע!`}</h3>

			<Box className="content">
				<Box>{`ברגעים אלה ממש המערכת החכמה שלנו משכללת את תוצאות מפגשי הוידאו דייט...`}</Box>
				<Box
					style={{
						marginTop: "0.7rem",
					}}>{`נעדכן בעוד מספר דקות :)`}</Box>

				<Box>{`תודה על הסבלנות.`}</Box>
			</Box>
		</>
	);
}
