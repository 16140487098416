import { Box } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import heartBreak from "assets/images/speedDate/heartBreak.png";
import vdHeart from "assets/images/speedDate/vdHeart.png";
import CountdownTimer from "../CountdownTimer";

export default function HeaderVideoStatus({
	eventDate,
	currentDate,
	nextDate,
	eventEndTime = false,
	toggleDrawer = () => {},
	closeVideoDate = () => {},
	remainingTime,
}) {
	const [eventStart, setEventStart] = useState(!!eventDate && moment().isAfter(moment(eventDate)));
	const [startTimeRemaining, setStartTimeRemaining] = useState();
	const [timeNextDate, setTimeNextDate] = useState(null);
	const [showCountdownTimer, setShowCountdownTimer] = useState(true);

	const handleClick = () => {
		toggleDrawer();
	};
	const handleFinishCount = () => {
		toggleDrawer();
		setShowCountdownTimer(false);
		closeVideoDate();
	};

	const getComponent = () => {
		if ((!currentDate?.name && !nextDate?.time && eventStart) || eventEndTime) {
			return (
				<Box className="videoStatusItem" onClick={handleClick}>
					<span>{`האירוע נגמר`}</span>
				</Box>
			);
		}
		if (!eventStart) {
			// wait for first date

			return <img className="vdHeart" src={vdHeart} />;

			// return (
			// 	<Box className="videoStatusItem startsIn" onClick={handleClick}>
			// 		<span>{"מתחילים כאן בעוד "}</span>
			// 		<span className="time">{startTimeRemaining}</span>
			// 		<span>{" דקות"}</span>
			// 	</Box>
			// );
		}
		if (!currentDate?.uidMatch) {
			// user in break - wait for next date

			return (
				<Box
					className="videoStatusItem startsIn break"
					style={{ justifyContent: !nextDate?.time && "center" }}
					onClick={handleClick}>
					<Box className="breakImg">
						<span>{"הפסקה"}</span>
						<img src={heartBreak} />
					</Box>
					{nextDate?.time && (
						<Box>
							<span>{"הדייט הבא בעוד "}</span>
							<span className="time">{timeNextDate}</span>
							<span>{" דקות"}</span>
						</Box>
					)}
				</Box>
			);
		}

		//date on air
		return (
			<Box className="videoStatusItem dateWith" onClick={handleClick}>
				<span>{`דייט עם ${currentDate?.name ?? ""}`}</span>

				{!!remainingTime && remainingTime > 0 && showCountdownTimer && (
					<CountdownTimer
						initCount={remainingTime}
						handleFinishCount={handleFinishCount}></CountdownTimer>
				)}
			</Box>
		);
	};

	const calculateTimeRemaining = (time = eventDate) => {
		const now = moment().set({ second: 0 });
		const eventTime = moment(time);
		const duration = moment.duration(eventTime.diff(now));

		const minutes = duration.minutes();
		return minutes;
	};

	const handleNextDateTime = () => {
		const nextTime = calculateTimeRemaining(moment(nextDate?.time, "HH:mm").valueOf());
		if (nextTime < 0) return;
		setTimeNextDate(nextTime);
	};
	useEffect(() => {
		if (!nextDate?.time) return setTimeNextDate(null);
		handleNextDateTime();

		const interval = setInterval(() => handleNextDateTime(), 20000);
		return () => clearInterval(interval);
	}, [nextDate]);

	useEffect(() => {
		if (eventStart) return;
		setStartTimeRemaining(calculateTimeRemaining(moment(eventDate).set({ second: 0 })));

		const interval = setInterval(() => {
			if (eventStart) return;
			if (!eventStart && startTimeRemaining <= 0) return setEventStart(true);
			setStartTimeRemaining(calculateTimeRemaining(moment(eventDate).set({ second: 0 })));
		}, 20000);

		return () => clearInterval(interval);
	}, [eventStart]);

	useEffect(() => {
		if (currentDate?.name) setEventStart(true);
		handleNextDateTime();
	}, [currentDate]);

	useEffect(() => {
		if (remainingTime > 0 && !showCountdownTimer) setShowCountdownTimer(true);
	}, [remainingTime]);

	return <Box className="headerVideoStatus">{getComponent()}</Box>;
}
