export default {
	SortByLastLoginCombinedWithBustsDESC: "SortByLastLoginCombinedWithBustsDESC",
	SortByLastLikeMeDESC: "SortByLastLikeMeDESC",
	SortByDistanceASC: "SortByDistanceASC",
	SortByLikeTimeStampDESC: "SortByLikeTimeStampDESC",
	SortByLikedMeTimeStampDESC: "SortByLikedMeTimeStampDESC",
	SortByCreateTimeDESC: "SortByCreateTimeDESC",
	SortByCreateTimeASC: "SortByCreateTimeASC",
	SortByLastLoginTimeDESC: "SortByLastLoginTimeDESC",
	SortByManualRankAndRandomDESC: "SortByManualRankAndRandomDESC",
	SortByRankAndLastLoginDESC: "SortByRankAndLastLoginDESC",
};
