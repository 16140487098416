import { useEffect, useState } from "react";
import i18next from "i18next";
import { Button } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import Promotions from "./Promotions";
import ChatUsersList from "./ChatUsersList";
import ChatTabs from "consts/chatTabs";
import usePopups from "hooks/usePopups";
import {
	getChatReceivedUsers,
	getHasMoreChats,
	getLoadChatsNumber,
	getResetReceivedUsersOnce,
} from "redux/chatReceived/selectors";
import {
	getChatReceivedFilteredUsers,
	getHasMoreChatsReceivedFiltered,
	getLoadChatSentNumberReceivedFiltered,
	getResetReceivedFilteredUsersOnce,
} from "redux/chatReceivedFiltered/selectors";
import {
	getChatSentUsers,
	getHasMoreChatsSent,
	getLoadChatSentNumber,
	getResetSentUsersOnce,
} from "redux/chatSent/selectors";
import { resetChatReceivedUsers, virtualDeleteChat } from "redux/chatReceived/actions";
import { virtualDeletePromotion } from "redux/promotions/actions";
import { cancelActiveUser } from "redux/chat/actions";
import useNavigator from "hooks/useNavigator";
import ChatFilteredButton from "./ChatFilteredButton";
import { resetChatReceivedFilteredUsers } from "redux/chatReceivedFiltered/actions";
import { resetChatSentUsers } from "redux/chatSent/actions";
import chatMeta from "services/chatMeta";

export default ({ tab }) => {
	const navigator = useNavigator();
	const [selectedChats, setSelectedChats] = useState(new Set());
	const [chatsUnread, setChatsUnread] = useState({});

	const { showDeleteChatsPopup, _closePopupUnderPopup } = usePopups();
	const allMessagesBtnClass = tab === ChatTabs.Sent ? "btn-primary active" : "btn-light";
	const MatchesBtnClass = tab === ChatTabs.Received ? "btn-primary active" : "btn-light";
	const ReceivedFilteredBtnClass =
		tab === ChatTabs.ReceivedFiltered ? "btn-primary active" : "btn-light";
	const chatReceivedUsers = useSelector(
		tab === ChatTabs.Received
			? getChatReceivedUsers
			: tab === ChatTabs.Sent
			? getChatSentUsers
			: getChatReceivedFilteredUsers
	);

	const loadChatsNumber = useSelector(
		tab === ChatTabs.Received
			? getLoadChatsNumber
			: tab === ChatTabs.Sent
			? getLoadChatSentNumber
			: getLoadChatSentNumberReceivedFiltered
	);

	const showHasMoreChatsBtn = useSelector(
		tab === ChatTabs.Received
			? getHasMoreChats
			: tab === ChatTabs.Sent
			? getHasMoreChatsSent
			: getHasMoreChatsReceivedFiltered
	);
	const resetUsersOnce = useSelector(
		tab === ChatTabs.Received
			? getResetReceivedUsersOnce
			: tab === ChatTabs.Sent
			? getResetSentUsersOnce
			: getResetReceivedFilteredUsersOnce
	);
	const dispatch = useDispatch();

	const switchTab = (nextTab) => {
		if (nextTab === tab) {
			return;
		}
		switch (nextTab) {
			case ChatTabs.Received:
				navigator.navigateToMassagesReceived();
				break;
			case ChatTabs.Sent:
				navigator.navigateToMassagesSent();
				break;
			case ChatTabs.ReceivedFiltered:
				navigator.navigateToMassagesFiltered();
				break;
		}
	};

	const updateSelectedChat = (add, userUid) => {
		add && selectedChats.add(userUid);
		!add && selectedChats.delete(userUid);
		setSelectedChats(new Set(selectedChats));
	};

	const isPromotion = (userUid) => chatReceivedUsers.find((user) => user.uid === userUid).promotion;

	const onDeleteChats = () => {
		selectedChats.forEach((userUid) => {
			isPromotion(userUid)
				? dispatch(virtualDeletePromotion(userUid))
				: dispatch(virtualDeleteChat(userUid, tab));
		});
		setSelectedChats(new Set());
		_closePopupUnderPopup();
		dispatch(cancelActiveUser());
	};

	const onClickDeleteChats = () => showDeleteChatsPopup({ onDeleteChats });

	useEffect(() => {
		if (resetUsersOnce) return;
		switch (tab) {
			case ChatTabs.Received:
				return dispatch(resetChatReceivedUsers({ hasMoreChats: false, loadChatsNumber: 30 }));
			case ChatTabs.Sent:
				return dispatch(resetChatSentUsers({ hasMoreChats: false, loadChatsNumber: 30 }));
			case ChatTabs.ReceivedFiltered:
				return dispatch(
					resetChatReceivedFilteredUsers({ hasMoreChats: false, loadChatsNumber: 30 })
				);
		}
	}, [tab]);

	useEffect(() => {
		let chatsUnreadSubscription = null;
		switch (tab) {
			case ChatTabs.Received:
			case ChatTabs.Sent:
				chatsUnreadSubscription = chatMeta.listenChatReceivedUnread().subscribe((data) => {
					if (!data) return setChatsUnread({});
					setChatsUnread(data);
				});

				return;
			case ChatTabs.ReceivedFiltered:
				chatsUnreadSubscription = chatMeta.listenChatReceivedFilteredUnread().subscribe((data) => {
					if (!data) return setChatsUnread({});
					setChatsUnread(data);
				});
				return;
		}

		return () => {
			chatsUnreadSubscription?.unsubscribe();
		};
	}, [tab]);
	return (
		<>
			<div className="chatPanel">
				<div className={`deletePanel ${selectedChats.size ? "slideBottom" : ""}`}>
					<Button className="deleteChatUsers" outline color="primary" onClick={onClickDeleteChats}>
						{i18next.t("Chat.Delete selected correspondences")}
					</Button>
				</div>

				<div className="px-4 pt-4 title">
					{/* <h4 className="mb-4">{i18next.t("Home Tabs.Messages")}</h4> */}
					<label className="mb-4">{i18next.t("Recommended for you")}</label>
				</div>
				<Promotions />
				<div className="px-2">
					<div className="px-3 pb-2">
						<div className="btn-group chatsTabs" role="group" aria-label="Basic example">
							<button
								type="button"
								className={`btn btn-rounded ${ReceivedFilteredBtnClass}`}
								onClick={() => switchTab(ChatTabs.ReceivedFiltered)}>
								{i18next.t(ChatTabs.ReceivedFiltered)}
							</button>
							<button
								type="button"
								className={`btn btn-rounded ${allMessagesBtnClass}`}
								onClick={() => switchTab(ChatTabs.Sent)}>
								{i18next.t(ChatTabs.Sent)}
							</button>
							<button
								type="button"
								className={`btn btn-rounded ${MatchesBtnClass}`}
								onClick={() => switchTab(ChatTabs.Received)}>
								{i18next.t(ChatTabs.Received)}
							</button>
						</div>
					</div>
					<ChatFilteredButton show={tab === ChatTabs.ReceivedFiltered} />
					<ChatUsersList
						activeTab={tab}
						chatReceivedUsers={chatReceivedUsers}
						updateSelectedChat={updateSelectedChat}
						selectedChats={selectedChats}
						loadChatsNumber={loadChatsNumber}
						showHasMoreChatsBtn={showHasMoreChatsBtn}
						chatsUnread={chatsUnread}
					/>
				</div>
			</div>
		</>
	);
};
