import SearchPage from "./SearchPage";
import searchTypes from "consts/typesSearch";
import { useSelector } from "react-redux";
import { getLoggedInUser, getLoggedInUserUID } from "redux/auth/selectors";
import { useEffect } from "react";
import locationService from "services/locationService";
import { getIsDesktopWeb, getIsPlatformAndroid, getIsPlatformIOS } from "redux/init/selectors";
import usePopups from "hooks/usePopups";
import ProfileService from "services/profile";
import { getUserProfile } from "redux/profile/selectors";
import systemService from "services/system";
import dynamicPopupsService from "services/dynamicPopups.service";
import subscriptionService from "services/subscription";
import useNavigator from "hooks/useNavigator";
import SwiperCards from "components/search/SwiperCards";
import { getSubscriptionData, getSubscriptionsIsSoonExpired } from "redux/subscription/selectors";
import FcmTokenService from "services/fcmToken";
import localStorage from "services/localStorage";
import moment from "moment";
import CardsSwiper from "components/search/CardsSwiper";

export default () => {
	const navigate = useNavigator();
	//const client = useClient({ clientId: "e2c19b7c-f58a-41a3-a242-ea053003f80e" });
	const uid = useSelector(getLoggedInUserUID);
	const user = useSelector(getUserProfile);
	const subscription = useSelector(getSubscriptionData);
	const subscriptionsIsSoonExpired = useSelector(getSubscriptionsIsSoonExpired);
	const isPlatformIOS = useSelector(getIsPlatformIOS);
	const isPlatformAndroid = useSelector(getIsPlatformAndroid);
	const isWebPlatform = useSelector(getIsDesktopWeb);

	const {
		showEnteredMatchPopup,
		showDynamicPopup,
		showSubscriptionDealPopup,
		showPhoneNotificationSettingsPopup,
		showApproveShowInTelegramPopup,
		showRateUsPopup,
	} = usePopups();

	const CheckIfOneWeekPassed = (time, days = 7) => {
		if (!time) return true;
		const timestampPlus7Days = moment(parseInt(time)).add(days, "days");
		const hasOneWeekPassed = moment().isAfter(timestampPlus7Days);
		return !!hasOneWeekPassed;
	};

	useEffect(async () => {
		if (uid && isPlatformIOS && isPlatformAndroid) {
			const location = await locationService.setLocation(uid);

			// go to phone settings popup.
			const phoneTokenDate = localStorage.get("ptt"); //phone token time
			if (!user?.allowAppNotification && !!CheckIfOneWeekPassed(phoneTokenDate)) {
				const userEnteredPopup = user?.enteredNotificationPopupCount;
				if (userEnteredPopup && userEnteredPopup > 3) return; //if entered over 3 times

				const hasPhoneToken = await FcmTokenService.fetchUserToken(user.uid);

				if (!hasPhoneToken) {
					localStorage.set("ptt", Date.now());

					ProfileService.updateProfile(user.uid, {
						enteredNotificationPopupCount: parseInt(userEnteredPopup || 0) + 1,
					});
					setTimeout(() => {
						showPhoneNotificationSettingsPopup();
					}, 1000);
				}
			}
		}
	}, [uid]);

	useEffect(async () => {
		if (!user?.uid) return;
		if (user.uid === "qDWPHc1UgCOmRcrDIbQxeefBW863") {
			//	navigate.redirectWithReload(`speedDating/test111`);
		}
		if (!user?.isEnteredMatchPopup) {
			//in the future - get the data from system DB
			// systemService.fetchSiteEntrancePopupData().then((data) => {
			// 	showEnteredMatchPopup(data);
			// 	ProfileService.updateProfile(user.uid, {
			// 		isEnteredMatchPopup: true,
			// 	});`
			// });

			return setTimeout(() => {
				showEnteredMatchPopup();
				ProfileService.updateProfile(user.uid, {
					isEnteredMatchPopup: true,
				});
			}, 3000);
		} else if (!user?.isSendShowInTelegram) {
			const showTelegramDate = localStorage.get("stpt"); // show telegram popup time

			if (!showTelegramDate) {
				localStorage.set("stpt", Date.now());
				return showApproveShowInTelegramPopup();
			} else if (!!CheckIfOneWeekPassed(showTelegramDate)) {
				showApproveShowInTelegramPopup();
				ProfileService.updateProfile(user.uid, {
					isSendShowInTelegram: true,
				});
			}
		} else if (!user?.isEnteredRateUsPopup) {
			const showRateUsDate = localStorage.get("srupt"); // show rate us popup time
			const handleRateUs = () => {
				localStorage.set("srupt", Date.now());
				return setTimeout(() => {
					showRateUsPopup();
				}, 3000);
			};
			if (!showRateUsDate || !!CheckIfOneWeekPassed(showRateUsDate, 30)) {
				if (isPlatformIOS || isPlatformAndroid) return handleRateUs();
			}
		}

		await dynamicPopupsService.getDynemicPopup().then((data) => {
			if (!data) return;
			const latestObject = Object.values(data || {})[0];

			if (latestObject) {
				dynamicPopupsService
					.getUserDynemicPopup(user.uid, latestObject?.key)
					.then((res) => {
						console.log("res => ", res);
						if (!res) showDynamicPopup(latestObject);
					})
					.catch((e) => console.log(e));
			}
		});
	}, [user?.uid]);
	useEffect(async () => {
		if (!subscription || !user?.uid) return;
		if (
			subscriptionsIsSoonExpired &&
			!subscription?.renewable &&
			(!subscription.lastDateShowDealPopup ||
				subscription.lastDateShowDealPopup + 60 * 60 * 24 * 1000 < Date.now())
		) {
			showSubscriptionDealPopup();
			await subscriptionService.updateShowPopup(user.uid, true);
		}
	}, [subscription, user?.uid]);

	return (
		<>
			<SearchPage type={searchTypes.Matches}></SearchPage>
			{/* <CardsSwiper /> */}
			{/* {isWebPlatform ? <SearchPage type={searchTypes.Matches} /> : <SwiperCards />} */}
		</>
	);
};
