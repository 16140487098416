import firebase from "firebase/app";
import "firebase/database";

const FIREBASE_HTTP_FUNCTION_URL =
	"https://us-central1-shlish-friends.cloudfunctions.net/httpFunction"; // process.env.REACT_APP_FIREBASE_HTTP_FUNCTION_URL;

class HTTPFunctionService {
	APIS = {
		SUBSCRIPTION_PAY: "subscription/pay",
		MIGRATION: "migrate",
		SAVE_COMMUNICATION_SETTINGS: "settings/saveCommunicationSettings",
	};

	async GetUrl(url) {
		try {
			let response = await fetch(url);
			let responseJson = await response.text();
			return responseJson;
		} catch (err) {
			console.log("err GetUrl", err);
			throw err;
		}
	}

	async getCardcomPageLink(planId) {
		try {
			let response = await fetch(
				`${FIREBASE_HTTP_FUNCTION_URL}/${this.APIS.SUBSCRIPTION_PAY}?planId=${planId}`,
				{
					headers: {
						Authorization: `Bearer ${await firebase.auth().currentUser?.getIdToken()}`,
					},
					referrerPolicy: "no-referrer-when-downgrade",
				}
			);
			let responseJson = await response.json();
			return responseJson;
		} catch (err) {
			return Promise.reject();
		}
	}

	async migrateUser() {
		try {
			let response = await fetch(`${FIREBASE_HTTP_FUNCTION_URL}/${this.APIS.MIGRATION}`, {
				headers: {
					Authorization: `Bearer ${await firebase.auth().currentUser?.getIdToken()}`,
				},
				referrerPolicy: "no-referrer-when-downgrade",
			});
			let responseJson = await response.json();
			return responseJson;
		} catch (err) {
			return Promise.reject();
		}
	}

	async saveNewCommunicationSettings(newSettings, userUid) {
		try {
			await fetch(`${FIREBASE_HTTP_FUNCTION_URL}/${this.APIS.SAVE_COMMUNICATION_SETTINGS}`, {
				headers: {
					"Content-Type": "application/json",
				},
				method: "POST",
				body: JSON.stringify({
					userUid,
					newSettings,
				}),
			});
		} catch (err) {
			return Promise.reject();
		}
	}
}

export default new HTTPFunctionService();
