import "assets/scss/custom/pages/paySpeedDate.scss";
import { Box } from "@mui/system";
import useNavigator from "hooks/useNavigator";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getUserProfile } from "redux/profile/selectors";
import speedDatingService from "services/speedDatingService";
import moment from "moment";
import FooterBtn from "components/speedDate/FooterBtn";
import CommonTemplate from "components/speedDate/CommonTemplate";
import userSpeedDateStatus from "consts/userSpeedDateStatus";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import videoDatingLogoBlue from "assets/images/videoDatingLogoBlue.png";
import tokenService from "services/token.service";
import usePopups from "hooks/usePopups";

export default function PaySpeedDatingPage() {
	const navigate = useNavigator();
	const currentUser = useSelector(getUserProfile) || {};
	const location = useLocation();
	const urlName = location.state?.urlName ?? "";
	const iframeUrlLink = location.state?.iframeUrlLink ?? "";
	const { showBuyWithTokenPopup } = usePopups();
	const [speedData, setSpeedDate] = useState({});
	const [iframeUrl, setIframeUrl] = useState(iframeUrlLink ?? "");
	const [loading, setLoading] = useState(false);
	const [hasToken, setHasToken] = useState(false);
	const [tokenData, setTokenData] = useState("");

	const navigateToRegisterPage = () => {
		return navigate.navigateToPath(`/registerSpeedDating/${urlName ?? ""}`);
	};

	const handlePayBtn = async () => {
		if (!currentUser?.uid || !urlName) navigateToRegisterPage();
		setLoading(true);

		if (hasToken) {
			setLoading(false);
			return showBuyWithTokenPopup({ tokenData, speedData });
		}

		const res = await speedDatingService.getSpeedDatingPaymentUrl(speedData?.key);
		if (res.data?.success) setIframeUrl(res.data?.url);
		setLoading(false);
	};

	useEffect(() => {
		if (!urlName) return navigateToRegisterPage();
		speedDatingService.getSpeedDateInfoByUrlName(urlName).then((data) => {
			const sData = Object.values(data || {});
			if (!sData || !sData?.length) return navigateToRegisterPage();
			setSpeedDate(sData[0]);
		});
	}, [urlName]);

	useEffect(() => {
		if (!speedData?.key || !currentUser.uid) return;

		const subscription = speedDatingService
			.listenToUserInSpeedDate$(speedData.key, currentUser.uid)
			.subscribe((userData) => {
				if (userData?.status === userSpeedDateStatus.confirmed)
					return navigate.navigateToSuccessfullyPayVideoPage();
			});

		return () => {
			subscription?.unsubscribe();
		};
	}, [speedData?.key, currentUser.uid]);

	useEffect(async () => {
		window.scrollTo(0, 0);

		const res = await tokenService.getUserTokenData();
		setHasToken(!!res.data?.success);
		if (res.data?.success) {
			setTokenData(res.data?.data || {});
		}
	}, []);

	useEffect(async () => {
		if (location.state?.iframeUrlLink) setIframeUrl(location.state?.iframeUrlLink);
	}, [location.state?.iframeUrlLink]);

	return (
		<Box
			className={`registerSpeedDateWrapper paySpeedDateWrapper ${
				iframeUrl ? "iframeWithoutAfterImg" : ""
			}`}>
			<CommonTemplate bluePage={false} wideImg={!iframeUrl} withBlueImg={!!iframeUrl}>
				{iframeUrl ? (
					<iframe className="addPaymentIframe" title="Embedded Content" src={iframeUrl} />
				) : (
					<>
						<Box className="speedDateContentWrapper">
							<img className="payLogoBlue" src={videoDatingLogoBlue} alt="image" />
							<h3 className="text">
								<Box style={{ fontWeight: "bold" }}>{`איזה כיף שבחרת להירשם לאירוע!`}</Box>
								<Box style={{ fontWeight: "bold" }}>{`הולך להיות מעולה!!`}</Box>
							</h3>

							<Box className="text">
								{`התשלום עבור האירוע הינו ${
									speedData?.price ?? ""
								} ש"ח, ויגבה מכרטיס האשראי שלך רק לאחר שנוודא שאכן קיבלת לפחות ${
									speedData?.minDatesForUser ?? 1
								} דייטים פוטנציאלים לערב הספידייט הקרוב.`}
							</Box>
						</Box>
						<FooterBtn handleClick={handlePayBtn} text={`לתשלום >>`} loading={loading} />
					</>
				)}
			</CommonTemplate>
		</Box>
	);
}
