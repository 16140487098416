import { useSelector } from "react-redux";

import { Fill, Face } from "consts/cloudinaryOptions";
import { TransformImg } from "helpers/cloudinary";
import ApiRequestStatus from "consts/apiRequestStatus";
import { isUserOnline } from "redux/presence/selectors";
import placeholderManRound from "assets/images/emptyImages/placeholderManRound.png";
import placeholderWomanRound from "assets/images/emptyImages/placeholderWomanRound.png";
import privatePictureRound from "assets/images/emptyImages/privateRound.png";

export default ({ chat, user, img = null }) => {
	const isLoaded = user && user.status === ApiRequestStatus.SUCCESSFULLY;
	const uri = isLoaded ? user.pictures?.[user.mainPictureName]?.url || "" : "";
	const path = TransformImg(uri, undefined, null, Fill.Thumb, Face.Face, "r_max");
	const isOnline = useSelector(isUserOnline(chat.uid));
	const userStatus = isOnline ? "online" : "offline";
	const defaultImage = user.gender ? placeholderWomanRound : placeholderManRound;

	return (
		<div className={"chat-user-img " + userStatus + " align-self-center me-3 ms-0"}>
			<img
				src={img ? img : uri ? path : user.isPicturesPrivate ? privatePictureRound : defaultImage}
				className="rounded-circle avatar-mm"
			/>
			{!img && <span className="user-status"></span>}
		</div>
	);
};
