import React, { useState, useEffect } from "react";
import _ from "lodash";
import i18next from "i18next";
import { useDispatch } from "react-redux";
import SelectButtonsQuestion from "components/login/Questions/ButtonGroupQuestion";
import KeepShabbat from "consts/keepShabbat";
import ProfileFields from "consts/profileFields";
import { updateProfile } from "redux/profile/actions";
import kosher from "assets/images/registerImg/kosher.png";

export default ({ profile, stepName, steps, goToNamedStep, onFinalStepFinish }) => {
	const index = steps.indexOf(stepName);
	const total = steps.length;
	const dispatch = useDispatch();
	const [isClickedNext, setIsClickedNext] = useState(false);

	const handleOnSubmit = (value) => {
		dispatch(updateProfile(ProfileFields.EatKosherFood, value));
		setIsClickedNext(true);
	};

	const handleOnBackButtonClick = () => {
		goToNamedStep(steps[index - 1]);
	};

	useEffect(() => {
		if (isClickedNext && !_.isNil(profile[ProfileFields.EatKosherFood])) {
			if (total - 1 <= index) {
				onFinalStepFinish();
			} else {
				goToNamedStep(steps[index + 1]);
			}
		}
	}, [isClickedNext, profile]);

	return (
		<div className="loginHolder nameHolder">
			<div className="imageForPage">
				<img src={kosher} />
			</div>
			<SelectButtonsQuestion
				options={Object.values(KeepShabbat)}
				title={i18next.t(`Login.Are you eat kosher food ${profile[ProfileFields.Gender] || 0}`)}
				onSubmit={handleOnSubmit}
				showBackButton={index > 0}
				onBackButtonClick={handleOnBackButtonClick}
				translationPrefix={`EatKosherFood_${profile[ProfileFields.Gender] || 0}`}
			/>
		</div>
	);
};
