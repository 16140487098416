import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import i18next from "i18next";
import useNavigator from "hooks/useNavigator";
import PhoneVerificationQuestion from "components/login/Questions/PhoneVerificationQuestion";
import { verifyPhone, resendCode, resetLoginWithPhone } from "redux/auth/actions";
import {
	getLoginWithPhoneStatus,
	getResendCodeStatus,
	getLoginPhone,
	isUserLoggedIn,
	getIsAuthChecked,
} from "redux/auth/selectors";
import LOGIN_WITH_PHONE_STATUS from "consts/loginWithPhoneStatus";
import RESEND_CODE_STATUS from "consts/resendCodeStatus";
import phone2Image from "assets/images/registerImg/hello_woman.png";
import TermsLink from "components/login/TermsLink";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { Form, FormGroup, Input, Label } from "reactstrap";
import alternativeLoginService from "services/alternativeLoginService";
import ReactGA from "react-ga4";

export default ({ isFromPopup = false }) => {
	const navigator = useNavigator();
	const dispatch = useDispatch();
	const loginPhone = useSelector(getLoginPhone);
	const [hasError, setHasError] = useState();
	const [errMsg, setErrMsg] = useState();
	const [isCodeResent, setIsCodeResent] = useState();
	const location = useLocation();
	const params = location.state;
	const [smsSent, setSmsSent] = useState(false);
	const [resendSmsSent, setResendSmsSent] = useState(false);
	const [phone, setPhone] = useState("");
	const isSubmitted = useRef(false);

	const handleSubmit = async (code) => {
		if (!code || isSubmitted.current == code) return;
		isSubmitted.current = code;

		const data = await alternativeLoginService.getTokenLoginByCode(phone, code);
		console.log(data);
		if (data.success && data.isNewRegister) {
			ReactGA.event({
				category: "User",
				action: "Start New registered",
				label: "Enter Phone in login",
			});
		}

		if (data.success && data.token) {
			navigator.navigateToPath(`/token/${data.token}`);
		}
		//dispatch(verifyPhone(value));
	};
	const handleBack = () => {
		//alert(1);
		navigator.redirectToPhoneWithRefresh();
		//dispatch(resetLoginWithPhone());
	};

	const handleResendCode = async (value) => {
		// dispatch(resendCode(value));
		if (!!resendSmsSent || !params?.phone) return;

		const data = await alternativeLoginService.authWithCode(params.phone);
		console.log(data);
		setResendSmsSent(true);
	};
	useEffect(async () => {
		if (params && !!params.phone && !smsSent) {
			const data = await alternativeLoginService.authWithCode(params.phone);
			setPhone(params.phone);
			setSmsSent(true);
		}
	}, [params]);

	useEffect(() => {
		const html = document.querySelector("html");
		html.style.fontSize = "62.5%";
		return () => {
			html.style.fontSize = "";
		};
	}, []);

	return (
		<div className="loginPagesWrapper">
			<div className="loginPopup">
				<div className="loginHolder verifyHolder">
					<div className="imageForPage">
						<img src={phone2Image} />
					</div>
					{isCodeResent && <div>{i18next.t("Fill Profile Details.Resend code successfully")}</div>}
					<PhoneVerificationQuestion
						title={i18next.t("Login.Verify your phone")}
						subTitle={
							<>
								<>{i18next.t("Login.Verify phone text")}</>
								<div className="phoneVer">{loginPhone}</div>
							</>
						}
						hasError={hasError}
						setHasError={setHasError}
						errMsg={errMsg}
						onSubmit={handleSubmit}
						onBack={handleBack}
						onResendCode={handleResendCode}
					/>
					<TermsLink />
				</div>
			</div>
		</div>
	);
};
