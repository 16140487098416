import i18next from "i18next";
import { Modal, ModalFooter, ModalHeader, ModalBody } from "reactstrap";

import usePopups from "hooks/usePopups";
import { getPopups } from "redux/popups/selectors";
import { useSelector } from "react-redux";
import { Box, CircularProgress } from "@mui/material";
import { getIsPlatformAndroid, getIsPlatformIOS, getPlatform } from "redux/init/selectors";
import contactsService from "services/contactsService";
import { getUserProfile } from "redux/profile/selectors";
import img from "assets/images/forceUpload/forceShare.svg";
import Button from "components/Button";
import { useState } from "react";
import { App } from "@capacitor/app";
import { NativeSettings, AndroidSettings, IOSSettings } from "capacitor-native-settings";

export default () => {
	const { closePopup } = usePopups();
	const { data } = useSelector(getPopups);
	const currentUser = useSelector(getUserProfile);
	const isPlatformIOS = useSelector(getIsPlatformIOS);
	const [loading, setLoading] = useState(false);

	const shareContacts = async () => {
		if (!isPlatformIOS) {
			await NativeSettings.openAndroid({ option: AndroidSettings.ApplicationDetails });
			await contactsService.setContacts(currentUser?.uid);
			closePopup();
		} else {
			await NativeSettings.openIOS({
				option: IOSSettings.App,
			});
			await contactsService.setContacts(currentUser?.uid);
			closePopup();
			//alert("You need to give access permission to contacts in the device settings");
		}
	};
	return (
		<Modal
			isOpen={true}
			toggle={closePopup}
			className="forceShareFriends simpleDialogFull"
			style={{ minWidth: "100vw", minHeight: "100vh" }}>
			<ModalBody style={{ minWidth: "100vw", minHeight: "100vh" }}>
				<Box>
					<img style={{ width: "126px", margin: "150px 0 50px 0" }} src={img}></img>
					<h1>גישה לאנשי הקשר היא חובה על מנת לאפשר לאפליקציה לעבוד</h1>
					<h2 className="desc">
						לאחר אישור , כל היכרות תהיה הרבה יותר פשוטה, קלה והכי חשוב - בטוחה!
					</h2>
					<h3 className="desc">
						בלחיצת כפתור אפשר יהיה לראות האם יש ביניכם מכרים משותפים, להתייעץ, לשאול ולהכיר בדרך
						הטובה ביותר!
					</h3>
					<Box className="wrapMainBtn">
						{!!loading ? (
							<CircularProgress size={40} className="text-secondary" />
						) : (
							<Button
								className="mainAccountBtn"
								onClick={shareContacts}
								text={"אישור והמשך גלישה "}
							/>
						)}
					</Box>
				</Box>
			</ModalBody>
			<ModalFooter></ModalFooter>
		</Modal>
	);
};
