import firebase from "firebase/app";
import "firebase/storage";

class StorageService {
	PICTURES_BASE_PATH = "users/profilePics";
	TAGS_BASE_PATH = "tags.json";
	CONTACT_US_PATH = "contactUs";

	getTagsURL = async () => {
		const downloadURL = await firebase.storage().ref(this.TAGS_BASE_PATH).getDownloadURL();
		return downloadURL;
	};

	deleteImage(uid, imageName) {
		return firebase
			.storage()
			.ref(this.PICTURES_BASE_PATH)
			.child(uid)
			.child("pics")
			.child(imageName)
			.delete();
	}

	async saveImage(uid, imageName, file) {
		const storageImageSnap = await firebase
			.storage()
			.ref(this.PICTURES_BASE_PATH)
			.child(uid)
			.child("pics")
			.child(imageName)
			.putString(file, "data_url");
		return {
			url: await firebase.storage().ref(storageImageSnap.metadata.fullPath).getDownloadURL(),
			size: storageImageSnap.metadata.size / 1048576, // convert file size from bytes to megaByte
		};
	}

	async saveContactUsImage(imageName, file) {
		const storageImageSnap = await firebase
			.storage()
			.ref(this.CONTACT_US_PATH)
			.child(imageName)
			.putString(file, "data_url");

		return await firebase.storage().ref(storageImageSnap.metadata.fullPath).getDownloadURL();
	}

	async saveAudio(fileName, audioBlob) {
		const currentDate = new Date();
		const currentMonth = currentDate.getMonth() + 1;
		const currentYear = currentDate.getFullYear();

		const audioRef = firebase
			.storage()
			.ref()
			.child("chats/audioRecords")
			.child(`${currentYear}`)
			.child(`${currentMonth}`)
			.child(fileName);
		await audioRef.put(audioBlob);
		const downloadURL = await audioRef.getDownloadURL();
		return downloadURL;
	}
}

export default new StorageService();
