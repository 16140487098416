import "assets/scss/custom/pages/registerSpeedDate.scss";
import { Box } from "@mui/system";
import useNavigator from "hooks/useNavigator";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getUserProfile } from "redux/profile/selectors";
import speedDatingService from "services/speedDatingService";
import i18next from "i18next";
import { Button } from "reactstrap";
import usePopups from "hooks/usePopups";
import { CircularProgress } from "@mui/material";
import moment from "moment";
import speedDateStatus from "consts/speedDateStatus";
import videoDatingHome from "assets/images/videoDatingHome.png";
import videoDatingLogoWhite from "assets/images/videoDatingLogoWhite.png";
import UpcomingEvent from "components/speedDate/UpcomingEvent";
import FooterBtn from "components/speedDate/FooterBtn";
import CommonTemplate from "components/speedDate/CommonTemplate";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export default function RegisterSpeedDatingPage() {
	const { showLoginPopup } = usePopups();
	const { urlName } = useParams();
	const currentUser = useSelector(getUserProfile) || {};
	const navigate = useNavigator();
	const [speedData, setSpeedDate] = useState({});
	const [showArrow, setShowArrow] = useState(true);
	const containerRef = useRef(null);

	const handleArrowClick = () => {
		setShowArrow(false);
		if (containerRef.current) {
			window.scrollTo(0, containerRef.current.scrollHeight);
		}
	};
	const navigateToHomePage = () => {
		return navigate.navigateToAccountVideoDate({ urlName: urlName ?? null });
	};
	const navigateToExplanationPage = () => {
		return navigate.navigateToPath(`/explanationSpeedDate/${speedData?.urlName}`);
	};

	const handleRegisterBtn = () => {
		if (!currentUser?.uid) return showLoginPopup(navigateToExplanationPage);
		if (!speedData?.key) return;
		navigateToExplanationPage();
	};

	useEffect(() => {
		if (!urlName) {
			speedDatingService.getNextSpeedDate().then((data) => {
				const findData = Object.values(data || {}).find(
					(ev) => ev?.status === speedDateStatus.start
				);
				if (!findData) return navigateToHomePage();
				setSpeedDate(findData);
			});
		} else {
			speedDatingService.getSpeedDateInfoByUrlName(urlName).then((data) => {
				if (!data) return navigateToHomePage();
				const sData = Object.values(data || {});
				if (!sData || !sData?.length) return navigateToHomePage();
				setSpeedDate(sData[0]);
			});
		}
	}, []);

	useEffect(() => {
		if (!speedData?.key || !currentUser.uid) return;
		const canRegister = speedDatingService.checkIfUserCanRegisterToEventByFilters(
			speedData,
			currentUser
		);
		if (!canRegister) {
			return navigate.navigateToAccountVideoDate();
		}

		speedDatingService.getUserInSpeedDate(speedData.key, currentUser.uid).then((userData) => {
			if (!!userData?.uid) return navigate.navigateToAccountVideoDate({ urlName: urlName });
		});
	}, [speedData, currentUser.uid]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Box className="registerSpeedDateWrapper">
			<CommonTemplate bluePage={true} wideImg={true}>
				<Box className="speedDateContentWrapper" ref={containerRef}>
					<img className="imgLogo" src={videoDatingLogoWhite} alt="image" />
					{!!speedData?.key && (
						<>
							{speedData?.description && <Box className="text">{speedData.description}</Box>}
							<UpcomingEvent event={speedData} />
							<FooterBtn handleClick={handleRegisterBtn} text={`כאן מתחילים >>`} />
							{!!showArrow && (
								<Box onClick={handleArrowClick} className="downArrow">
									<KeyboardArrowDownIcon />
								</Box>
							)}
						</>
					)}
				</Box>
			</CommonTemplate>
		</Box>
	);
}
