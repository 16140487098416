import i18next from "i18next";

import DetailsAreaContainer from "./DetailsAreaContainer";
import AccountVideoBox from "components/speedDate/AccountVideoBox";
import videoDatingLogoBlue from "assets/images/videoDatingLogoBlue.png";
import { Box } from "@mui/material";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

export default function AccountVideoDate() {
	const { url } = useParams();
	const innerSubTitle = (
		<Box style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
			<img src={videoDatingLogoBlue} alt="video Dating Logo Blue" />
		</Box>
	);
	return (
		<DetailsAreaContainer subTitle={innerSubTitle} isVideoDating={true}>
			<AccountVideoBox sdUrl={url ?? null} />
		</DetailsAreaContainer>
	);
}
