export default ({ length, selected, onTapDot = () => {} }) => {
	const handleClick = (i) => onTapDot(i);

	return (
		<div className="imageTapIndicator">
			<div className="dotsContainer">
				{length > 1 &&
					new Array(length)
						.fill(null)
						.map((_, i) => (
							<div
								className={`dot ${i === selected ? "selected" : ""}`}
								onClick={() => handleClick(i)}></div>
						))}
			</div>
		</div>
	);
};
