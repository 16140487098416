import { EMPTY, of } from "rxjs";
import { isNil } from "lodash";

import * as types from "./actionTypes";
import ProfileFields from "consts/profileFields";
import Stance from "consts/stance";
import ProfileService from "services/profile";
import QueueService from "services/queue";
import ProfileEvents from "consts/profileEvents";
import { getLoggedInUserUID } from "redux/auth/selectors";
import { getUserProfile } from "./selectors";
import TagsService from "services/tags";

export const updateProfile = (key, value, oldValue) => {
	return {
		type: types.PROFILE_UPDATE_PROFILE,
		user: { [key]: value },
		oldValue,
	};
};

export const updateProfileCity = (lang, location, placeId, description) => {
	return (dispatch, getState) => {
		const uid = getLoggedInUserUID(getState());
		QueueService.sendEvent(uid, ProfileEvents.SaveProfileDetail, {
			fieldName: ProfileFields.CityPlaceId,
			newVal: placeId,
			oldVal: null,
		});
		QueueService.sendEvent(uid, ProfileEvents.SaveProfileCity, {
			city_placeid: placeId,
			used_language: lang,
		});
		dispatch({
			type: types.PROFILE_UPDATE_PROFILE,
			user: {
				[ProfileFields.CityLoc]: location,
				[ProfileFields.CityPlaceId]: placeId,
				[ProfileFields.Cites[lang]]: description,
			},
		});
	};
};

export const autoUpdateKeepShabbat = (profile, fieldName, value) => {
	if (
		fieldName === ProfileFields.Stance &&
		value !== Stance.Mesorati &&
		!isNil(profile[ProfileFields.KeepShabbat])
	) {
		const resetKeepShabat = updateProfile(ProfileFields.KeepShabbat, null);

		return of(resetKeepShabat);
	}
	return EMPTY;
};

export const autoUpdateChildrenCount = (fieldName, value) => {
	if (fieldName === ProfileFields.HasChildren && value === false) {
		const resetChildrenCount = updateProfile(ProfileFields.ChildrenCount, null);

		return of(resetChildrenCount);
	}
	return EMPTY;
};

export const autoUpdateModestyFilter = (fieldName, value) => {
	if (fieldName === ProfileFields.Stance) {
		return of(setAutoModestyFilter(value));
	}
	return EMPTY;
};

const setAutoModestyFilter = (stance) => {
	const modestyFilter = calculateModestyFilter(stance);
	return updateModestyFilter(modestyFilter);
};

const calculateModestyFilter = (stance) => {
	switch (stance) {
		case Stance.Chiloni:
		case Stance.Mesorati:
		case Stance.DatiLite:
		case Stance.Datlash:
			return 2;
		case Stance.Dati:
		case Stance.DatiLeumi:
			return 1;
		default:
			return 0;
	}
};

export const updateModestyFilter = (filter) => {
	return updateProfile(ProfileFields.ModestyFilter, filter);
};
export const updateAllowSharedContact = (filter) => {
	return updateProfile(ProfileFields.AllowSharedContact, filter);
};

export const addTagByText = (tagText, showTagAddedSuccessfullyPopup) => {
	return (dispatch, getState) => {
		const tags = TagsService.getTagsList();
		const tag = tags.find((tagI) => tagI.text === tagText);
		if (tag) {
			ProfileService.addTag(tag);
			dispatch({ type: types.PROFILE_ADD_TAG, payload: tag });
			showTagAddedSuccessfullyPopup();
		}
	};
};

export const setMainPicture = (key) => {
	ProfileService.setMainImage(key);
	return { type: types.UPDATE_MAIN_PICTURE, payload: key };
};

export const deleteImage = (imageKey) => {
	return { type: types.PROFILE_DELETE_IMAGE, payload: imageKey };
};

export const updateImage = (profileName, file) => {
	return { type: types.PROFILE_UPDATE_IMAGE, payload: { profileName, file } };
};

export const updateHeicImage = () => {
	return { type: types.PROFILE_UPDATE_HEIC_IMAGE };
};

export const saveEditedImage = (key, path) => {
	return { type: types.PROFILE_SAVE_EDITED_IMAGE, key, path };
};

export const updateCompleteRegistration = () => {
	return (dispatch, getState) => {
		const profile = getUserProfile(getState());
		if (!profile.hasCompleteRegistration) {
			ProfileService.updateCompleteRegistration(profile.uid);
			dispatch({
				type: types.PROFILE_COMPLETE_REGISTRATION_UPDATED_SUCCESSFULLY,
			});
		}
	};
};
