import firebase from "firebase/app";
import "firebase/database";
import httpsCallable from "./httpsCallable";
import { Observable } from "rxjs";

class BlogService {
	BASE_REF = "articles";
	ARTICLES_CATEGORY = "articles_category";
	ARTICLES_COMMENT = "articles_comment";

	async getBlogsByUser(maxBlogs = 200) {
		return (
			await firebase
				.database()
				.ref(this.BASE_REF)
				.orderByChild("publishDate")
				.limitToLast(maxBlogs)
				.once("value")
		).val();
	}
	async getBlogs(maxBlogs = 1000) {
		return (
			await firebase
				.database()
				.ref(this.BASE_REF)
				.orderByChild("publishDate")
				.limitToLast(maxBlogs)
				.once("value")
		).val();
	}

	async getBlogsCategory() {
		return (await firebase.database().ref(this.ARTICLES_CATEGORY).once("value")).val();
	}

	async getArticleByCategoryKey(key) {
		return (
			await firebase
				.database()
				.ref(this.BASE_REF)
				.orderByChild("categoryKey")
				.equalTo(key)
				.once("value")
		).val();
	}

	async getArticleByUrlName(urlName) {
		const result = await httpsCallable.call("getArticleByUrlName", {
			urlName,
		});
		return result?.data?.data;
	}

	async updateArticleViews(key) {
		const result = await httpsCallable.call("updateArticleViews", {
			key,
		});
		return result;
	}
	async addComment(articleId, text) {
		const result = await httpsCallable.call("addComment", {
			articleId,
			text,
		});
		return result;
	}
	async editComment(articleId, text, key) {
		const result = await httpsCallable.call("editComment", {
			articleId,
			text,
			key,
		});
		return result;
	}
	async deleteComment(articleId, key, loginFromManager = false, commentUid = null) {
		const result = await httpsCallable.call("deleteComment", {
			articleId,
			key,
			loginFromManager,
			commentUid,
		});
		return result;
	}

	listenComments(articleKey = "") {
		console.log(articleKey);
		return new Observable((subscriber) => {
			const ref = firebase.database().ref(this.ARTICLES_COMMENT).child(articleKey);

			const callbackFn = ref.on(
				"value",
				(snapshot) => subscriber.next(snapshot.val()),
				(error) => subscriber.error(error)
			);
			return () => ref.off("value", callbackFn);
		});
	}
}

export default new BlogService();
