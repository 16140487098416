import firebase from "firebase/app";
import "firebase/database";
import { Observable } from "rxjs";
import { Contacts } from "@capacitor-community/contacts";
import QueueService from "services/queue";
import httpsCallable from "./httpsCallable";
import StorageService from "services/storage";

class ContactsService {
	getPermission = async () => {
		try {
			return await Contacts.getPermissions();
		} catch (error) {
			console.error(error);
		}
	};

	getContacts = async () => {
		try {
			const projection = {
				name: true,
				phones: true,
				image: true,
			};
			const contacts = await Contacts.getContacts({
				projection,
			});
			return contacts.contacts;
		} catch (error) {
			console.error(error);
		}
	};
	listenContacts(uid) {
		return new Observable((subscriber) => {
			this.getContacts(uid).then((contacts) => {
				subscriber.next(contacts);
			});
		});
	}
	async setContacts(uid) {
		try {
			const sendEvent = (data, numSteps, key) => {
				return QueueService.sendEvent(uid, "setContacts", {
					contacts: data,
					numSteps,
					key,
				});
			};
			const contacts = await this.getContacts();
			const contactsClean = contacts
				.filter((item) => item.phones)
				.map((item) => {
					return {
						contactId: item.contactId ?? null,
						phones: item.phones ?? null,
						name: item?.name ?? null,
					};
				});
			const promises = [];
			if (contactsClean.length) {
				const timeStamp = Date.now();
				const step = 1000;
				const numSteps = Math.ceil(contactsClean.length / step);
				for (let index = 0; index < contactsClean.length; index = index + step) {
					var slicedArr = contactsClean.slice(index, index + step);
					promises.push(sendEvent(slicedArr, numSteps, timeStamp));
				}
				await Promise.all(promises);
			}

			//var slicedArr = contactsClean.slice(index, index + 1000);
			/* await QueueService.sendEvent(uid, "setContacts", {
				contacts: contactsClean,
			}); */

			return contacts;
		} catch (error) {
			console.log(error);
		}
	}

	async addContactMatching(userUid, talentId) {
		try {
			const extraData = {};
			extraData.talentId = talentId;
			QueueService.sendEvent(userUid, "addContactMatching", extraData);
		} catch (error) {
			console.log(error);
		}
	}

	async replayContactUs(key, text) {
		const result = await httpsCallable.call("replayContactUs", {
			key,
			text,
		});
		return result;
	}
	async contactUs(email, text, phone = null, image = null) {
		let imageUrl = image;
		if (!!imageUrl) {
			const now = new Date();
			const randomNum = Math.floor(Math.random() * 1000000);
			const randomNum2 = Math.floor(Math.random() * 1000);
			const imageName = `contact_pic${randomNum2}${now.getTime()}${randomNum}`;

			imageUrl = await StorageService.saveContactUsImage(imageName, image);
		}
		const result = await httpsCallable.call("contactUs", {
			email,
			text,
			phone,
			image: imageUrl,
		});
		return result;
	}
}

export default new ContactsService();
