import { useEffect } from "react";
import {
	getShowMassLetterPopup,
	getThreeMonthsPassedMassLetter,
	getUserQuotaStatus,
} from "redux/general/selectors";
import { useDispatch, useSelector } from "react-redux";
import usePopups from "hooks/usePopups";
import { getUserProfile } from "redux/profile/selectors";
import _ from "lodash";
import LocalStorageService from "services/localStorage";
import { resetMassLetter } from "redux/massLetter/actions";
import useShowJoinNowBtn from "hooks/useShowJoinNowBtn";
import { checkRelevantQuota } from "helpers/quota";

export default () => {
	const dispatch = useDispatch();
	const isThreeMonthsPassedMassLetter = useSelector(getThreeMonthsPassedMassLetter);
	const isShowMassLetterPopup = useSelector(getShowMassLetterPopup);
	const { showMassletterPopup, showVerifiedEmailPopup } = usePopups();
	const user = useSelector(getUserProfile);
	const userQuotaStatus = useSelector(getUserQuotaStatus);
	const isUserWithoutSubscription = useShowJoinNowBtn();

	const handlePopupOpen = (now) => {
		LocalStorageService.set("mpd", now);
		showVerifiedEmailPopup();
	};

	useEffect(() => {
		const notToShowMassletter = true;
		if (notToShowMassletter) return;

		if (isShowMassLetterPopup && !!isUserWithoutSubscription) {
			showMassletterPopup();
		}
	}, [isShowMassLetterPopup, isUserWithoutSubscription]);

	useEffect(() => {
		if (!!isThreeMonthsPassedMassLetter) {
			dispatch(resetMassLetter());
		}
	}, [isThreeMonthsPassedMassLetter]);

	useEffect(() => {
		const NEED_TO_FIX = true;
		if (!NEED_TO_FIX) {
			if (!_.isEmpty(user) && !user?.email && !!user?.approved) {
				const storedLastDateMailPopupOpen = LocalStorageService.get("mpd"); //mpd = mail popup date
				const now = new Date().getTime();
				const WEEK_TIME = 604800000;

				if (!storedLastDateMailPopupOpen) return handlePopupOpen(now);

				//If a week has passed
				if (now - storedLastDateMailPopupOpen > WEEK_TIME) return handlePopupOpen(now);
			}
		}
	}, [user]);

	useEffect(async () => {
		if (!!userQuotaStatus) {
			await checkRelevantQuota();
		}
	}, [userQuotaStatus]);

	return <></>;
};
