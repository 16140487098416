import placeholderManRound from "assets/images/emptyImages/placeholderManRound.png";
import placeholderWomanRound from "assets/images/emptyImages/placeholderWomanRound.png";

export const getFirstCharImg = (user) => {
	const defaultProfile = user.gender ? placeholderWomanRound : placeholderManRound;
	if (!user?.name) return defaultProfile;
	return `https://ui-avatars.com/api/?name=${encodeURIComponent(
		user?.name
	)}&background=2D5086&color=fff&length=1&rounded=true&bold=true`;
};
