import i18next from "i18next";

import { getAgeByDob } from "helpers/date";
import ProfileFields from "consts/profileFields";
import currentLanguage from "helpers/currentLanguage";

export default ({ profile, onTap = () => {} }) => {
	const long_city = profile[ProfileFields.Cites[currentLanguage()]];
	const city = long_city?.split(",")[0];

	return (
		<div className="basicDetails" onClick={onTap}>
			<div>
				<span className="font-size-25 jsmartProfileName">{profile.name + ", "}</span>
				<span className="font-size-25 jsmartProfileName">
					{profile.age || getAgeByDob(profile.dob)}
				</span>
			</div>
			<div>
				<span className="font-size-16">{city && city + ", "}</span>
				<span className="font-size-16">
					{i18next.t(`Stance_${profile.gender || 0}.${profile.stance}`)}
				</span>
			</div>
		</div>
	);
};
