import i18next from "i18next";

import replaceStartWith from "helpers/replaceStartWith";

export default ({ profile }) => {
	const handlePhone = () => {
		let userPhone = profile?.phone || "";
		if (userPhone.includes("-")) userPhone = userPhone.replaceAll("-", "");
		if (userPhone.includes("+")) userPhone = userPhone.replace("+", "");
		if (userPhone.includes("972")) userPhone = userPhone.replace("972", "0");
		return userPhone;
	};
	const phone = handlePhone();

	return (
		<h6>
			{i18next.t("Account Settings.Phone number")} {phone}
		</h6>
	);
};
