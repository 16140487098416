import { Box } from "@mui/material";
import usePopups from "hooks/usePopups";
import { useEffect, useState } from "react";
import unreadNotificationService from "services/unreadNotification.service";

export default function CardcomGiftCard({ setIsSuccessPurchase, data = null }) {
	const { showFittedMessagePopup } = usePopups();
	const [iframeLoaded, setIframeLoaded] = useState(false);
	const [url, setUrl] = useState(data?.url);

	const handleIframeLoaded = () => {
		setIframeLoaded(true);
	};

	useEffect(() => {
		if (!url && !!data?.url) {
			setUrl(data?.url);
		}
	}, [data]);

	useEffect(() => {
		if (!data?.keyGiftCard) return;
		const subscription = unreadNotificationService
			.listenPurchaseStatus(data.keyGiftCard)
			.subscribe((data) => {
				if (!data) return;
				const { status } = data;

				if (status === "success") setIsSuccessPurchase(true);
				if (status === "failed") showFittedMessagePopup("הרכישה נכשלה");
			});

		return () => {
			subscription?.unsubscribe();
		};
	}, []);

	return (
		<Box className="cardcomBox">
			{!iframeLoaded && <>{"טוען עמוד מאובטח לתשלום..."}</>}

			{url && <iframe onLoad={handleIframeLoaded} src={url}></iframe>}
		</Box>
	);
}
