import "assets/scss/blog/blogSurveys.scss";
import { Box, Button, Checkbox } from "@mui/material";
import { useEffect, useState } from "react";
import _ from "lodash";

export default function SurveyBtn({
	numOfChoice,
	selected = null,
	handleSend = () => {},
	optionText = "",
	surveyKey = "",
	surveyPercentResults = {},
	option = "",
	surveyOver = false,
}) {
	const [isSelected, setIsSelected] = useState(selected == numOfChoice);

	const handleChange = (e) => {
		e.preventDefault();
	};

	useEffect(() => {
		setIsSelected(selected == numOfChoice);
	}, [selected, numOfChoice]);
	return (
		<Button
			key={surveyKey}
			variant="outlined"
			className={isSelected ? "surveyBtn selectedBtn" : "surveyBtn"}
			style={{
				opacity: "1",
				background:
					!_.isEmpty(surveyPercentResults) && (selected || surveyOver)
						? `linear-gradient(to left, #ddf9f9 ${surveyPercentResults[option]}%, transparent ${surveyPercentResults[option]}%)`
						: "",
			}}
			onClick={() => handleSend(surveyKey, numOfChoice)}>
			<Box className="checkBoxAndText">
				<Checkbox
					disabled={surveyOver}
					checked={isSelected}
					style={{ color: isSelected ? "#2d5086" : "#BFBFBF" }}
					onChange={handleChange}
				/>
				<span>{optionText}</span>
			</Box>
			{!_.isEmpty(surveyPercentResults) && (!!selected || !!surveyOver) && (
				<Box className="percentRes">{`${surveyPercentResults[option]}%`}</Box>
			)}
		</Button>
	);
}
