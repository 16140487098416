import "firebase/database";
import httpsCallable from "./httpsCallable";
import AnonymousCallService from "services/anonymousCall";
import localStorage from "services/localStorage";

class AlternativeLoginService {
	//	BASE_REF = "articles";

	async getTokenLoginByCode(phone, code) {
		const affId = localStorage.get("affId") || null;

		const result = await AnonymousCallService.call("getTokenByCode", { code, phone, affId });

		return result;
	}

	async authWithCode(phone) {
		const result = await AnonymousCallService.call("authWithCode", { phone });

		return result;
	}
}

export default new AlternativeLoginService();
