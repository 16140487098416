import { mergeMap } from "rxjs/operators";
import { ofType } from "redux-observable";
import SearchQueryBuilderType from "consts/searchQueryBuilderType";
import SearchService from "services/search";
import { getUserProfile } from "../profile/selectors";
import * as types from "./actionTypes";
import { getPitId, getSearchAfter, getLastSortOption } from "./selectors";
import ProfileFields from "consts/profileFields";
import ElasticSortOptions from "consts/elasticSortOptions";
import searchTypes from "consts/typesSearch";

export const fetchSearch = (action$, store) =>
	action$.pipe(
		ofType(types.SEARCH_FETCH),
		mergeMap(async ({ filters, freeText, searchLocation, selectedSortOption }) => {
			const state = store.value;
			const typeSearch = state.search.typeSearch ? state.search.typeSearch : searchTypes.Search;
			const profile = getUserProfile(state);

			let sortOption;
			if (!selectedSortOption) {
				const sortOptions = [
					ElasticSortOptions.SortByLastLikeMeDESC,
					//ElasticSortOptions.SortByRankAndLastLoginDESC,
				];

				const lastOption = getLastSortOption(state);
				const randomNumber = Math.floor(Math.random() * 14) + 1;
				sortOption = "sort" + randomNumber;
				/* sortOption = !!lastOption
					? sortOptions[sortOptions.indexOf(lastOption) + (1 % sortOptions.length)]
					: sortOptions[Math.floor(Math.random() * sortOptions.length)]; */
			} else {
				sortOption = selectedSortOption;
			}

			try {
				const payload = await SearchService.search(
					null,
					null,
					{
						freeText,
						userFilters: typeSearch === searchTypes.Matches ? {} : filters,
						userLocation: searchLocation || profile[ProfileFields.CityLoc] || profile.location,
					},
					sortOption,
					typeSearch
				);

				payload.lastSortOption = sortOption;

				return {
					type: types.SEARCH_FETCH_SUCCESSFULLY,
					payload,
				};
			} catch (error) {
				return {
					type: types.SEARCH_FETCH_FAILED,
					error: error?.message,
				};
			}
		})
	);
export const fetchSearchLoadMore = (action$, store) =>
	action$.pipe(
		ofType(types.SEARCH_FETCH_LOAD_MORE),
		mergeMap(async ({ filters, freeText, searchLocation }) => {
			const state = store.value;
			const typeSearch = state.search.typeSearch ? state.search.typeSearch : searchTypes.Search;
			const profile = getUserProfile(state);
			const pitId = getPitId(state);
			const searchAfter = getSearchAfter(state);
			const sortOption = getLastSortOption(state);
			const users = (state.search?.users || []).map((user) => user?.uid);

			try {
				const payload = await SearchService.search(
					pitId,
					searchAfter,
					{
						freeText,
						userFilters: typeSearch === searchTypes.Matches ? {} : filters,
						userLocation: searchLocation || profile.location || profile[ProfileFields.CityLoc],
					},
					sortOption,
					typeSearch
				);

				payload.lastSortOption = sortOption;
				payload.items = payload.items.filter((item) => !users.includes(item?.uid));

				return {
					type: types.SEARCH_FETCH_LOAD_MORE_SUCCESSFULLY,
					payload,
				};
			} catch (error) {
				return {
					type: types.SEARCH_FETCH_FAILED,
					error: error?.message,
				};
			}
		})
	);
