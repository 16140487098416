import "firebase/database";
import httpsCallable from "./httpsCallable";

class oldD4dService {
	async updateUserFromD4d() {
		const result = await httpsCallable.call("updateUserFromD4d", {});
		return result;
	}

	async isUserExistInD4d() {
		const result = await httpsCallable.call("isUserExistInD4d", {});
		return result;
	}
}

export default new oldD4dService();
