import { Box } from "@mui/material";
import moment from "moment";
import { useState } from "react";
import FooterBtn from "../FooterBtn";
import i18next from "i18next";

export default function NotFoundMatch({ speedData = {} }) {
	const [loading, setLoading] = useState(false);

	const registerToNextVideo = async () => {
		// await speedDatingService.registerToNextVideo();

		setLoading(false);
	};

	const handleClick = () => {
		setLoading(true);
		registerToNextVideo();
	};
	return (
		<>
			<h3 className="title">{i18next.t("speedDate.WeAreSorry")}</h3>

			<Box className="content">
				<Box>{i18next.t("speedDate.confirmed your participation in the upcoming event")}</Box>
				<Box className="bold">{moment(speedData?.eventDate).format("D/M/YY בשעה HH:mm")}</Box>
				<Box>{`${i18next.t(
					"speedDate.after perfecting all the matches we didn't find any for you"
				)} ${i18next.t("speedDate.potentialDates")}.`}</Box>
				{speedData?.price && <Box>{i18next.t("speedDate.weWillNotCharge")}</Box>}
			</Box>
			{/* <Box className="footer">
				<FooterBtn
					handleClick={handleClick}
					text={i18next.t("speedDate.registerNextEvent")}
					loading={loading}
				/>
			</Box> */}
		</>
	);
}
