import "assets/scss/blog/blogSurveys.scss";
import { Box } from "@mui/material";
import surveyService from "services/survey.service";
import { useEffect, useState } from "react";
import _ from "lodash";
import usePopups from "hooks/usePopups";
import moment from "moment";
import SurveyBtn from "./SurveyBtn";

export default function SurveyBox({
	surveyItem = {},
	surveyKey = "",
	i,
	surveysAnswered = {},
	uid = "",
}) {
	const [answerSurvey, setAnswerSurvey] = useState(surveysAnswered[surveyKey] || null);
	const [surveyPercentResults, setSurveyPercentResults] = useState({});
	const [selected, setSelected] = useState(null);
	const survOptions = ["option_1", "option_2", "option_3", "option_4"];
	const { showLoginPopup } = usePopups();
	const surveyOver = surveyItem?.endTime < moment().valueOf();

	const handleSend = async (key, choice) => {
		if (!uid) showLoginPopup();
		if (!key || !choice || selected || surveysAnswered[surveyKey]?.choiceKey) return;
		setSelected(choice);
		setAnswerSurvey(true);

		const data = { key, choice };
		await surveyService
			.saveSurvey(data)
			.then((d) => {})
			.catch((e) => console.log(e));
	};

	const getPercent = (num, sum) => ((num / (sum || 1)) * 100).toFixed(0);

	const createPercentageObj = (obj, sum) => {
		const tempObj = {};

		Object.entries(obj || {}).map(([key, val]) => {
			tempObj[key] = getPercent(val, sum);
		});
		return tempObj;
	};

	const handleSurveyResults = (res) => {
		const tempObj = {};
		let sumRes = 0;
		survOptions.map((surv) => {
			if (res[surv]) {
				tempObj[surv] = res[surv];
				sumRes += res[surv];
			} else {
				tempObj[surv] = 0;
			}
		});
		return createPercentageObj(tempObj, sumRes);
	};

	useEffect(async () => {
		const ansSurv = surveysAnswered[surveyKey];
		if (ansSurv) {
			setAnswerSurvey(ansSurv);
			if (ansSurv?.choiceKey) setSelected(ansSurv?.choiceKey);
		}
	}, [surveysAnswered]);

	useEffect(async () => {
		const subscription = surveyService.listenToSurveyResults$(surveyKey).subscribe((data) => {
			const percentObj = handleSurveyResults(data || {});
			setSurveyPercentResults(percentObj);
		});

		return () => {
			subscription?.unsubscribe();
		};
	}, [surveysAnswered]);

	return (
		<Box className={answerSurvey || surveyOver ? "surveyBox answerSurveyBox" : "surveyBox"} key={i}>
			<h4>{surveyItem?.question}</h4>
			<Box className="wrapSurveyBtns">
				{survOptions.map((opt, index) => {
					if (!surveyItem[opt]) return <></>;
					const numOfChoice = index + 1;

					return (
						<SurveyBtn
							surveyKey={surveyKey}
							numOfChoice={numOfChoice}
							selected={selected}
							handleSend={handleSend}
							optionText={surveyItem[opt]}
							surveyPercentResults={surveyPercentResults}
							option={opt}
							surveyOver={surveyOver}
						/>
					);
				})}
			</Box>
		</Box>
	);
}
