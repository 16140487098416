import "assets/scss/blog/blogSurveys.scss";
import { Box, Button, Checkbox } from "@mui/material";
import surveyService from "services/survey.service";
import { useEffect, useState } from "react";
import _ from "lodash";
import { getUserProfile } from "redux/profile/selectors";
import { useSelector } from "react-redux";
import SurveyBox from "components/survey/SurveyBox";

export default function BlogSurveys({ articleData = {} }) {
	const [surveysDB, setSurveysDB] = useState({});
	const [surveysAnswered, setSurveysAnswered] = useState({});
	const userProfile = useSelector(getUserProfile);

	useEffect(async () => {
		if (!articleData?.key || !articleData?.surveys?.length) return;

		await surveyService.getSurvey().then((data) => {
			if (data) setSurveysDB(data);
		});
	}, []);

	useEffect(async () => {
		if (!userProfile?.uid) return;

		await surveyService.getSurveyByUser().then((data) => {
			if (!data) return;
			setSurveysAnswered(data || {});
		});
	}, [userProfile]);

	return (
		<>
			{!_.isEmpty(surveysDB) && !!articleData?.surveys?.length && (
				<Box className="blogSurveysWrapper">
					{articleData.surveys.map((surveyKey, i) => {
						const surveyItem = surveysDB[surveyKey];
						if (!surveyItem || !surveyItem?.active) return <></>;

						return (
							<SurveyBox
								surveyItem={surveyItem}
								surveyKey={surveyKey}
								i={i}
								surveysAnswered={surveysAnswered}
								uid={userProfile?.uid}
							/>
						);
					})}
				</Box>
			)}
		</>
	);
}
