//import { InAppPurchase2 } from "@awesome-cordova-plugins/in-app-purchase-2";
import googleIAPState from "consts/googleIAPState";
import { Observable } from "rxjs";

const store = {};
store.verbosity = store.QUIET;
store.validator =
	"https://validator.fovea.cc/v1/validate?appName=com.meetsmart.jsmart&apiKey=9c809b2f-211d-42ab-8908-aeea3e7394c4";

class InappPurchaseService {
	order = (googlePlaySubscriptionId) => {
		store.order(googlePlaySubscriptionId);
	};

	register = (plans, userUid) => {
		const registrationPlans = plans.map((plan) => ({
			id: plan.google_play_id,
			type: plan.renewable ? store.PAID_SUBSCRIPTION : store.CONSUMABLE,
		}));
		store.register(registrationPlans);
		store.applicationUsername = () => userUid;
		store.refresh();
	};

	listenStatusChanged(plans) {
		return new Observable((subscriber) => {
			plans.forEach(({ google_play_id }) => {
				store.when(google_play_id).approved((product) => {
					product.verify();
					subscriber.next({ status: googleIAPState.approved, product });
				});
				store.when(google_play_id).verified((product) => {
					product.finish();
					subscriber.next({ status: googleIAPState.verified, product });
				});
				store.when(google_play_id).cancelled(() => {
					subscriber.next({ status: googleIAPState.canceled });
				});
			});
		});
	}

	listenStoreErrors() {
		return new Observable((subscriber) => {
			store.error((error) => {
				subscriber.next(error);
			});
		});
	}
}

export default new InappPurchaseService();
