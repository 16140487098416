export const PROMOTIONS_SALE_CARDCOM_PURCHASE_SUCCESSFULLY =
	"promotions.PROMOTIONS_SALE_CARDCOM_PURCHASE_SUCCESSFULLY";
export const PROMOTIONS_SALE_PURCHASE_WITH_CARDCOM =
	"promotions.PROMOTIONS_SALE_PURCHASE_WITH_CARDCOM";
export const PROMOTIONS_SALE_GET_CARDCOM_RESPONSE_SUCCESSFULLY =
	"promotions.PROMOTIONS_SALE_GET_CARDCOM_RESPONSE_SUCCESSFULLY";
export const PROMOTIONS_SALE_GET_CARDCOM_RESPONSE_FAILED =
	"promotions.PROMOTIONS_SALE_GET_CARDCOM_RESPONSE_FAILED";
export const PROMOTIONS_SALE_CARDCOM_PURCHASE_FAILED =
	"promotions.PROMOTIONS_SALE_CARDCOM_PURCHASE_FAILED";
export const PROMOTIONS_SALE_POPUP_INIT_SUCCESSFULLY =
	"promotions.PROMOTIONS_SALE_POPUP_INIT_SUCCESSFULLY";
