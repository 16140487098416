import i18next from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, ModalBody, Modal, ModalHeader, ModalFooter } from "reactstrap";
import usePopups from "hooks/usePopups";
import { getPopups } from "redux/popups/selectors";
import speedDatingService from "services/speedDatingService";
import { useState } from "react";
import { CircularProgress } from "@mui/material";
import moment from "moment";

export default function CancelVideoDateRegisterModal() {
	const { data } = useSelector(getPopups);
	const { closePopup } = usePopups();
	const [loading, setLoading] = useState(false);

	const cancelSpeedDate = () => {
		setLoading(true);
		speedDatingService.cancelUserSpeedDating(data?.key).then((res) => {
			if (res.data?.success) {
				closePopup();
			}
			setLoading(false);
		});
	};

	return (
		<Modal isOpen={true} toggle={closePopup} className="simpleDialog">
			<Button
				color="none"
				type="button"
				onClick={closePopup}
				className="nav-btn"
				id="user-profile-hide"
				style={{ width: "min-content" }}>
				<i className="ri-close-line"></i>
			</Button>
			<ModalHeader style={{ paddingTop: "0" }}>{`ביטול השתתפות באירוע`}</ModalHeader>
			<ModalBody>
				<span style={{ width: "100%", maxWidth: "100%" }}>
					{`חשוב לדעת - ניתן לבטל את הרישום לאירוע עד ${moment(data?.endUsersRegister).format(
						"D/M/YY בשעה HH:mm"
					)}`}
				</span>
				<h5 style={{ margin: "10px 0" }}>{`האם ברצונך לבטל את השתתפותך באירוע הקרוב?`}</h5>
			</ModalBody>
			<ModalFooter>
				{loading ? (
					<CircularProgress size={40} style={{ color: "#f65368" }} />
				) : (
					<>
						<Button
							outline
							size="sm"
							color="primary"
							onClick={cancelSpeedDate}
							className="btn-rounded littleBtn">
							{`ביטול השתתפות`}
						</Button>
						<Button
							outline
							size="sm"
							color="primary"
							onClick={closePopup}
							className="btn-rounded littleBtn active">
							{`חזור`}
						</Button>
					</>
				)}
			</ModalFooter>
		</Modal>
	);
}
