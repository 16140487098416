import { Box } from "@mui/system";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getUserProfile } from "redux/profile/selectors";
import matchMakerService from "services/matchMakerService";
import "assets/scss/dot.scss";

export default function MatchMakerPage() {
	const currentUser = useSelector(getUserProfile) || {};
	const [chat, setChat] = useState();
	const [waitToResponse, setWaitToResponse] = useState(false);
	const [initChat, setInitChat] = useState();
	const [matchmakerSetting, setMatchmakerSetting] = useState();

	const onSendAnswer = async (key) => {
		setWaitToResponse(true);
		await matchMakerService.sendAnswer({
			responseValue: key,
			currentQ: matchmakerSetting.currentQuestion,
		});
		setWaitToResponse(false);
	};

	useEffect(() => {
		if (!currentUser.uid) return;
		const subscription = matchMakerService
			.listenToMatchmakerChat$(currentUser.uid)
			.subscribe((chatData) => {
				setChat(chatData);
				if (!chatData) {
					setInitChat(true);
				}
			});
		return () => {
			subscription?.unsubscribe();
		};
	}, [currentUser.uid]);

	useEffect(() => {
		if (!currentUser.uid) return;
		const subscription = matchMakerService
			.listenToMatchmaker$(currentUser.uid)
			.subscribe((chatData) => {
				setMatchmakerSetting(chatData);
			});
		return () => {
			subscription?.unsubscribe();
		};
	}, [currentUser.uid]);

	useEffect(async () => {
		if (!initChat) return;
		await matchMakerService.initChat();
	}, [initChat]);

	return (
		<div style={{ marginTop: "100px" }}>
			<Box className="matchMakerChatWarper" width={"100%"}>
				{!!chat && Object.values(chat).length ? (
					<div className="chatItem">
						{Object.values(chat).map((item) => {
							return (
								<ChatItem
									text={item.text}
									writer={item.writer}
									extraData={item.extraData}></ChatItem>
							);
						})}
					</div>
				) : (
					<div className="dot-pulse"></div>
				)}
			</Box>

			<Box style={{ marginBottom: "50px" }}></Box>

			<Box className="matchMakerWarper" width={"100%"}>
				{!waitToResponse &&
					!!matchmakerSetting &&
					!!matchmakerSetting.currentOptions &&
					(!matchmakerSetting.hideOptions || matchmakerSetting.hideOptionsUntil < Date.now()) && (
						<div className="buttonsSection2">
							{matchmakerSetting.currentOptions.map((item) => (
								<AnswerItem text={item.value} keyValue={item.key} sendAnswer={onSendAnswer} />
							))}
						</div>
					)}
				{!!waitToResponse && <div className="dot-pulse"></div>}
			</Box>
		</div>
	);
}

const ChatItem = ({ writer, text, extraData }) => {
	const template = extraData?.template;
	return (
		<>
			<div>
				<b>{writer}</b>: {text}
			</div>
			{!!template && template === "user" && (
				<div>
					<img style={{ width: "100px" }} src={extraData?.img.url}></img>
				</div>
			)}
		</>
	);
};

const AnswerItem = ({ text, keyValue, sendAnswer }) => {
	const sendAnswerClick = () => {
		sendAnswer(keyValue);
	};
	return (
		<>
			<button color="primary" className="btn" onClick={() => sendAnswerClick()}>
				{text}
			</button>
		</>
	);
};
