import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import useNavigator from "hooks/useNavigator";
import i18next from "i18next";
import isMobile from "helpers/isMobile";
import usePopups from "hooks/usePopups";
import PublicProfileService from "services/publicProfile";
import UserProfileSideBar from "components/userProfileSidebar";
import _ from "lodash";
import Loader from "components/Loader";
import CardUserModal from "components/search/card/CardUserModal";
import BaseHelmet from "components/BaseHelmet";
import { useSelector } from "react-redux";
import { getUserProfile } from "redux/profile/selectors";
import { isEmpty } from "lodash";

export default () => {
	let { id } = useParams();
	const [userProfile, setUserProfile] = useState(null);
	const navigate = useNavigator();
	const currentUser = useSelector(getUserProfile) || {};

	useEffect(async () => {
		if (!id) return navigate.navigateToHomePage();
		const userFromId = await PublicProfileService.fetchProfile(id).catch(() =>
			navigate.navigateToHomePage()
		);

		if (!userFromId) return navigate.navigateToHomePage();
		setUserProfile(userFromId);
	}, [id]);

	useEffect(async () => {
		if (!isEmpty(currentUser)) {
			if (userProfile?.gender === currentUser.gender || (userProfile && !userProfile?.approved))
				navigate.navigateToHomePage();
		}
	}, [currentUser, userProfile]);

	return (
		<>
			{userProfile && <BaseHelmet profile={userProfile} />}
			<Box
				sx={{
					background: "#fff",
					width: "100vw",
					height: "100vh",
					position: "absolute",
				}}>
				{!userProfile && <Loader />}

				{userProfile ? (
					isMobile() ? (
						<UserProfileSideBar userFromUrl={userProfile} />
					) : (
						<CardUserModal userFromUrl={userProfile} />
					)
				) : (
					<></>
				)}
			</Box>
		</>
	);
};
