import i18next from "i18next";
import { useState } from "react";
import { Form, FormGroup, Input, Label } from "reactstrap";

export default ({ field, onChange, initValue, title }) => {
  const browserLanguage =
    window.navigator.userLanguage || window.navigator.language;

  const [value, setValue] = useState(initValue);

  const onNext = (date) => {
    setValue(date);
    onChange(field, date);
  };

  return (
    <Form inline>
      <FormGroup>
        <Label for={field}>{title}</Label>
        <Input
          className={
            browserLanguage.indexOf("he") !== -1 ? "datepickerRtl " : ""
          }
          id={field}
          value={value}
          onChange={(e) => onNext(e.target.value)}
          placeholder={i18next.t("Type")}
          type="date"
        />
      </FormGroup>
    </Form>
  );
};
