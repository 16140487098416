import { Box } from "@mui/material";
import "assets/scss/user/youtube.scss";

export default function UserYoutubeLink({ profile }) {
	const { youtubeUrlVideo } = profile;
	if (!youtubeUrlVideo) return <></>;

	const createIframe = () => {
		if (!youtubeUrlVideo) return;

		const urlParams = new URL(youtubeUrlVideo);
		let videoId = urlParams.searchParams.get("v");

		if (!videoId && youtubeUrlVideo.includes("/shorts/")) {
			videoId = youtubeUrlVideo.split("/shorts/")[1].split("?")[0];
			if (!videoId) videoId = youtubeUrlVideo.split("/shorts/")[1];
		}

		if (!videoId && youtubeUrlVideo.includes("youtu.be/")) {
			videoId = youtubeUrlVideo.split("youtu.be/")[1].split("?")[0];
		}

		if (!videoId) return <span></span>;

		const iframe = `<iframe width="100%" height="max-content" style="height:100%; min-height:max-content;" src="https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1&rel=0&showinfo=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;

		if (!iframe) return <span></span>;
		return iframe;
	};

	return (
		<div className="youtubeUserWrapper">
			<h4 className="text-left text-primary font-size-16 ">{"יוטיוב"}</h4>
			<Box
				className="wrapYouTubeVideo"
				sx={{
					overflow: "hidden",
					borderRadius: "20px",
					height: "230px",
				}}
				dangerouslySetInnerHTML={{
					__html: createIframe(),
				}}
			/>
		</div>
	);
}
