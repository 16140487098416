import { useSelector } from "react-redux";
import i18next from "i18next";
import { Link } from "react-router-dom";

import { geIsPicturesPrivate, getUserProfile } from "redux/profile/selectors";
import { getAgeByDob } from "helpers/date";
import ProfileFields from "consts/profileFields";
import currentLanguage from "helpers/currentLanguage";
import placeholderManRound from "assets/images/emptyImages/placeholderManRound.png";
import placeholderWomanRound from "assets/images/emptyImages/placeholderWomanRound.png";

/* */
import useShowJoinNowBtn from "hooks/useShowJoinNowBtn";
import giftImg from "assets/images/giftCard/gift.png";
import youGetGift from "assets/images/giftCard/youGetGift.png";
import { useEffect, useState } from "react";
import userNotificationsService from "services/unreadNotification.service";
import usePopups from "hooks/usePopups";

export default () => {
	const { showReceivedGiftCardPopup } = usePopups();
	const loggedInUserProfile = useSelector(getUserProfile);
	const gender = loggedInUserProfile.gender;
	const city = loggedInUserProfile[ProfileFields.Cites[currentLanguage()]];
	const mainPictureUri =
		loggedInUserProfile.pictures?.[loggedInUserProfile.mainPictureName]?.url || "";

	/* */
	const showJoinNowBtn = useShowJoinNowBtn();
	const isPicturesPrivate = useSelector(geIsPicturesPrivate);
	const [showGiftIcon, setShowGiftIcon] = useState(false);
	const [gift, setGift] = useState({});

	const handleClickGift = () => {
		if (gift) showReceivedGiftCardPopup(gift);
	};

	useEffect(async () => {
		if (!loggedInUserProfile?.uid) return;
		const isUserHaveGift = await userNotificationsService.getIsUserHaveGift(
			loggedInUserProfile.uid
		);
		setShowGiftIcon(!!isUserHaveGift.data?.success);
		if (!!isUserHaveGift.data?.gift) setGift(isUserHaveGift.data?.gift);
	}, [loggedInUserProfile?.uid]);

	return (
		<div className="userEdit">
			<div className="personalDetails">
				<div>
					<span color="primary" className="font-size-22 mb-1 text-truncate jsmartProfileName">
						{loggedInUserProfile.name}
					</span>
					,{" "}
					<span color="primary" className="font-size-22 mb-1 text-truncate jsmartProfileName">
						{getAgeByDob(loggedInUserProfile.dob)}
					</span>
				</div>
				<div className="detailsProfile">
					<p color="primary">{i18next.t(`Stance_${gender || 0}.${loggedInUserProfile.stance}`)} </p>
					<p color="primary">{city && " | " + city}</p>
				</div>
				{showGiftIcon && (
					<div
						onClick={handleClickGift}
						style={{ display: "flex", alignItems: "center", gap: "10px" }}>
						<img style={{ width: "15px", height: "15px" }} src={giftImg} />
						<img style={{ height: "24px" }} src={youGetGift} />
					</div>
				)}
			</div>

			<div
				className={`mainUserImage ${!showJoinNowBtn ? "hasSubscription2" : ""} ${
					isPicturesPrivate ? "hasPrivatePictures" : ""
				}`}>
				<Link Link to="/account/pictures">
					<img
						src={
							mainPictureUri ? mainPictureUri : gender ? placeholderWomanRound : placeholderManRound
						}
					/>
				</Link>
			</div>
		</div>
	);
};
