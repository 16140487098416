import React, { useState, useEffect } from "react";
import _ from "lodash";
import i18next from "i18next";
import { useDispatch } from "react-redux";

import SelectButtonsQuestion from "components/login/Questions/ButtonGroupQuestion";
import Children from "consts/children";
import ProfileFields from "consts/profileFields";
import { updateProfile } from "redux/profile/actions";
import birthday from "assets/images/registerImg/birthday.png";
import Stance from "consts/stance";
import LoginSteps from "consts/loginSteps";
import DropdownQuestion from "../Questions/DropdownQuestion";
import childrenCount from "consts/childrenCount";

export default ({ profile, stepName, steps, goToNamedStep, onFinalStepFinish }) => {
	const index = steps.indexOf(stepName);
	const total = steps.length;
	const dispatch = useDispatch();
	const [isClickedNext, setIsClickedNext] = useState(false);
	const [range] = useState(Object.values(childrenCount));
	const mainClass =
		process.env.REACT_APP_ENV === "shlish" ? "loginHolder nameHolder" : "loginHolder";

	const handleOnSubmit = (value) => {
		dispatch(updateProfile(ProfileFields.ChildrenCount, value));
		setIsClickedNext(true);
	};

	const handleOnBackButtonClick = () => {
		if (
			profile[ProfileFields.Stance] !== Stance.Mesorati &&
			profile[ProfileFields.Stance] !== Stance.Datlash &&
			steps[index - 1] === LoginSteps.KosherFood
		) {
			goToNamedStep(steps[index - 3]);
		} else {
			goToNamedStep(steps[index - 1]);
		}
	};

	useEffect(() => {
		if (isClickedNext && !_.isNil(profile[ProfileFields.HasChildren])) {
			if (total - 1 <= index) {
				onFinalStepFinish();
			} else {
				goToNamedStep(steps[index + 1]);
			}
		}
	}, [isClickedNext, profile]);

	return (
		<div className={mainClass}>
			<div className="imageForPage">
				<img src={birthday} />
			</div>
			<DropdownQuestion
				options={range}
				title={i18next.t("Login.How many children u have")}
				onSubmit={handleOnSubmit}
				showBackButton={index > 0}
				onBackButtonClick={handleOnBackButtonClick}
				defaultTitle={i18next.t(`Select_${profile[ProfileFields.Gender] || 0}`)}
			/>
		</div>
	);
};
