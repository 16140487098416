import { Button, ModalBody, Modal, ModalHeader, ModalFooter } from "reactstrap";
import usePopups from "hooks/usePopups";
import { getUserProfile } from "redux/profile/selectors";
import { useSelector } from "react-redux";
import useNavigator from "hooks/useNavigator";
import { App as CapacitorApp } from "@capacitor/app";
import { getIsPlatformWeb } from "redux/init/selectors";

export default function LogoutModal() {
	const { closePopup } = usePopups();
	const { name, gender } = useSelector(getUserProfile);
	const isWebPlatform = useSelector(getIsPlatformWeb);
	const { navigateToPath } = useNavigator();

	const handleLogout = () => {
		navigateToPath("/logout");
		closePopup();
	};

	const handleExitSite = () => {
		if (!isWebPlatform) CapacitorApp.exitApp();

		closePopup();
	};

	return (
		<Modal isOpen={true} toggle={closePopup} className="simpleDialog">
			<div className="user-chat-nav ">
				<Button
					color="none"
					type="button"
					onClick={closePopup}
					className="nav-btn"
					id="user-profile-hide">
					<i className="ri-close-line"></i>
				</Button>
			</div>
			<ModalHeader>{`רגע אחד${name ? ` ${name}` : ""}...`}</ModalHeader>
			<ModalBody style={{ fontSize: "1.2rem", padding: "0 0.5rem" }}>
				<>{`אם הכוונה שלך ב`}</>
				<b>{`יציאה מהחשבון `}</b>
				<>{`היא לא להופיע ${!!gender ? "כמחוברת" : "כמחובר"} אונליין באפליקציה - `}</>
				<b>{`אין צורך לבצע יציאה, `}</b>
				{!!gender ? (
					<>{`את יכולה פשוט לסגור את האפליקציה וכך גם לא תצטרכי להתחבר בכל פעם מחדש :)`}</>
				) : (
					<>{`אתה יכול פשוט לסגור את האפליקציה וכך גם לא תצטרך להתחבר בכל פעם מחדש :)`}</>
				)}
				<span
					style={{
						marginTop: "20px",
						display: "block",
						color: "red",
						minWidth: "100%",
						fontSize: "1rem",
						fontWeight: "400",
					}}>{`חשוב לדעת! יציאות חוזרות עלולות לגרום למערכת האבטחה של האתר לזהות את המשתמש שלך כחריג ולחסום את אפשרות הכניסה שלך לכמה שעות.`}</span>

				<b style={{ display: "block", marginTop: "20px" }}>{`אז, מה ברצונך לעשות?`}</b>
			</ModalBody>
			<ModalFooter>
				<Button
					outline
					size="sm"
					color="primary"
					onClick={handleExitSite}
					className="btn-rounded littleBtn active">
					{`רק לסגור את האפליקציה`}
				</Button>
			</ModalFooter>
			<ModalFooter style={{ padding: "0 0 10px" }}>
				<span
					onClick={handleLogout}
					style={{ color: "#495057", textDecoration: "underline", cursor: "pointer" }}>
					{`לצאת מהחשבון שלי`}
				</span>
			</ModalFooter>
		</Modal>
	);
}
