import i18next from "i18next";

import ProfileFields from "consts/profileFields";
import LookingForJsmart from "consts/lookingFor";
import LookingForShlish from "consts/lookingForShlish";
import MultiSelect from "components/MultiSelect";

export default ({ onChange, profile }) => {
	const LookingFor = process.env.REACT_APP_ENV === "shlish" ? LookingForShlish : LookingForJsmart;

	const _onChange = (key, values) =>
		onChange(
			key,
			values.map((item) => Number(item))
		);

	return (
		<MultiSelect
			field={ProfileFields.LookingFor}
			fieldOptions={Object.values(LookingFor)}
			onChange={_onChange}
			initSelected={profile.lookingFor}
			title={i18next.t("Edit Profile.LookingFor")}
			translatePrefix="LookingFor"
		/>
	);
};
