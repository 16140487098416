import { Box, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import moment from "moment";
import speedDatingService from "services/speedDatingService";
import i18next from "i18next";
import { useSelector } from "react-redux";
import { getUserProfile } from "redux/profile/selectors";
import Feedback from "../Feedback";
import PublicProfileService from "services/publicProfile";
import _ from "lodash";
import userSpeedDateStatus from "consts/userSpeedDateStatus";

const notComeToDateAlarm = `לידיעתך - אי הגעה לאירוע עלולה לגרום לריחוק ממפגשי הדייטים`;

export default function ResultsVideo({ speedData = {}, userData = {} }) {
	const [loading, setLoading] = useState(true);
	const [dates, setDates] = useState(null);

	useEffect(async () => {
		const eventKey = speedData?.key;
		if (eventKey && userData?.status === userSpeedDateStatus.arrived) {
			speedDatingService.getMyDating(eventKey).then((resDates) => {
				if (resDates?.data?.dates) {
					const arrTime = Object.values(resDates.data.dates || {}).map(async (val) => {
						let valObj = { ...val };

						const user = await PublicProfileService.fetchProfile(val.uidMatch);
						valObj.name = user?.name || "";
						valObj.dob = user?.dob || "";
						valObj.user = user || {};

						return valObj;
					});
					Promise.all(arrTime).then((data) => {
						setDates(Object.values(data));
						setLoading(false);
					});
				}
			});
		} else setLoading(false);
	}, [speedData?.key]);

	return (
		<>
			<h3 className="title">{`הדייט שהיה ב - ${moment(speedData?.eventDate).format(
				"D/M/YY בשעה HH:mm"
			)}`}</h3>

			<Box className="content">
				{userData?.status !== userSpeedDateStatus.arrived ? (
					<>
						<Box>{`אופס! נראה שלא הגעת לאירוע…`}</Box>
						<Box>{`ממש חבל! יש אנשים שחיכו לך…`}</Box>
						<Box>{notComeToDateAlarm}</Box>
					</>
				) : (
					<>
						{!!loading ? (
							<CircularProgress size={40} className="text-secondary" />
						) : (
							<Box className="wrapFeedbacksBox">
								{!!dates &&
									!!dates?.length &&
									dates.map((item, index) => <FeedbackUserBox key={index} item={item} />)}
							</Box>
						)}
					</>
				)}
			</Box>
		</>
	);
}

function FeedbackUserBox({ item }) {
	const [showPhone, setShowPhone] = useState(false);
	const currentUser = useSelector(getUserProfile);
	const isSuccessDating = !!item?.successDating;
	const iComeToMeet = !!item?.iComeToMeet;
	const myMatchComeToMeet = !!item?.myMatchComeToMeet;

	const hasImage =
		!_.isNil(item?.user?.pictures) && !!item?.user?.pictures[item?.user?.mainPictureName];
	const userImg = hasImage ? item?.user?.pictures[item?.user?.mainPictureName]?.url : "";

	const handleShowPhone = () => {
		setShowPhone(true);
	};

	const getEventFeedback = () => {
		const meetWith = `פגישה עם ${item?.name}`;
		if (!iComeToMeet) {
			return (
				<>
					<h3>{meetWith}</h3>
					<Box>{`אופס! נראה שלא הגעת לפגישה`}</Box>
					<Box>{`ממש חבל! ${item?.name} ${!!currentUser?.gender ? "חיכה" : "חיכתה"} לך…`}</Box>
					<Box style={{ marginTop: "10px" }}>{`*${notComeToDateAlarm}`}</Box>
				</>
			);
		}
		if (!myMatchComeToMeet) {
			return (
				<>
					<h3>{meetWith}</h3>
					<Box>{`נראה ש${item?.name} לא ${
						!!currentUser?.gender ? "הגיע" : "הגיעה"
					} לפגישה, מצטערים,`}</Box>
					<Box style={{ marginTop: "10px" }}>{`*${notComeToDateAlarm}`}</Box>
				</>
			);
		}
		if (!isSuccessDating) {
			return (
				<>
					<h3>
						{meetWith}
						{hasImage && <img src={userImg} />}
					</h3>
					<Box>{`הפעם זה לא הסתדר, אולי בפעם הבאה… `}</Box>
				</>
			);
		}
		if (isSuccessDating) {
			return (
				<>
					<h3>
						{meetWith}
						{hasImage && <img src={userImg} />}
					</h3>
					<h4>{`וואו! נראה שזה הצליח ביניכם`}</h4>
					{showPhone ? (
						<Box>{(item?.userMatchPhone ?? "").replace("+972", "0")}</Box>
					) : (
						<Box>
							<span>{`להצגת מספר הטלפון של ${item?.name} `}</span>
							<u onClick={handleShowPhone}>{`${!!currentUser?.gender ? "לחצי" : "לחץ"} כאן`}</u>
						</Box>
					)}
				</>
			);
		}
	};

	return <Box className="wrapFeedbackItem">{getEventFeedback()}</Box>;
}
