import {
	Box,
	Radio,
	RadioGroup,
	TextField,
	FormGroup,
	FormControlLabel,
	Checkbox,
	CircularProgress,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Button } from "reactstrap";
import moment from "moment";
import DoneIcon from "@mui/icons-material/Done";
import { isValidNumber } from "helpers/mobileNumber";
import validate from "validate.js";
import fillProfileDetailsValidations from "consts/fillProfileDetailsValidations";
import anonymousCall from "services/anonymousCall";
import localStorage from "services/localStorage";

// import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { TimePicker } from "@mui/x-date-pickers/TimePicker";

export default function GiftCardForm({ setData, planKey = "", dataFromDB = {} }) {
	const [firstPage, setFirstPage] = useState(true);
	const [receiverName, setReceiverName] = useState("");
	const [receiverPhone, setReceiverPhone] = useState("");
	const [receiverMail, setReceiverMail] = useState("");
	const [giftTiming, setGiftTiming] = useState("now");
	const [giftTime, setGiftTime] = useState("today");
	const [howToSend, setHowToSend] = useState([]);
	const [timeToSendGift, setTimeToSendGift] = useState(moment().valueOf());
	const [blessing, setBlessing] = useState(
		"היי, משהו קטן ממני עם המון המון אהבה❤️ \nללא ספק מגיע לך את הכי טוב שיש!"
	);
	const [withBlessing, setWithBlessing] = useState(true);
	const [buyerName, setBuyerName] = useState("");
	const [buyerPhone, setBuyerPhone] = useState("");
	const [errorType, setErrorType] = useState("");
	const [anonymousBuyer, setAnonymousBuyer] = useState(false);

	const getNumWithZero = (num) => {
		if (num < 10) return `0${num}`;
		return num;
	};
	const [hour, setHour] = useState(getNumWithZero(moment().hour()));
	const [minuets, setMinuets] = useState(getNumWithZero(moment().minute()));
	const [loading, setLoading] = useState(false);

	const fieldTypes = {
		withBlessing: "withBlessing",
		blessing: "blessing",
		buyerName: "buyerName",
		buyerPhone: "buyerPhone",
		receiverName: "receiverName",
		receiverPhone: "receiverPhone",
		receiverMail: "receiverMail",
		giftTiming: "giftTiming",
		giftTime: "giftTime",
		howToSend: "howToSend",
		hour: "hour",
		minuets: "minuets",
		anonymousBuyer: "anonymousBuyer",
	};

	const handleStoredData = () => {
		const storedData = {
			phone_receiver: receiverPhone ?? "",
			name_receiver: receiverName ?? "",
			email_receiver: receiverMail ?? "",
			phone_buyer: buyerPhone ?? "",
			name_buyer: buyerName ?? "",
			blessing: blessing ?? "",
			scheduled_date: timeToSendGift ?? null,
			methods_send_gift: howToSend ?? [],
			is_scheduled: giftTiming !== "now",
			anonymous_buyer: anonymousBuyer ?? false,
		};
		localStorage.set("giftCardData", JSON.stringify(storedData), 1);
	};
	const getTextErrorByType = (type) => {
		if (!type) return "";
		let txt = "";
		switch (type) {
			case fieldTypes.blessing:
				txt = "הקדשה";
				break;
			case fieldTypes.buyerName:
				txt = "שם שלך";
				break;
			case fieldTypes.buyerPhone:
				txt = "טלפון שלך";
				break;
			case fieldTypes.receiverName:
				txt = "שם מקבל/ת המתנה";
				break;
			case fieldTypes.receiverPhone:
				txt = "טלפון מקבל/ת המתנה";
				break;
			case fieldTypes.receiverMail:
				txt = "מייל מקבל/ת המתנה";
				break;
			case fieldTypes.howToSend:
				txt = "בחירת שליחת המתנה";
				break;
		}
		return `שגיאה בשדה "${txt}"`;
	};

	const handleChangeField = (field, newVal) => {
		switch (field) {
			case fieldTypes.withBlessing:
				return setWithBlessing(fieldTypes.withBlessing === newVal);
			case fieldTypes.anonymousBuyer:
				return setAnonymousBuyer(newVal);
			case fieldTypes.blessing:
				return setBlessing(newVal);
			case fieldTypes.buyerName:
				return setBuyerName(newVal);
			case fieldTypes.buyerPhone:
				return setBuyerPhone(newVal);
			case fieldTypes.receiverName:
				return setReceiverName(newVal);
			case fieldTypes.receiverPhone:
				return setReceiverPhone(newVal);
			case fieldTypes.receiverMail:
				return setReceiverMail(newVal);
			case fieldTypes.giftTiming:
				return setGiftTiming(newVal);
			case fieldTypes.giftTime:
				return setGiftTime(newVal);
			case fieldTypes.howToSend:
				let listSend = [...howToSend];
				if (howToSend?.includes(newVal)) {
					listSend = howToSend.filter((item) => item !== newVal);
				} else {
					listSend.push(newVal);
				}
				return setHowToSend(listSend);
			case fieldTypes.hour:
				const newValNum = Number(newVal);
				if (newValNum < 0 || newValNum > 23) return;
				return setHour(getNumWithZero(newVal));
			case fieldTypes.minuets:
				const newValNumMin = Number(newVal);
				if (newValNumMin < 0 || newValNumMin > 59) return;
				return setMinuets(getNumWithZero(newVal));
		}
	};
	const handleClickNextBtn = () => {
		setErrorType("");
		handleStoredData();
		if (
			!receiverName ||
			!receiverPhone ||
			(howToSend.includes("email") && !receiverMail) ||
			!howToSend?.length
		) {
			if (!receiverName) return setErrorType(fieldTypes.receiverName);
			if (!receiverPhone) return setErrorType(fieldTypes.receiverPhone);
			if (!howToSend?.length) return setErrorType(fieldTypes.howToSend);
			if (howToSend.includes("email") && !receiverMail)
				return setErrorType(fieldTypes.receiverMail);
		}

		const isOnlyNumber = /^\d+$/.test(receiverPhone);
		if (!isOnlyNumber) return setErrorType(fieldTypes.receiverPhone);
		const _isValidNumber = isValidNumber(receiverPhone);
		if (!_isValidNumber) return setErrorType(fieldTypes.receiverPhone);

		if (receiverMail) {
			const emailValidationErrors = validate.single(
				receiverMail,
				fillProfileDetailsValidations.email
			);
			if (emailValidationErrors && emailValidationErrors[0])
				return setErrorType(fieldTypes.receiverMail);
		}

		if (giftTiming !== "now") {
			let addDays = 0;
			if (!hour || !minuets) return;

			if (giftTime == "tomorrow") addDays = 1;
			if (giftTime == "in2days") addDays = 2;

			const timeToSend = moment()
				.add(addDays, "days")
				.hour(Number(hour))
				.minutes(Number(minuets))
				.second(0)
				.valueOf();

			setTimeToSendGift(timeToSend);
		}
		setFirstPage(false);
	};
	const handleSubmitBtn = async () => {
		setErrorType("");
		handleStoredData();

		if (!buyerName || !buyerPhone || (withBlessing && !blessing)) {
			if (!buyerName) return setErrorType(fieldTypes.buyerName);
			if (!buyerPhone) return setErrorType(fieldTypes.buyerPhone);
			if (withBlessing && !blessing) return setErrorType(fieldTypes.blessing);
		}
		const _isValidNumber = isValidNumber(buyerPhone);
		if (!_isValidNumber) return setErrorType(fieldTypes.buyerPhone);
		const affId = localStorage.get("giftAff");
		const dataToStore = {
			phone_receiver: receiverPhone,
			name_receiver: receiverName,
			email_receiver: receiverMail,
			phone_buyer: buyerPhone,
			name_buyer: buyerName,
			blessing: withBlessing ? blessing : "",
			is_scheduled: giftTiming !== "now",
			scheduled_date: timeToSendGift,
			methods_send_gift: howToSend,
			planKey: planKey,
			affId: affId ?? null,
			anonymous_buyer: anonymousBuyer,
		};
		setLoading(true);

		const res = await anonymousCall.createGiftCardSale(dataToStore);
		if (res?.success) {
			const data = { keyGiftCard: res?.keyGiftCard, url: res?.url, ...dataToStore };
			setData(data);
			window.scrollTo(0, 0);
		}
		setLoading(false);
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [firstPage]);

	useEffect(() => {
		if (dataFromDB?.name_receiver) setReceiverName(dataFromDB?.name_receiver);
		if (dataFromDB?.phone_receiver) setReceiverPhone(dataFromDB?.phone_receiver);
		if (dataFromDB?.email_receiver) setReceiverMail(dataFromDB?.email_receiver);
		if (dataFromDB?.methods_send_gift) setHowToSend(dataFromDB?.methods_send_gift ?? []);
		if (dataFromDB?.scheduled_date) setTimeToSendGift(dataFromDB?.scheduled_date);
		if (dataFromDB?.blessing) setBlessing(dataFromDB?.blessing);
		if (dataFromDB?.name_buyer) setBuyerName(dataFromDB?.name_buyer);
		if (dataFromDB?.phone_buyer) setBuyerPhone(dataFromDB?.phone_buyer);
		if (dataFromDB?.anonymous_buyer) setAnonymousBuyer(dataFromDB?.anonymous_buyer);
	}, [dataFromDB]);

	return (
		<Box className="giftCardUserForm">
			<HeaderForm />

			{firstPage ? (
				<DetailsForm
					errorType={errorType}
					getTextErrorByType={getTextErrorByType}
					handleClickNextBtn={handleClickNextBtn}
					handleChangeField={handleChangeField}
					receiverName={receiverName}
					receiverPhone={receiverPhone}
					giftTiming={giftTiming}
					giftTime={giftTime}
					howToSend={howToSend}
					receiverMail={receiverMail}
					hour={hour}
					minuets={minuets}
				/>
			) : (
				<BlessingForm
					errorType={errorType}
					getTextErrorByType={getTextErrorByType}
					anonymousBuyer={anonymousBuyer}
					handleSubmitBtn={handleSubmitBtn}
					handleChangeField={handleChangeField}
					receiverName={receiverName}
					receiverPhone={receiverPhone}
					receiverMail={receiverMail}
					howToSend={howToSend}
					timeToSendGift={timeToSendGift}
					blessing={blessing}
					buyerName={buyerName}
					buyerPhone={buyerPhone}
					withBlessing={withBlessing}
					setFirstPage={setFirstPage}
					loading={loading}
				/>
			)}

			<ProgressBtns firstPage={firstPage} />
		</Box>
	);
}

function HeaderForm() {
	return (
		<>
			<h2>{`רכישת שובר מתנה`}</h2>
			<h2>{`לשליש גן עדן סמארט`}</h2>
		</>
	);
}

function DetailsForm({
	handleChangeField,
	receiverName = "",
	receiverPhone = "",
	giftTiming = "now",
	giftTime = "today",
	howToSend = [],
	receiverMail = "",
	getTextErrorByType = () => {},
	handleClickNextBtn = () => {},
	hour = null,
	minuets = null,
	errorType = "",
}) {
	return (
		<>
			<FormBox headerText={`למי מיועדת המתנה?`}>
				<FormTextField
					handleChangeField={handleChangeField}
					value={receiverName}
					field={"receiverName"}
				/>
			</FormBox>
			<FormBox headerText={`טלפון של מקבל/ת המתנה`}>
				<FormTextField
					handleChangeField={handleChangeField}
					value={receiverPhone}
					field={"receiverPhone"}
				/>
				<Box className="descriptionText">
					{
						"השובר משוייך למספר הטלפון של מקבל המתנה. מקבל המתנה יוכל לבחור מתי להפעיל את המנוי לאחר התחברות לאפליקציה עם מספר הטלפון שלו"
					}
				</Box>
			</FormBox>
			<FormBox headerText={`מתי לשלוח את המתנה?`}>
				<RadioGroup
					row
					defaultValue="now"
					onChange={(e) => handleChangeField("giftTiming", e.target.value)}>
					<FormControlLabel
						value="now"
						control={<Radio style={{ color: "#2d5086" }} />}
						label="עכשיו"
					/>
					<FormControlLabel
						value="later"
						control={<Radio style={{ color: "#2d5086" }} />}
						label="במועד מאוחר יותר"
					/>
				</RadioGroup>
			</FormBox>

			{giftTiming !== "now" && (
				<>
					<FormBox headerText={`בחירת תאריך`}>
						<RadioGroup
							row
							defaultValue="today"
							value={giftTime}
							onChange={(e) => handleChangeField("giftTime", e.target.value)}>
							<FormControlLabel
								value="today"
								control={<Radio style={{ color: "#2d5086" }} />}
								label="היום"
							/>
							<FormControlLabel
								value="tomorrow"
								control={<Radio style={{ color: "#2d5086" }} />}
								label="מחר"
							/>
							<FormControlLabel
								value="in2days"
								control={<Radio style={{ color: "#2d5086" }} />}
								label="מחרתיים"
							/>
						</RadioGroup>
					</FormBox>
					<FormBox headerText={`בחירת שעה`}>
						<Box className="timeWrapper">
							<FormTimeField
								handleChangeField={handleChangeField}
								value={minuets}
								field={"minuets"}
								type="number"
							/>
							<Box className="wrapBlue">
								<Box className="blueCircle"></Box>
								<Box className="blueCircle"></Box>
							</Box>
							<FormTimeField
								handleChangeField={handleChangeField}
								value={hour}
								field={"hour"}
								type="number"
							/>
						</Box>
					</FormBox>
				</>
			)}

			<FormBox headerText={`איך לשלוח את השובר?`}>
				<FormGroup
					onChange={(e) => {
						if (e.target?.name == "textField") return;
						handleChangeField("howToSend", e.target.value);
					}}>
					<FormControlLabel
						value="sms"
						checked={howToSend.includes("sms")}
						control={<Checkbox style={{ color: "#2d5086" }} />}
						label="SMS"
					/>
					<FormControlLabel
						value="email"
						checked={howToSend.includes("email")}
						control={<Checkbox style={{ color: "#2d5086" }} />}
						label="אימייל"
					/>
					{howToSend.includes("email") && (
						<Box style={{ marginRight: "25px" }}>
							<FormTextField
								handleChangeField={handleChangeField}
								value={receiverMail}
								field={"receiverMail"}
								placeHolder="email@mail.com"
							/>
						</Box>
					)}
					<FormControlLabel
						value="print"
						checked={howToSend.includes("print")}
						control={<Checkbox style={{ color: "#2d5086" }} />}
						label="הדפסה לקובץ, אני אתן בעצמי"
					/>
				</FormGroup>
			</FormBox>

			<Box className="wrapGiftCardBtnForm">
				<Button className="nextBtn" onClick={handleClickNextBtn}>
					<span>{"הבא >>"}</span>
				</Button>
				{errorType && <Box style={{ color: "#f65368" }}>{getTextErrorByType(errorType)}</Box>}
			</Box>
		</>
	);
}
function FormBox({ headerText = "", children }) {
	return (
		<Box className="wrapFormBox">
			{headerText && <h4>{headerText}</h4>}
			{children}
		</Box>
	);
}
function FormTextField({ handleChangeField, value, field, placeHolder = "", ...rest }) {
	return (
		<TextField
			className="textGiftCardComponent"
			value={value}
			name="textField"
			sx={{ width: "100%" }}
			inputProps={{ maxLength: field === "blessing" ? 500 : 40 }}
			onChange={(e) => handleChangeField(field, e.target.value)}
			placeholder={placeHolder}
			{...rest}
		/>
	);
}
function FormTimeField({ handleChangeField, value, field, ...rest }) {
	return (
		<TextField
			className="timeGiftCardComponent"
			value={value}
			sx={{ width: "80px" }}
			inputProps={{ maxLength: 2 }}
			onChange={(e) => handleChangeField(field, e.target.value)}
			{...rest}
		/>
	);
}

function ProgressBtns({ firstPage }) {
	return (
		<Box className="progressGiftBtns">
			<Box className="pageBox">
				<span className="number" style={{ background: !firstPage && "#2d5086" }}>
					{!firstPage ? <DoneIcon style={{ color: "#fff" }} /> : 1}
				</span>
				<span style={{ fontWeight: !firstPage && "400" }}>{"ממלאים את פרטי השליחה"}</span>
			</Box>
			<Box className="grayBox"></Box>
			<Box className="pageBox">
				<span
					className="number"
					style={{ background: !!firstPage && "#DFDFDF", color: !!firstPage && "#2d5086" }}>
					2
				</span>
				<span style={{ fontWeight: !!firstPage && "400" }}>{"כותבים הקדשה ושולחים"}</span>
			</Box>
		</Box>
	);
}

function BlessingForm({
	handleChangeField,
	receiverName,
	receiverMail,
	receiverPhone,
	timeToSendGift,
	blessing = "",
	buyerName,
	buyerPhone,
	withBlessing,
	handleSubmitBtn = () => {},
	setFirstPage = () => {},
	howToSend,
	loading = false,
	getTextErrorByType = () => {},
	errorType = "",
	anonymousBuyer = false,
}) {
	useEffect(() => {
		handleChangeField("withBlessing", "withBlessing");
	}, []);
	return (
		<>
			<Box className={"blessingBox"}>
				<Box>
					<span>{`המתנה שלך תישלח ל`}</span>
					<b>{receiverName}</b>
				</Box>
				{!!howToSend?.includes("sms") && (
					<Box>
						<span>{`למספר `}</span>
						<b>{receiverPhone}</b>
					</Box>
				)}
				{!!howToSend?.includes("email") && (
					<Box>
						<span>{`למייל `}</span>
						<b>{receiverMail}</b>
					</Box>
				)}
				<Box>
					<span>{`בתאריך `}</span>
					<b>{moment(timeToSendGift).format("DD.MM.YYYY בשעה HH:mm")}</b>
				</Box>
			</Box>
			<Box className={"blessingBox"}>
				<RadioGroup
					row
					defaultValue="withBlessing"
					onChange={(e) => handleChangeField("withBlessing", e.target.value)}>
					<FormControlLabel
						value="withBlessing"
						control={<Radio style={{ color: "#2d5086" }} />}
						label="רוצה להוסיף הקדשה"
					/>
					<Box style={{ marginRight: "25px", width: "100%" }}>
						<Box className="wrapFormBox" style={{ margin: 0 }}>
							<FormTextField
								handleChangeField={handleChangeField}
								value={blessing}
								field={"blessing"}
								minRows={4}
								multiline={true}
								disabled={!withBlessing}
							/>
						</Box>
					</Box>

					<FormControlLabel
						value="withoutBlessing"
						control={<Radio style={{ color: "#2d5086" }} />}
						label="אעדיף לשלוח ללא הקדשה"
					/>
				</RadioGroup>
			</Box>
			<FormBox headerText={`השם שלך`}>
				<FormTextField
					handleChangeField={handleChangeField}
					value={buyerName}
					field={"buyerName"}
				/>
				<FormControlLabel
					style={{ margin: 0 }}
					value="anonymousBuyer"
					checked={anonymousBuyer}
					control={<Checkbox style={{ color: "#2d5086" }} />}
					label="רוצה לתת מתנה בעילום שם"
					onChange={(e) => handleChangeField("anonymousBuyer", e.target.checked)}
				/>
			</FormBox>
			<FormBox headerText={`הטלפון שלך`}>
				<FormTextField
					handleChangeField={handleChangeField}
					value={buyerPhone}
					field={"buyerPhone"}
				/>
			</FormBox>

			<Box className="wrapGiftCardBtnForm">
				{loading ? (
					<CircularProgress size={40} style={{ color: "#f65368", marginTop: "30px" }} />
				) : (
					<>
						<span
							style={{ textDecoration: "underline", marginTop: "30px" }}
							onClick={() => setFirstPage(true)}>
							{"<< הקודם"}
						</span>
						<Button className="submitBtn" onClick={handleSubmitBtn}>
							<span>{"לתשלום ושליחה >>"}</span>
						</Button>
						{errorType && <Box style={{ color: "#f65368" }}>{getTextErrorByType(errorType)}</Box>}
					</>
				)}
			</Box>
		</>
	);
}
