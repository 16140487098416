import "assets/scss/chat.scss";
import React, { useEffect, useState } from "react";
import { Label, Button } from "reactstrap";
import storage from "../../services/storage";
import { Line, Circle } from "rc-progress";
import "emoji-mart/css/emoji-mart.css";
import i18next from "i18next";

import _ from "lodash";
import { useRef } from "react";
import { Box, CircularProgress, Slider } from "@mui/material";
import { useSelector } from "react-redux";
import { getUserProfile } from "redux/profile/selectors";
import { Microphone } from "@mozartec/capacitor-microphone";
import { Plugins } from "@capacitor/core";
export default ({ userUid, back, save }) => {
	const [isRecording, setIsRecording] = useState(true);
	const [isPlaying, setIsPlaying] = useState(false);
	const [audioChunks, setAudioChunks] = useState(false);
	const [recordingProgress, setRecordingProgress] = useState(0);
	const [timePlaying, setTimePlaying] = useState("00");
	const [timeRecording, setTimeRecording] = useState("00");
	const [totalAudio, setTotalAudio] = useState(0);
	const [playingProgress, setPlayingProgress] = useState(0);
	const [audioPlayer, setAudioPlayer] = useState(null);
	const isRecordingRef = useRef(true);
	const isPlayingRef = useRef(false);
	const [loading, setLoading] = useState(false);
	const { gender } = useSelector(getUserProfile);
	let playbackTimer;

	const startRecording = async () => {
		try {
			clear();

			if (await hasMicrophonePermission()) {
				try {
					const options = {
						duration: 60000, // Optional, in milliseconds (defaults to 0, meaning unlimited)
						sampleRate: 44100, // Optional, in Hz (defaults to 44100)
					};
					await Microphone.startRecording(options);
					isRecordingRef.current = true;
					setIsRecording(true);
					console.log("Recording started");
				} catch (err) {
					console.error("Error starting recording:", err);
				}
			} else {
				clear();
				console.log("Microphone permission not granted");
				// Handle permission issue (e.g., display a message)
			}
		} catch (error) {
			console.error("Error accessing microphone:", error);
		}
	};

	const handleChangeSlider = (e, customizeNum = null) => {
		let time = customizeNum !== null ? customizeNum : e?.target?.value;
		if (!time) time = 0;
		setTimePlaying(formatNumber(Math.floor(time)));
		if (!audioPlayer) return;
		audioPlayer.currentTime = time; //can't be null or 0
	};

	const clear = () => {
		pause();
		stopRecording();
		isRecordingRef.current = false;
		setAudioChunks(false);
		setIsPlaying(false);
		setRecordingProgress(0);
		setPlayingProgress(0);
		setTimePlaying("00");
		setTimeRecording("00");
		setAudioPlayer(null);
	};

	const setProgressRecording = () => {
		const initSeconds = 30;
		let seconds = 30;
		function countdown() {
			if (seconds >= 0 && isRecordingRef.current) {
				setRecordingProgress(((initSeconds - seconds) / 30) * 100);

				seconds--;
				setTimeout(countdown, 1000, [isRecording]); // Call countdown() again after 1 second (1000 milliseconds)
			} else {
				if (isRecording) {
					setTotalAudio(Math.floor(initSeconds - seconds) - 1);
					stopRecording();
				}
			}
			setTimeRecording(formatNumber(initSeconds - seconds - 1));
		}
		countdown();
	};

	useEffect(() => {
		if (isRecording) {
			setProgressRecording();
		}
	}, [isRecording]);

	const setPlayingProgressRecording = () => {
		playbackTimer = setInterval(() => {
			if (audioPlayer) {
				const currentTime = audioPlayer.currentTime;
				const percentage = (currentTime / totalAudio) * 100;
				setTimePlaying(formatNumber(Math.floor(currentTime)));
				console.log("currentTime", currentTime);
				console.log("totalAudio", totalAudio);

				if (currentTime >= totalAudio) {
					setPlayingProgress(100);
					setTimePlaying(formatNumber(Math.floor(totalAudio)));
					clearInterval(playbackTimer);
					setTimeout(() => {
						pause();
						handleChangeSlider(undefined, 0);
					}, 200);
				} else setPlayingProgress(percentage);
			}
		}, 200);
	};

	useEffect(() => {
		if (isPlayingRef.current) {
			setPlayingProgressRecording();
		}
		return () => {
			clearInterval(playbackTimer);
		};
	}, [isPlayingRef.current]);

	const upload = async () => {
		if (audioChunks) {
			setLoading(true);
			//const audioBlob = new Blob(audioChunks, { type: "audio/wav" });
			const fileName = `audio_${userUid}_${Date.now()}.wav`;
			const url = await storage.saveAudio(fileName, audioChunks);
			save(url);
			close();
		}
	};
	const stopRecording = async () => {
		isRecordingRef.current = false;
		setIsRecording(false);
		try {
			const recording = await Microphone.stopRecording();
			console.log("Recording stopped: ", recording);

			console.log(recording.dataUrl);

			let base64String = recording.dataUrl;
			base64String = base64ToBlob(base64String);
			setAudioChunks(base64String);
		} catch (err) {
			console.error("Error stopping recording:", err);
		}
	};

	function base64ToBlob(base64String) {
		const dataUriRegex = /^data:(.+);base64,/;
		if (dataUriRegex.test(base64String)) {
			base64String = base64String.split(",")[1];
		}

		// Decode Base64 string
		const binaryString = atob(base64String);
		const binaryData = new ArrayBuffer(binaryString.length);
		const binaryDataArray = new Uint8Array(binaryData);
		for (let i = 0; i < binaryString.length; i++) {
			binaryDataArray[i] = binaryString.charCodeAt(i);
		}

		// Create a Blob object
		return new Blob([binaryData], { type: "audio/wav" }); // Replace with specific MIME type if known
	}

	const playRecording = () => {
		setIsPlaying(true);
		isPlayingRef.current = true;
		let audio;

		if (audioPlayer) {
			audio = audioPlayer;
		} else {
			const audioUrl = URL.createObjectURL(audioChunks);
			audio = new Audio(audioUrl);
		}

		audio.play();
		setAudioPlayer(audio);
	};
	const pause = () => {
		setIsPlaying(false);
		isPlayingRef.current = false;
		if (audioPlayer) audioPlayer.pause();
	};

	const close = () => {
		clear();
		back();
	};
	const formatNumber = (number) => {
		if (number < 10) {
			return "0" + number;
		} else {
			return "" + number; // Convert to string to maintain consistency
		}
	};

	useEffect(() => {
		startRecording();
		return () => {
			clear();
		};
	}, []);

	const requestMicrophonePermissions = async () => {
		try {
			const status = await Microphone.requestPermissions();
			if (status.microphone === "granted") {
				console.log("Microphone permission granted");
				startRecording();
				// Use the microphone (e.g., start recording)
			} else if (status.microphone === "denied") {
				console.log("Microphone permission denied");
				// Handle denial (e.g., display an informative message)
			} else {
				console.log("Microphone permission prompt not yet shown");
			}
		} catch (err) {
			console.error("Error requesting microphone permission:", err);
		}
	};

	const hasMicrophonePermission = async () => {
		const status = await Microphone.checkPermissions();
		return status.microphone === "granted";
	};

	useEffect(async () => {
		await requestMicrophonePermissions();
	}, []);

	return (
		<>
			<div className="audio-wrapper">
				<Box className="audio-header">
					{!isRecording ? (
						<>
							{audioChunks && !isPlaying && (
								<div className="playBox">
									<Label
										id="play"
										onClick={() => playRecording()}
										className="me-1 btn btn-link text-decoration-none font-size-16 btn-lg waves-effect play_icon">
										{" "}
									</Label>
									<span className="siteColor playText">{`נגן`}</span>
								</div>
							)}

							{audioChunks && !!isPlaying && (
								<Label
									id="pause"
									onClick={() => pause()}
									className="me-1 btn btn-link text-decoration-none font-size-16 btn-lg waves-effect pause_icon">
									{" "}
								</Label>
							)}

							<Slider
								value={timePlaying}
								min={0}
								style={{ color: "#2d5086", marginRight: "8px" }}
								max={totalAudio}
								onChange={handleChangeSlider}
							/>

							<span className="siteColor">{`0:${timePlaying}`}</span>
							{!!loading ? (
								<CircularProgress
									size={40}
									className="text-secondary"
									style={{ minWidth: "40px" }}
								/>
							) : (
								<Button
									onClick={() => upload()}
									color=""
									className="font-size-16 btn-lg chat-send waves-effect waves-light jsmartChatBtn">
									<span style={{ fontSize: "0.7rem" }}>
										{i18next.t(`Chat Message.Send Audio_${gender ?? 0}`)}
									</span>
								</Button>
							)}
						</>
					) : (
						<>
							<span className="siteColor">{`0:${timeRecording}`}</span>
							<span style={{ width: "100%" }}>
								<Line percent={recordingProgress} strokeWidth={3} strokeColor="#2d5086" />
							</span>
							<Label
								id="jsmartRecord"
								onClick={() => stopRecording()}
								className="btn btn-link text-decoration-none font-size-16 btn-lg waves-effect stop_icon">
								{" "}
							</Label>
						</>
					)}
				</Box>

				<div className="audio-bar">
					<div className="audio-bar-box" onClick={() => close()}>
						<Label
							id="close"
							className="me-1 btn btn-link text-decoration-none font-size-16 btn-lg waves-effect trash_icon">
							{" "}
						</Label>
						<span className="siteColor">{`מחיקה`}</span>
					</div>

					{!isRecording && (
						<div className="audio-bar-box" onClick={() => startRecording()}>
							<Label
								id="mic"
								className="me-1 btn btn-link text-decoration-none font-size-16 btn-lg waves-effect mic_icon">
								{" "}
							</Label>
							<span className="siteColor">{`הקלט מחדש`}</span>
						</div>
					)}
				</div>
			</div>
		</>
	);
};
