import i18next from "i18next";
import { Button, Modal, ModalFooter, ModalHeader, ModalBody } from "reactstrap";

import usePopups from "hooks/usePopups";
import { getPopups } from "redux/popups/selectors";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import blogService from "services/blogService";
import localStorage from "services/localStorage";

export default () => {
	const { closePopup } = usePopups();
	const { artKey, commentKey, gender, commentUid } = useSelector(getPopups);
	const loginFromManager = localStorage.get("loginFrommanager");

	const deleteComment = async () => {
		if (!artKey || !commentKey) return;
		blogService.deleteComment(artKey, commentKey, loginFromManager || false, commentUid || null);
		return closePopup();
	};

	return (
		<Modal isOpen={true} toggle={closePopup} className="simpleDialog">
			<ModalHeader>{`האם ${
				gender ? "את בטוחה שאת" : "אתה בטוח שאתה"
			}  רוצה למחוק את התגובה?`}</ModalHeader>
			<ModalFooter>
				<Button
					outline
					size="sm"
					color="primary"
					onClick={deleteComment}
					className="btn-rounded littleBtn">
					{`${gender ? "מחקי" : "מחק"} את התגובה`}
				</Button>

				<Button
					outline
					size="sm"
					color="primary"
					onClick={closePopup}
					className="btn-rounded littleBtn">
					{`חזור`}
				</Button>
			</ModalFooter>
		</Modal>
	);
};
