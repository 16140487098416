import { createSelector } from "reselect";
import ApiRequestStatus from "consts/apiRequestStatus";

const getPromotionsSale = (state) => state?.promotionsSale;

export const getFetchPromotionCardcomApiStatus = createSelector(
	getPromotionsSale,
	(promotion) => promotion.fetchCardcomApiStatus
);

export const getPromotionCardComUrl = createSelector(
	getPromotionsSale,
	(promotion) => promotion.cardComUrl
);

export const getIsPurchasePromoteDone = createSelector(
	getPromotionsSale,
	(promotion) =>
		promotion.purchaseStatus === ApiRequestStatus.SUCCESSFULLY ||
		promotion.purchaseStatus === ApiRequestStatus.FAILED
);

export const getPromotePurchaseStatus = createSelector(
	getPromotionsSale,
	(promotion) => promotion.purchaseStatus
);
