import React, { useEffect, useState } from "react";
import Question from "./Question";
import PhoneInput from "../PhoneInput";
import Recaptcha from "components/recaptchaContainer";
import Loader from "components/Loader";
import isMobile from "helpers/isMobile";

export default ({
	title,
	subTitle,
	onSubmit,
	onChange,
	hasError,
	errMsg,
	showSubmitButton = true,
	showLoader = false,
	className = "",
}) => {
	const [value, setValue] = useState();
	const [isValid, setIsValid] = useState();
	const handleOnChange = (val) => {
		setValue(val);
		onChange(val);
	};
	const handleOnSubmit = () => {
		onSubmit(value);
	};
	const handleOnValidityChange = (_isValid) => {
		setIsValid(!hasError && _isValid);
	};

	useEffect(() => {
		if (hasError) {
			setIsValid(false);
		}
	}, [hasError]);

	return (
		<>
			<Question
				className={className}
				title={title}
				subTitle={subTitle}
				onSubmit={handleOnSubmit}
				showSubmitButton={showSubmitButton}
				isValid={isValid}>
				{showLoader && <Loader className={!isMobile() ? "desktopPhonePage" : ""} />}
				<PhoneInput
					onChange={handleOnChange}
					hasError={hasError}
					errMsg={errMsg}
					onValidityChange={handleOnValidityChange}
					onEnterPressed={handleOnSubmit}
				/>
			</Question>
			<Recaptcha />
		</>
	);
};
