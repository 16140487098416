import firebase from "firebase/app";
import "firebase/database";
import { Observable } from "rxjs";
import httpsCallable from "./httpsCallable";
import localStorage from "./localStorage";

class UserNotificationsService {
	BASE_REF = "users_unread_notifications_count";
	USERS_PURCHASE_STATUS = "users_purchase_status";

	isLoginFromManager() {
		const loginFromManager = localStorage.get("loginFrommanager");
		return !!loginFromManager;
	}

	async resetCountNotification(type) {
		if (this.isLoginFromManager()) return;
		const result = await httpsCallable.call("resetCountNotification", {
			type,
		});
		return result;
	}

	async getUserNotificationCountByType(uid, type) {
		if (!uid || !type) return;

		return (
			(await firebase.database().ref(this.BASE_REF).child(uid).child(type).once("value")).val() ||
			{}
		)?.count;
	}
	async getIsUserHaveGift(uid) {
		if (!uid) return;
		return await httpsCallable.call("getIsUserHaveGiftCard");
	}
	async updateShowPopupGiftCard(keyGiftCard) {
		return await httpsCallable.call("updateShowPopupGiftCard", { keyGiftCard });
	}
	async activateGiftCard(keyGiftCard) {
		return await httpsCallable.call("activateGiftCard", { keyGiftCard });
	}

	listenPurchaseStatus(keyGiftCard) {
		return new Observable((subscriber) => {
			const ref = firebase.database().ref(this.USERS_PURCHASE_STATUS).child(keyGiftCard);

			const callbackFn = ref.on(
				"value",
				(snapshot) => subscriber.next(snapshot.val()),
				(error) => subscriber.error(error)
			);
			return () => ref.off("value", callbackFn);
		});
	}
	listenUserNotificationCount(uid) {
		return new Observable((subscriber) => {
			const ref = firebase.database().ref(this.BASE_REF).child(uid);

			const callbackFn = ref.on(
				"value",
				(snapshot) => subscriber.next(snapshot.val()),
				(error) => subscriber.error(error)
			);
			return () => ref.off("value", callbackFn);
		});
	}
}
export default new UserNotificationsService();
