import firebase from "firebase/app";
import "firebase/database";
import httpsCallable from "./httpsCallable";
import { Observable } from "rxjs";

class MatchmakerService {
	MATCHMAKER = "matchmaker";
	MATCHMAKER_CHAT = "matchmaker_chat";

	listenToMatchmakerChat$(uid) {
		return new Observable((subscriber) => {
			const ref = firebase.database().ref(this.MATCHMAKER_CHAT).child(uid);

			const callbackFn = ref.on(
				"value",
				(snapshot) => subscriber.next(snapshot.val()),
				(error) => subscriber.error(error)
			);
			return () => ref.off("value", callbackFn);
		});
	}

	listenToMatchmaker$(uid) {
		return new Observable((subscriber) => {
			const ref = firebase.database().ref(this.MATCHMAKER).child(uid);

			const callbackFn = ref.on(
				"value",
				(snapshot) => subscriber.next(snapshot.val()),
				(error) => subscriber.error(error)
			);
			return () => ref.off("value", callbackFn);
		});
	}
	async getMatchmaker(uid) {
		return (await firebase.database().ref(this.MATCHMAKER).child(uid).once("value")).val();
	}

	async initChat() {
		return await httpsCallable.call("initMatchmaker", {});
	}

	async sendAnswer(data) {
		return await httpsCallable.call("sendResponseFromUserToMatchMaker", data);
	}
}

export default new MatchmakerService();
