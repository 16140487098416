import { useEffect, useState } from "react";
import speedDatingService from "services/speedDatingService";
import PublicProfileService from "services/publicProfile";
import moment from "moment";
import i18next from "i18next";
import DateItem from "./DateItem";
import { useSelector } from "react-redux";
import { getUserProfile } from "redux/profile/selectors";

export default function DateList({
	eventKey,
	speedData,
	currentDate,
	setListDates = () => {},
	dateDuration,
	setDateDuration = () => {},
	closeVideoDate = () => {},
	toggleDrawer = () => {},
}) {
	const currentUser = useSelector(getUserProfile);
	const [dates, setDates] = useState(null);
	const [displayDates, setDisplayDates] = useState([]);
	const [feedbackQuestions, setFeedbackQuestions] = useState([]);
	const [myFeedback, setMyFeedback] = useState({});

	const calculateBreakTime = (startTime, duration) => {
		const startTimeMoment = moment(startTime, "HH:mm");
		const breakTimeMoment = startTimeMoment.clone().add(duration, "minutes");
		return breakTimeMoment.format("HH:mm");
	};

	const getDatesWithBreaks = (datesData, dateDuration) => {
		const datesWithBreaks = [];
		let lastTime = null;

		Object.values(datesData).forEach((date) => {
			if (lastTime !== null) {
				const breakTime = calculateBreakTime(lastTime, dateDuration);
				datesWithBreaks.push({ name: "הפסקה", time: breakTime });
			}
			datesWithBreaks.push(date);
			lastTime = date.time;
		});

		return datesWithBreaks;
	};

	useEffect(async () => {
		if (eventKey) {
			const data = await speedDatingService.getMyDating(eventKey);
			if (data?.data?.dates) {
				const datesData = data.data.dates;

				if (data.data?.datesData?.dateDuration) setDateDuration(data.data?.datesData?.dateDuration);

				setDates(Object.values(datesData));
			}
		}
		return () => {};
	}, [eventKey]);
	const sortDates = (list) => {
		return list.sort((a, b) => {
			const timeA = moment(a.time, "HH:mm");
			const timeB = moment(b.time, "HH:mm");
			return timeA - timeB;
		});
	};

	useEffect(() => {
		if (!speedData.key || !speedData?.eventDate || !dates || !dates?.length) return;

		const sortedData = sortDates(dates);
		const datesWithBreaks = getDatesWithBreaks(sortedData, dateDuration);

		const arrTime = datesWithBreaks.map(async (val, i) => {
			let valObj = { ...val };
			if (val?.uidMatch) {
				const user = await PublicProfileService.fetchProfile(val.uidMatch);

				valObj.name = user?.name || "";
				valObj.dob = user?.dob || "";
				valObj.user = user || {};
				valObj.endDateTime = moment(val.time, "HH:mm")
					.add(dateDuration, "minutes")
					.set({ second: 0 })
					.valueOf();
			} else {
				valObj.name = i18next.t("speedDate.break");
				if (!!datesWithBreaks[i + 1]) {
					valObj.endDateTime = moment(datesWithBreaks[i + 1].time, "HH:mm")
						.set({ second: 0 })
						.valueOf();
				}
			}

			return valObj;
		});
		Promise.all(arrTime).then((data) => {
			const sortedData = sortDates(data);
			setDisplayDates(sortedData);
			setListDates(sortedData);
		});
	}, [dates]);

	useEffect(() => {
		speedDatingService.getFeedbackQuestions().then((data) => {
			if (!data) return;
			const fqData = Object.values(data || {}).filter((item) => !item?.deleted && !!item?.active);
			setFeedbackQuestions(fqData);
		});
		speedDatingService.getUserFeedback(speedData?.key, currentUser?.uid).then((data) => {
			if (!data) return;
			setMyFeedback(data);
		});
	}, []);

	return (
		<>
			{!!displayDates &&
				!!displayDates?.length &&
				displayDates.map((item, index) => {
					return (
						<DateItem
							key={index}
							item={item}
							current={currentDate}
							feedbackQuestions={feedbackQuestions}
							speedData={speedData}
							myFeedback={myFeedback}
							closeVideoDate={closeVideoDate}
							toggleDrawer={toggleDrawer}
						/>
					);
				})}
		</>
	);
}
// {!!displayTicks && displayTicks > 0 && !currentDate && (
// 	<CountdownTimer
// 		initCount={displayTicks}
// 		handleFinishCount={() => {
// 			setDisplayTicks(0);
// 			handleNextMeeting();
// 		}}
// 		fullView={true}
// 	/>
// )}
