import React, { useState, useEffect } from "react";
import i18next from "i18next";
import Question from "./Question";
import PhoneVerificationInput from "../PhoneVerificationInput";
import Button from "../Button";

export default ({
	title,
	subTitle,
	onSubmit,
	onResendCode,
	hasError,
	setHasError,
	errMsg,
	onBack,
}) => {
	const [value, setValue] = useState();
	const [isValid, setIsValid] = useState();
	const [ticks, setTicks] = useState(60);
	const [resendSmsSent, setResendSmsSent] = useState(false);

	const handleSubmit = () => {
		onSubmit(value);
	};

	const handleResendCode = () => {
		onResendCode(value);
		setResendSmsSent(true);
	};

	const handleChange = (val) => {
		setValue(val);
	};

	const handleValidityChange = (valid) => {
		setIsValid(valid);
		if (valid) {
			handleSubmit();
		}
	};

	useEffect(() => {
		if (ticks > 0) {
			setTimeout(() => {
				setTicks((value) => value - 1);
			}, 1000);
		}
	}, [ticks]);

	return (
		<Question
			isValid={isValid}
			title={title}
			subTitle={subTitle}
			onSubmit={handleSubmit}
			showBackButton={true}
			onBackButtonClick={onBack}>
			<PhoneVerificationInput
				onChange={handleChange}
				onValidityChange={handleValidityChange}
				hasError={hasError}
				setHasError={setHasError}
				errMsg={errMsg}
				onEnterPressed={handleSubmit}
			/>
			<>
				<h5>
					<h5>{i18next.t("Login.Did not receive SMS?")}</h5>
					{ticks > 0 && i18next.t("Login.Resend code in 60 seconds", { seconds: ticks })}
					{!resendSmsSent && ticks === 0 && (
						<Button
							text={i18next.t("Login.Resend code")}
							onClick={handleResendCode}
							className="resend backBtn"
						/>
					)}
				</h5>
			</>
		</Question>
	);
};
