import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import i18next from "i18next";
import useNavigator from "hooks/useNavigator";
import PhoneVerificationQuestion from "components/login/Questions/PhoneVerificationQuestion";
import { verifyPhone, resendCode, resetLoginWithPhone } from "redux/auth/actions";
import {
	getLoginWithPhoneStatus,
	getResendCodeStatus,
	getLoginPhone,
	isUserLoggedIn,
	getIsAuthChecked,
} from "redux/auth/selectors";
import LOGIN_WITH_PHONE_STATUS from "consts/loginWithPhoneStatus";
import RESEND_CODE_STATUS from "consts/resendCodeStatus";
import phone2Image from "assets/images/registerImg/hello_woman.png";
import TermsLink from "components/login/TermsLink";

export default ({ isFromPopup = false }) => {
	const navigator = useNavigator();
	const dispatch = useDispatch();
	const loginPhone = useSelector(getLoginPhone);
	const loginStatus = useSelector(getLoginWithPhoneStatus);
	const resendCodeStatus = useSelector(getResendCodeStatus);
	const isLoggedIn = useSelector(isUserLoggedIn);
	const isAuthChecked = useSelector(getIsAuthChecked);
	const [hasError, setHasError] = useState();
	const [errMsg, setErrMsg] = useState();
	const [isCodeResent, setIsCodeResent] = useState();

	const handleSubmit = (value) => {
		dispatch(verifyPhone(value));
	};
	const handleBack = () => {
		//alert(1);
		navigator.redirectToPhoneWithRefresh();
		//dispatch(resetLoginWithPhone());
	};

	const handleResendCode = (value) => {
		// dispatch(resendCode(value));
		navigator.navigateToAlternativeLogin({ phone: loginPhone });
	};

	useEffect(() => {
		switch (loginStatus) {
			case LOGIN_WITH_PHONE_STATUS.LOGIN_SUCCESSFULLY:
				if (!isFromPopup) navigator.navigateToLoginQuestionsPage();
				break;
			case LOGIN_WITH_PHONE_STATUS.INIT:
				if (!isFromPopup) navigator.navigateToLoginEnterPhonePage();
				break;
			case LOGIN_WITH_PHONE_STATUS.VERIFICATION_FAILED:
				setHasError(true);
				setErrMsg(i18next.t("Phone Error.Wrong Code"));
				break;
			default:
				break;
		}
	}, [loginStatus]);

	useEffect(() => {
		if (!!isFromPopup) return;
		if (!loginPhone) {
			navigator.navigateToLoginEnterPhonePage();
		}
	}, [loginPhone]);

	useEffect(() => {
		switch (resendCodeStatus) {
			case RESEND_CODE_STATUS.RESEND_CODE_FAILED:
				setHasError(true);
				setErrMsg(i18next.t("Login.Resend code failed"));
				break;
			case RESEND_CODE_STATUS.RESEND_CODE_SUCCESSFULLY:
				setIsCodeResent(true);
			default:
				break;
		}
	}, [resendCodeStatus]);

	useEffect(() => {
		if (isLoggedIn && isAuthChecked && !isFromPopup) {
			navigator.navigateToLoginQuestionsPage();
		}
	}, [isLoggedIn, isAuthChecked]);

	return (
		<div className="loginPagesWrapper">
			<div className="loginPopup">
				<div className="loginHolder verifyHolder">
					<div className="imageForPage">
						<img src={phone2Image} />
					</div>
					{isCodeResent && <div>{i18next.t("Fill Profile Details.Resend code successfully")}</div>}
					<PhoneVerificationQuestion
						title={i18next.t("Login.Verify your phone")}
						subTitle={
							<>
								<>{i18next.t("Login.Verify phone text")}</>
								<div className="phoneVer">{loginPhone}</div>
							</>
						}
						hasError={hasError}
						setHasError={setHasError}
						errMsg={errMsg}
						onSubmit={handleSubmit}
						onBack={handleBack}
						onResendCode={handleResendCode}
					/>
					<TermsLink />
				</div>
			</div>
		</div>
	);
};
