import _ from "lodash";
import moment from "moment";

import * as types from "./actionTypes";
import { getLoggedInUserUID } from "redux/auth/selectors";
import QueueService from "services/queue";
import ViewsEvents from "consts/viewEvents";
import ViewsService from "services/views";
import LocalStorageService from "services/localStorage";
import { HEIGHT_MIN, HEIGHT_MAX } from "consts/fillProfileDetailsValidations";
import FilterLimits from "services/fiterLimits";
import localStorage from "services/localStorage";
import { getUserProfile } from "redux/profile/selectors";

export const search = (freeText, sortOption, searchLocation) => {
	return (dispatch, getState) => {
		const state = getState();
		const profile = getUserProfile(state);

		const filters = _.isEmpty(getSearchFilters())
			? profile?.searchFilters || {}
			: getSearchFilters();

		return dispatch({
			type: types.SEARCH_FETCH,
			freeText,
			filters,
			selectedSortOption: sortOption,
			searchLocation,
		});
	};
};

export const searchLoadMore = (freeText, searchLocation) => {
	return (dispatch, getState) => {
		const state = getState();
		const profile = getUserProfile(state);

		const filters = _.isEmpty(getSearchFilters())
			? profile?.searchFilters || {}
			: getSearchFilters();

		return dispatch({ type: types.SEARCH_FETCH_LOAD_MORE, freeText, filters, searchLocation });
	};
};

export const searchLike = (userUid) => {
	return { type: types.SEARCH_LIKE, user: userUid };
};

export const viewUser = (userUid) => {
	return (dispatch, getState) => {
		const loginFromManager = LocalStorageService.get("loginFrommanager");

		const state = getState();
		const uid = getLoggedInUserUID(state);
		if (!loginFromManager && uid && uid !== userUid) {
			QueueService.sendEvent(uid, ViewsEvents.View, userUid);
			ViewsService.view(uid, userUid);
		}
		return dispatch({ type: types.SEARCH_VIEW, user: userUid });
	};
};

export const setSearchType = (searchType) => {
	return { type: types.SEARCH_TYPE, payload: searchType };
};

export const updateRedPoint = () => {
	return { type: types.SEARCH_UPDATE_RED_POINT };
};

export const getSearchFilters = () => JSON.parse(LocalStorageService.get("currentFilters") || "{}");

export const setSearchFilters = (filters) =>
	LocalStorageService.set("currentFilters", JSON.stringify(filters));

export const getFiltersIsEmpty = (profile) => {
	const filters = getSearchFilters();
	return _.isEmpty(filters) || isDefaultValuesFilters(filters, profile);
};

const isDefaultValuesFilters = (filters, profile) => {
	const { min, max } = FilterLimits.getDefaultAgeRange(profile.gender, profile.dob);
	const heightIsDefault =
		!filters.Height || (filters.Height.from == HEIGHT_MIN && filters.Height.to == HEIGHT_MAX);
	const ageIsDefault =
		!filters.Age ||
		(moment()
			.endOf("year")
			.diff(filters.Age.from * 1000, "year") == max &&
			moment()
				.startOf("year")
				.diff(filters.Age.to * 1000, "year") == min);
	const filterHasOtherKeys = Object.keys(filters).find((key) => key !== "Height" && key != "Age");
	return heightIsDefault && ageIsDefault && !filterHasOtherKeys;
};
