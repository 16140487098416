import firebase from "firebase/app";
import "firebase/database";
import AppService from "./app";

class FcmTokenService {
	BASE_REF = "push_tokens";

	async fetchUserToken(uid) {
		if (!uid) return;
		return !!(await firebase.database().ref(this.BASE_REF).child(uid).once("value")).val();
	}
	async saveFcmToken(uid, fcmToken) {
		const [platform, version] = await Promise.all([
			AppService.getPlatform(),
			AppService.getAppVersion(),
		]);
		if (!!platform && !!version && !!uid && !!fcmToken) {
			await firebase.database().ref(this.BASE_REF).child(uid).child(fcmToken).update({
				timeStamp: firebase.database.ServerValue.TIMESTAMP,
				platform: platform,
				version: version,
				appVersion: version,
			});
		}
	}

	async removeCurrentFcmToken(uid) {
		const fcmToken = await this.getFcmTokenFromDevice();
		if (fcmToken) {
			return firebase.database().ref(this.BASE_REF).child(uid).child(fcmToken).remove();
		}
	}
}

export default new FcmTokenService();
