import httpsCallable from "./httpsCallable";
import firebase from "firebase/app";
import "firebase/storage";
import { Observable } from "rxjs";

class surveyService {
	BASE_REF = "survey";
	SURVEY_RESULT = "survey_result";
	SURVEY_RESULT_BY_USERS = "survey_result_by_users";
	USERS_SURVEYS = "users_surveys";

	listenToSurvey$() {
		console.log("listen-survey");

		return new Observable((subscriber) => {
			let ref = firebase.database().ref(this.BASE_REF);
			ref.on(
				"value",
				(snapshot) => {
					if (!!snapshot) subscriber.next(snapshot.val());
				},
				(error) => subscriber.error(error)
			);
			return () => ref.off("value");
		});
	}

	async getSurvey() {
		return (await firebase.database().ref(this.BASE_REF).once("value")).val();
	}

	async saveSurvey(data) {
		return await httpsCallable.call("saveSurvey", data);
	}
	async getSurveyByUser() {
		const uid = firebase.auth().currentUser?.uid;
		if (!uid) return;

		const res = (await firebase.database().ref(this.USERS_SURVEYS).child(uid).once("value")).val();
		return res;
	}

	async getSurveyResults(key) {
		if (!key) return;
		const res = (await firebase.database().ref(this.SURVEY_RESULT).child(key).once("value")).val();
		return res;
	}

	listenToSurveyResults$(key) {
		console.log("listen-survey");

		return new Observable((subscriber) => {
			let ref = firebase.database().ref(this.SURVEY_RESULT).child(key);
			ref.on(
				"value",
				(snapshot) => {
					if (!!snapshot) subscriber.next(snapshot.val());
				},
				(error) => subscriber.error(error)
			);
			return () => ref.off("value");
		});
	}
}

export default new surveyService();
