import share from "assets/images/share.svg";
import { useSelector } from "react-redux";
import { getUserProfile } from "redux/profile/selectors";
import { isEmpty } from "lodash";
import profileFields from "consts/profileFields";
import currentLanguage from "helpers/currentLanguage";
import { getAgeByDob } from "helpers/date";
import i18next from "i18next";

export default ({ profile = {} }) => {
	const myUser = useSelector(getUserProfile);

	const shareUser = async () => {
		const myId = !!myUser?.internalID ? myUser?.internalID : myUser?.uid;
		const long_city = profile[profileFields.Cites[currentLanguage()]];
		const city = long_city?.split(",")[0];

		if (!!profile?.uid) {
			const text = `היי מה קורה?😉\nיש פה כרטיס בדיוק עבורך באפליקציית ההכרויות של ${
				process.env.REACT_APP_ENV_NAME
			} :\n${profile?.name}, ${i18next.t(`Stance_${profile.gender || 0}.${profile.stance}`)}, ${
				!!profile?.gender ? "בת" : "בן"
			} ${profile.age || getAgeByDob(profile.dob) || ""} מ${
				!!city ? city : ""
			} 😍\nמה דעתך?\n\nלצפיה בכרטיס :\n ${process.env.REACT_APP_DOMAIN}/user/${profile?.uid}?f=${
				myId ?? ""
			}`;

			const shareUrl = `https://api.whatsapp.com/send?text=${text}`;
			return window.open(shareUrl);
		}
	};
	return <img className="shareButton" onClick={() => shareUser()} src={share} alt="share" />;
};

// let filesArray = null;

// if (
// 	!!profile?.mainPictureName &&
// 	!isEmpty(profile?.pictures) &&
// 	!!profile?.pictures[profile?.mainPictureName]
// ) {
// 	const userImg = profile?.pictures[profile?.mainPictureName].url;
// }

// try {
// 	return await navigator.share({
// 		title: `מה דעתך על ${profile?.name}`,
// 		text: `היי מה קורה?😉\nיש פה כרטיס בדיוק עבורך באפליקציית ההכרויות של J-smart :\n${
// 			profile?.name
// 		}, ${i18next.t(`Stance_${profile.gender || 0}.${profile.stance}`)}, ${
// 			!!profile?.gender ? "בת" : "בן"
// 		} ${profile.age || getAgeByDob(profile.dob) || ""} מ${
// 			!!city ? city : ""
// 		} 😍\nמה דעתך?\n\nלצפיה בכרטיס :\n`,
// 		url: `https://jsmartapp.co.il/user/${profile?.uid}?f=${myId ?? ""}`,
// 		// filesArray: filesArray ? filesArray : null,
// 	});
// } catch (error) {
// 	console.log(error.message);
// }
