import "assets/scss/giftCard/giftCard.scss";
import { Box, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import anonymousCall from "services/anonymousCall";
import giftCard from "assets/images/giftCard/giftCard.png";
import { Button } from "reactstrap";
import { useSelector } from "react-redux";
import { getUserProfile } from "redux/profile/selectors";
import usePopups from "hooks/usePopups";
import CustomQRCode from "helpers/QrCode";
import unreadNotificationService from "services/unreadNotification.service";
import useNavigator from "hooks/useNavigator";

export default function ReceivedGiftCardPage({ giftCardData = null, isPopup = false }) {
	const { showLoginPopup, showFittedMessagePopup, closePopup } = usePopups();
	let { keyGiftCard } = useParams();
	const [data, setData] = useState();
	const [isLoading, setIsLoading] = useState(false);
	const [activated, setActivated] = useState(false);
	const { uid, name, gender } = useSelector(getUserProfile);
	const location = useLocation();
	const stateData = location.state;
	const history = useHistory();
	const { navigateToPath } = useNavigator();

	const openInNewTab = (e) => {
		e.preventDefault();
		window.open("/terms", "_blank", "toolbar=0,location=0,menubar=0,width=600,height=600");
	};
	const activateGiftCard = async () => {
		setIsLoading(true);
		const res = await unreadNotificationService.activateGiftCard(
			keyGiftCard || giftCardData?.keyGiftCard
		);
		if (!res.data?.success) {
			showFittedMessagePopup("הפעלת השובר נכשלה");
		} else {
			setActivated(true);
		}

		setIsLoading(false);
	};

	const handleNavigateToHome = () => {
		!!isPopup ? closePopup() : navigateToPath("/");
	};

	const handleClick = () => {
		history.replace({
			pathname: location.pathname, // Stay on the same path
			state: null, // Clear the state
		});

		if (uid || isPopup) {
			return activateGiftCard();
		} else {
			showLoginPopup(() => activateGiftCard());
		}
	};

	useEffect(async () => {
		window.scrollTo(0, 0);

		if (giftCardData) {
			return setData(giftCardData);
		}

		if (keyGiftCard) {
			const res = await anonymousCall.getGiftCardBlessing(keyGiftCard);
			if (res?.success) {
				setData(res?.data);
			}
		}
	}, []);

	useEffect(async () => {
		if (!data?.status) return;
		if (data?.status === "activate") return setActivated(true);

		if (stateData?.p) {
			window.print();
		}
	}, [data]);

	return (
		<Box className="receivedGiftCardPage">
			<Box className="headerImage">
				<img src={giftCard} className="giftCardImg" />
			</Box>
			{activated ? (
				<Box className="receivedBox activatedGift">
					<h3 className="">{`איזה כיף ${name}! המתנה הופעלה בהצלחה מאחלים ${
						gender ? "שתמצאי" : "שתמצא"
					} את החצי השני שלך במהרה ❤️`}</h3>
					<Button className="activeGiftBtn" onClick={handleNavigateToHome}>
						<span>{"להמשך גלישה באפליקציה"}</span>
					</Button>
				</Box>
			) : (
				<>
					<b className="receivedBox">{`איזה כיף! קיבלת מנוי מתנה ${
						data?.name_buyer && !data?.anonymous_buyer ? `מ${data?.name_buyer} ` : ""
					}לשליש גן עדן סמארט ל-3 חודשים`}</b>
					{!!data?.status && !isLoading ? (
						<>
							{!!data?.blessing && <Box className="blessing">{data?.blessing}</Box>}
							<Button className="activeGiftBtn" onClick={handleClick}>
								<span>{"הפעלת המתנה"}</span>
							</Button>
						</>
					) : (
						<CircularProgress size={40} style={{ color: "#f65368", marginTop: "30px" }} />
					)}

					<Box className="receivedBox" style={{ margin: "30px" }}>
						<Box>{`לחיצה על הפעלת המתנה תעביר אותך לאפליקציית "שליש גן עדן סמארט". שם, לאחר התחברות עם מספר הטלפון הנייד שהוזן בעת רכישת השובר ${
							data?.phone_receiver ? `${data?.phone_receiver} ` : ""
						} ניתן יהיה להפעיל את המנוי.`}</Box>
						<Box>
							{"לעזרה ניתן לפנות אלינו במייל לinfo@shlish.co.il או בטלפון/וואטסאפ 086519660 . "}
						</Box>
						<Box>
							{"חשוב לדעת - מימוש השובר תקף למשך 24 חודשים מיום הרכישה. בכפוף "}
							<a className="underLine" onClick={openInNewTab}>
								{" לתקנון."}
							</a>
						</Box>
					</Box>

					{keyGiftCard && (
						<CustomQRCode
							style={{ margin: "30px 0" }}
							url={`${process.env.REACT_APP_DOMAIN}/received_giftcard/${keyGiftCard}`}
							// fgColor="#2d5086"
						/>
					)}
				</>
			)}
		</Box>
	);
}
