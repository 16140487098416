import { Box } from "@mui/material";
import FooterBtn from "../FooterBtn";
import { useState } from "react";
import i18next from "i18next";
import useNavigator from "hooks/useNavigator";
import speedDatingService from "services/speedDatingService";

export default function AddPayment({ speedData = {} }) {
	const [loading, setLoading] = useState(false);
	const { navigateToPayVideoPage } = useNavigator();

	const getPayment = async () => {
		if (!speedData?.key) return;

		const res = await speedDatingService.getSpeedDatingPaymentUrl(speedData?.key);
		if (res.data?.success) {
			navigateToPayVideoPage({ urlName: speedData.urlName, iframeUrlLink: res.data?.url });
		}
		setLoading(false);
	};

	const handleClick = () => {
		setLoading(true);
		getPayment();
	};

	return (
		<>
			<Box>
				<h3 className="title">{`איזה כיף שבחרת להירשם לאירוע!`}</h3>
				<h3 className="title">{`הולך להיות מעולה!!`}</h3>
			</Box>

			<Box className="text">
				{`התשלום עבור האירוע הינו ${
					speedData?.price ?? ""
				} ש"ח, ויגבה מכרטיס האשראי שלך רק לאחר שנוודא שאכן קיבלת לפחות ${
					speedData?.minDatesForUser ?? 1
				} דייטים פוטנציאלים לערב הספידייט הקרוב.`}
			</Box>
			<Box className="footer">
				<FooterBtn handleClick={handleClick} text={i18next.t("למעבר לתשלום")} loading={loading} />
			</Box>
		</>
	);
}
