import _ from "lodash";
import articlesStatus from "consts/articlesStatus";
import { getAgeByDob } from "./date";
import moment from "moment";

export default (user, listArticles = [], hasSubscription = false, createDateTime) => {
	let tempList = listArticles
		.filter((art) => {
			if (art?.status !== articlesStatus.published) return false;
			if (!!art?.filterUsers && user) {
				const filters = art.filterUsers;
				const userAge = Number(user?.age || getAgeByDob(user?.dob));

				if (!!filters?.fromAge && userAge < Number(filters.fromAge)) return false;
				if (!!filters?.toAge && userAge > Number(filters.toAge)) return false;
				if (!!filters?.maritalStatus && !filters?.maritalStatus.includes(user?.maritalStatus))
					return false;
				if (!!filters?.stance && !filters?.stance.includes(user?.stance)) return false;
				if (!!filters?.gender && !filters?.gender.includes(user?.gender)) return false;
				if (!!filters?.rating && !filters?.rating.includes(user?.manualRating)) return false;
				if (!!filters?.withSubscription && !filters?.withSubscription.includes(!!hasSubscription))
					return false;
				if (!!filters?.toNewUsers && createDateTime) {
					const parsedDate = moment(parseInt(createDateTime, 10));
					const twoWeeksAgo = moment().subtract(2, "weeks");
					const isWithinLastTwoWeeks = parsedDate.isSameOrAfter(twoWeeksAgo);
					if (!isWithinLastTwoWeeks) return false;
				}
			}
			return true;
		})
		.sort((a, b) => {
			const priorityA = a?.filterUsers?.priority || 1;
			const priorityB = b?.filterUsers?.priority || 1;

			if (priorityA !== priorityB) {
				return priorityB - priorityA;
			}

			// If priorities are the same, sort by created date in ascending order
			const createdA = moment(a?.created || 0).valueOf();
			const createdB = moment(b?.created || 0).valueOf();

			return createdB - createdA;
		});

	return tempList;
};
