import i18next from "i18next";
import { useSelector } from "react-redux";
import { Button, ModalBody, Modal, ModalHeader, ModalFooter } from "reactstrap";

import { getUserProfile } from "redux/profile/selectors";
import usePopups from "hooks/usePopups";
import { Box } from "@mui/material";
import { getUserPublicProfile } from "redux/publicProfiles/selectors";
import { getPopups } from "redux/popups/selectors";

export default () => {
	const profile = useSelector(getUserProfile);
	const active_userId = useSelector((state) => state.chat.active_userId);
	const activeUserProfile = useSelector(getUserPublicProfile(active_userId)) || {};
	const { currentUserProfile } = useSelector(getPopups);
	const collageUser = currentUserProfile || activeUserProfile;

	const { closePopup } = usePopups();

	return (
		<Modal isOpen={true} toggle={closePopup} className="simpleDialog">
			<div className="user-chat-nav ">
				<Button
					color="none"
					type="button"
					onClick={closePopup}
					className="nav-btn"
					id="user-profile-hide">
					<i className="ri-close-line"></i>
				</Button>
			</div>
			<ModalHeader>
				<Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "5px" }}>
					<Box sx={{ textAlign: "start" }}>{`היי ${profile?.name ?? ""},`}</Box>
					<Box sx={{ textAlign: "start" }}>
						{i18next.t("Restriction Messages.Subscriber.Header", {
							collageName: collageUser.name,
						})}
					</Box>
				</Box>
			</ModalHeader>
			<ModalBody style={{ fontSize: "1.2rem" }}>
				{i18next.t(`Restriction Messages.Subscriber.Body_${profile.gender}`, {
					collageName: collageUser.name,
				})}
			</ModalBody>
			<ModalFooter>
				<Button
					outline
					size="sm"
					color="primary"
					onClick={closePopup}
					className="btn-rounded littleBtn">
					{i18next.t("Ok")}
				</Button>
			</ModalFooter>
		</Modal>
	);
};
