import "assets/scss/giftCard/giftCard.scss";
import { Box } from "@mui/material";
import HeaderPurchaseGiftCard from "components/giftCard/HeaderPurchaseGiftCard";
import GiftCardForm from "components/giftCard/GiftCardForm";
import { useEffect, useState } from "react";
import CardcomGiftCard from "components/giftCard/CardcomGiftCard";
import useNavigator from "hooks/useNavigator";
import anonymousCall from "services/anonymousCall";
import { useParams } from "react-router-dom";
import localStorage from "services/localStorage";

export default function PurchaseGiftCardPage() {
	const { navigateToThanksGiftCard } = useNavigator();
	const [data, setData] = useState();
	const [isSuccessPurchase, setIsSuccessPurchase] = useState(false);
	const [planKey, setPlanKey] = useState();
	const { keyGiftCard } = useParams();

	useEffect(() => {
		if (isSuccessPurchase) navigateToThanksGiftCard(data);
	}, [isSuccessPurchase]);

	useEffect(async () => {
		const plan = await anonymousCall.getGiftCardPlan();
		if (plan) setPlanKey(plan?.key || null);
	}, []);

	useEffect(async () => {
		const storedData = localStorage.get("giftCardData");

		if (keyGiftCard) {
			const res = await anonymousCall.getGiftCardBlessing(keyGiftCard);
			if (res?.success) {
				setData(res?.data);
			}
		} else if (storedData) {
			const parsedData = JSON.parse(storedData);

			const stored = {
				name_receiver: parsedData?.name_receiver ?? null,
				phone_receiver: parsedData?.phone_receiver ?? null,
				email_receiver: parsedData?.email_receiver ?? null,
				methods_send_gift: parsedData?.methods_send_gift ?? [],
				scheduled_date: parsedData?.scheduled_date ?? null,
				blessing: parsedData?.blessing ?? null,
				name_buyer: parsedData?.name_buyer ?? null,
				phone_buyer: parsedData?.phone_buyer ?? null,
				anonymous_buyer: parsedData?.anonymous_buyer ?? null,
			};
			setData(stored);
		}
	}, []);

	return (
		<Box className="purchaseGiftCardPage">
			<HeaderPurchaseGiftCard data={data} />
			{!data?.url ? (
				<GiftCardForm setData={setData} planKey={planKey} dataFromDB={data} />
			) : (
				<CardcomGiftCard setIsSuccessPurchase={setIsSuccessPurchase} data={data} />
			)}
		</Box>
	);
}
