import { Box, Button, CircularProgress, TextField } from "@mui/material";
import i18next from "i18next";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import sendWeb from "assets/images/icons/newSendIcon.svg";
import blogService from "services/blogService";
import { getUserProfile } from "redux/profile/selectors";
import { getFirstCharImg } from "helpers/getFirstCharImg";

export default function BlogAddComment({ articleData = {} }) {
	const userProfile = useSelector(getUserProfile) || {};
	const mainPictureUri =
		userProfile.pictures?.[userProfile.mainPictureName]?.url || getFirstCharImg(userProfile);
	const [isLoading, setIsLoading] = useState(false);
	const [text, setText] = useState("");
	const [canSend, setCanSend] = useState(true);

	const addComment = async (e) => {
		e.preventDefault();
		if (!text || !userProfile?.uid) return;
		if (text && articleData.key && !!canSend) {
			setIsLoading(true);

			const trimText = `${text ?? ""}`.trim();
			await blogService.addComment(articleData.key, trimText).then((data) => {
				if (data.data?.success) setCanSend(false);
				setText("");
			});
			setIsLoading(false);
		}
	};

	const sendCommentBtnTxt = [
		<img src={sendWeb} />,
		<span style={{ marginRight: "5px" }}>{userProfile.gender ? "שלחי" : "שלח"}</span>,
	];

	if (!userProfile) return <></>;

	return (
		<form className="addCommentForm">
			<Box className="wrapTxtBtn">
				<div className="userImage">
					<img src={mainPictureUri} />
				</div>
				<TextField
					// onKeyPress={(e) => {
					// 	e.key === "Enter" && e.preventDefault();
					// }}
					className="textComponent"
					value={text}
					placeholder={` ${userProfile.gender ? "הוסיפי תגובה.." : "הוסף תגובה.."}`}
					minRows={2}
					sx={{ width: "100%" }}
					multiline={true}
					onChange={(e) => setText(e.target.value)}></TextField>
			</Box>
			{isLoading ? (
				<Box>
					<CircularProgress sx={{ width: "50px", color: "#2D5086" }} />
				</Box>
			) : (
				<Button
					variant=""
					type="button"
					width="50px"
					className="sendCommentBtn"
					sx={{ background: "#2D5086 !important", color: "#fff", borderRadius: "20px" }}
					onClick={addComment}>
					{sendCommentBtnTxt}
				</Button>
			)}
		</form>
	);
}
