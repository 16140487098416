import { Box } from "@mui/material";
import VideoRegister from "./VideoRegister";
import EmptySpeedDate from "./EmptySpeedDate";
import ConfirmParticipation from "./ConfirmParticipation";
import userSpeedDateStatus from "consts/userSpeedDateStatus";
import NotFoundMatch from "./NotFoundMatch";
import AddPayment from "./AddPayment";
import moment from "moment";
import ResultsVideo from "./ResultsVideo";
import UpdateResults from "./UpdateResults";
import speedDateStatus from "consts/speedDateStatus";

export default function AccountVideoByStatus({
	userData = {},
	speedData = {},
	noSpeedData = false,
}) {
	const getComponent = () => {
		if (noSpeedData) {
			return <EmptySpeedDate />;
		} else if (
			!userData?.uid ||
			userData?.status === userSpeedDateStatus.canceled ||
			(speedData?.status !== userSpeedDateStatus.confirmed &&
				!userData?.uid &&
				speedData?.endUsersRegister &&
				moment().isAfter(moment(speedData?.endUsersRegister))) //register closed
		) {
			return <VideoRegister speedData={speedData} userData={userData} />;
		} else if (userData?.status === userSpeedDateStatus.notMatchDate) {
			return <NotFoundMatch speedData={speedData} />;
		} else if (speedData?.status === speedDateStatus.completed && !!userData?.uid) {
			return <ResultsVideo speedData={speedData} userData={userData} />;
		} else if (
			userData?.uid &&
			userData?.status === userSpeedDateStatus.start &&
			!!speedData?.price
		) {
			return <AddPayment speedData={speedData} userData={userData} />;
		} else if (
			speedData?.status === speedDateStatus.matched &&
			moment().isAfter(moment(speedData?.eventDate).add(2, "hour"))
		) {
			return <UpdateResults speedData={speedData} userData={userData} />;
		} else if (userData?.uid) {
			return <ConfirmParticipation speedData={speedData} userData={userData} />;
		}
	};
	return <Box className="wrapInnerVideoBox">{getComponent()}</Box>;
}
