import { mergeMap, catchError, repeat } from "rxjs/operators";
import { ofType } from "redux-observable";
import { of } from "rxjs";
import * as SubscriptionTypes from "redux/subscription/actionTypes";
import * as types from "./actionTypes";
import TestsService from "services/tests";
import { getLoggedInUserUID } from "redux/auth/selectors";

export const saveForTest = (action$, store) =>
	action$.pipe(
		ofType(SubscriptionTypes.SUBSCRIPTIONS_GOOGLE_STATUS_CHANGED),
		mergeMap(async ({ payload }) => {
			try {
				const state = store.value;
				const loggedInUserUID = getLoggedInUserUID(state);
				console.log("save for test " + JSON.stringify(payload));
				await TestsService.test(loggedInUserUID, payload);
				console.log("saved for test");
				return {
					type: types.TEST_SAVE_SUCCESSFULLY,
				};
			} catch (error) {
				return {
					type: types.TEST_SAVE_FAILED,
					payload: error?.message,
				};
			}
		}),
		catchError((error) => {
			return of({
				type: types.TEST_SAVE_FAILED,
				payload: error?.message,
			});
		}),
		repeat()
	);
