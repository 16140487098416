import { Box } from "@mui/material";
import EventCountdown from "./EventCountdown";
import turquoiseHeart from "assets/images/turquoiseHeart.png";
import moment from "moment";

export default function UpcomingEvent({ event = {} }) {
	const eventDate = moment(event.eventDate);
	const formattedTime = eventDate.format("HH:mm");
	const formattedDate = eventDate.format("D.M.YY");

	return (
		<Box className="upcomingEventBox">
			<Box className="text">{`האירוע הקרוב`}</Box>
			<Box className="formattedDate">
				<span>{formattedDate}</span>
				<img src={turquoiseHeart} />
				<span>{formattedTime}</span>
			</Box>
			{event?.eventDate && <EventCountdown eventDate={event.eventDate} />}
		</Box>
	);
}
