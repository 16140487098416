import i18next from "i18next";
import { Button, Modal, ModalBody } from "reactstrap";
import {
	Card,
	CardContent,
	Typography,
	IconButton,
	Rating,
	CircularProgress,
	TextField,
} from "@mui/material";
import { Star } from "@mui/icons-material";
import usePopups from "hooks/usePopups";
import { useEffect, useState } from "react";
import "assets/scss/rating/rating.scss";
import starRating from "assets/images/starRating.svg";
import arrowRating from "assets/images/arrowRating.svg";
import emojiRating from "assets/images/emojiRating.svg";
import { useSelector } from "react-redux";
import { getUserProfile } from "redux/profile/selectors";
import ratingUsService from "services/ratingUsService";
import AppServices from "services/app";
import { getIsPlatformAndroid, getIsPlatformIOS, getPlatform } from "redux/init/selectors";

export default () => {
	const { closePopup } = usePopups();
	const [rating, setRating] = useState(0);
	const [comment, setComment] = useState("");
	const [loading, setLoading] = useState(false);
	const [ratingDisabled, setRatingDisabled] = useState(false);
	const [openCommentOption, setOpenCommentOption] = useState(false);
	const [openRatingInApp, setOpenRatingInApp] = useState(false);
	const { gender } = useSelector(getUserProfile) || {};
	const isPlatformAndroid = useSelector(getIsPlatformAndroid);
	const isPlatformIOS = useSelector(getIsPlatformIOS);
	const platform = useSelector(getPlatform);

	const handleRating = (event, value) => {
		if (ratingDisabled) return;
		const values = {
			1: 5,
			2: 4,
			3: 3,
			4: 2,
			5: 1,
		};

		setRating(values[value] || 0); // Set the rating when it changes
	};

	const handleOpenRateApp = async () => {
		if (isPlatformIOS)
			return await AppServices.openInbrowser(
				"https://apps.apple.com/us/app/%D7%A9%D7%9C%D7%99%D7%A9-%D7%92%D7%9F-%D7%A2%D7%93%D7%9F-%D7%97%D7%91%D7%A8%D7%99%D7%9D/id6470799347?action=write-review"
			);
		if (isPlatformAndroid)
			return await AppServices.openInbrowser(
				"https://play.google.com/store/apps/details?id=com.shlish.friends"
			);
	};

	const handleSendRating = async (event) => {
		event.preventDefault();
		if (!rating || !!ratingDisabled) return;
		setLoading(true);

		const res = await ratingUsService.updateUserRatingUs(rating, platform);
		setLoading(false);
		setRatingDisabled(true);

		if (rating === 5) {
			if (!isPlatformIOS && !isPlatformAndroid) closePopup();
			else setOpenRatingInApp(true);
		} else {
			setOpenCommentOption(true);
		}
	};
	const handleSendCommentRating = async (event) => {
		event.preventDefault();
		setLoading(true);
		if (comment) {
			const res = await ratingUsService.updateUserCommentRatingUs(comment);
		}

		setLoading(false);
		closePopup();
	};
	const getTextComment = () => {
		return `${gender ? "את יכולה" : "אתה יכול"} לכתוב את הערותיך כאן`;
	};

	const getText = () => {
		return `אנו עובדים קשה לשיפור חווית המשתמש. נודה לך מאוד אם ${
			gender ? "תוכלי" : "תוכל"
		} לדרג אותנו.`;
	};

	// useEffect(() => {
	// 	console.log(rating);
	// }, [rating]);

	return (
		<Modal isOpen={true} toggle={closePopup} className="simpleDialog ratingDialog">
			<div className="user-chat-nav ">
				<IconButton
					onClick={closePopup}
					className="rating-card__close-btn"
					style={{ color: "#fff", zIndex: 1 }}>
					✕
				</IconButton>
			</div>
			<ModalBody>
				<div className="rating-card">
					<Card className="rating-card__container">
						<CardContent className="rating-card_content_container">
							<div className="rating-card__header">
								<img src={starRating} />
							</div>
							<Typography variant="h6" className="rating-card__title">
								{getText()}
							</Typography>
							<div
								className="rating-card__stars"
								style={{ margin: !!openCommentOption && "20px 0" }}>
								<Rating
									dir="rtl"
									style={{ color: "#f65368", direction: "rtl" }}
									value={rating}
									onChange={handleRating}
									disabled={ratingDisabled || openCommentOption}
									className="rating-card__star-icon"
									icon={<Star fontSize="50px" minWidth="30px" />}
								/>
							</div>

							<>
								{openRatingInApp ? (
									<Typography className="rating-card__feedback">
										<div className="rating-card__best">
											{"וואו... 🤩 נשמח ממש לדירוג זהה גם בחנות האפליקציות :"}
										</div>
									</Typography>
								) : (
									<>
										{!openCommentOption ? (
											<Typography className="rating-card__feedback">
												<div className="rating-card__best">{"הכי טוב שנוכל לקבל"}</div>

												<div className="rating-card__emoji">
													<img src={emojiRating} />
													<div className="rating-card__arrow">
														<img src={arrowRating} />
													</div>
												</div>
											</Typography>
										) : (
											<div className="rating-card__comment">
												{rating !== 5 && (
													<>
														{(true || rating === 2 || rating === 2) && (
															<div
																style={{
																	color: "#5a5a5a",
																	fontSize: "17px",
																}}>{`כ"כ נמוך?? 😯 נשמח לפירוט כדי שנוכל ללמוד ולהשתפר`}</div>
														)}
														<TextField
															className="textComponent"
															value={comment}
															placeholder={`${getTextComment()}`}
															minRows={4}
															sx={{ width: "100%" }}
															multiline={true}
															inputProps={{ maxLength: 400 }}
															onChange={(e) => setComment(e.target.value)}></TextField>
													</>
												)}
											</div>
										)}
									</>
								)}
							</>

							{!!loading ? (
								<CircularProgress size={40} />
							) : (
								<>
									{openRatingInApp ? (
										<Button
											variant="contained"
											className="rating-card__submit-btn"
											onClick={handleOpenRateApp}>
											{"יאללה, אשמח לפרגן ◀️"}
										</Button>
									) : (
										<>
											{!openCommentOption ? (
												<Button
													variant="contained"
													className="rating-card__submit-btn"
													onClick={handleSendRating}>
													{gender ? "דרגי" : "דרג"}
												</Button>
											) : (
												<Button
													variant="contained"
													className="rating-card__submit-btn"
													onClick={handleSendCommentRating}>
													{gender ? "שלחי" : "שלח"}
												</Button>
											)}
										</>
									)}
								</>
							)}
						</CardContent>
					</Card>
				</div>
			</ModalBody>
		</Modal>
	);
};
