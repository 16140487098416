import React, { useEffect, useRef } from "react";
import icons from "consts/audioIcons";

export default ({ currentMessage }) => {
	const playerButtonRef = useRef(null);
	const audioRef = useRef(null);
	const timelineRef = useRef(null);
	const soundButtonRef = useRef(null);
	const { playIcon, pauseIcon, soundIcon, muteIcon } = icons;

	useEffect(() => {
		if (!currentMessage.audio) return;
		audioRef.current.onended = audioEnded;
		audioRef.current.ontimeupdate = changeTimelinePosition;
		timelineRef.current.value = 0;
	}, []);

	const toggleAudio = () => {
		if (audioRef.current.paused) {
			audioRef.current.play();
			playerButtonRef.current.innerHTML = pauseIcon;
		} else {
			audioRef.current.pause();
			playerButtonRef.current.innerHTML = playIcon;
		}
	};

	const changeTimelinePosition = () => {
		const percentagePosition = (100 * audioRef.current?.currentTime) / audioRef.current?.duration;
		timelineRef.current &&
			(timelineRef.current.style.backgroundSize = `${percentagePosition}% 100%`);
		timelineRef.current && (timelineRef.current.value = percentagePosition);
	};

	const audioEnded = () => {
		playerButtonRef.current.innerHTML = playIcon;
	};

	const changeSeek = () => {
		const time = (timelineRef.current.value * audioRef.current.duration) / 100;
		audioRef.current.currentTime = time;
	};

	const toggleSound = () => {
		audioRef.current.muted = !audioRef.current.muted;
		soundButtonRef.current.innerHTML = audioRef.current.muted ? muteIcon : soundIcon;
	};

	return (
		<div class="audio-player">
			<audio ref={audioRef} src={currentMessage.audio.url}></audio>
			<div class="controls">
				<button
					class="player-button"
					ref={playerButtonRef}
					onClick={toggleAudio}
					dangerouslySetInnerHTML={{ __html: playIcon }}
				/>

				<input
					ref={timelineRef}
					onChange={changeSeek}
					type="range"
					class="timeline"
					min="0"
					max="100"
				/>

				<button
					class="sound-button"
					ref={soundButtonRef}
					onClick={toggleSound}
					dangerouslySetInnerHTML={{ __html: soundIcon }}
				/>
			</div>
		</div>
	);
};
