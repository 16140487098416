import { useState } from "react";
import i18next from "i18next";
import moment from "moment";

import DatePicker from "components/DatePicker";
import ProfileFields from "consts/profileFields";
import Warnings from "../Warnings";
import PendingStatus from "consts/pendingStatus";

const MAX_DATE = moment().subtract(18, "year").format("YYYY-MM-DD");

export default ({ onChange, profile, pending, setErrors }) => {
	const [errorMessage, setErrorMessage] = useState();

	const _onChange = (key, value) => {
		const isDateValid = moment(value, "YYYY-MM-DD").isSameOrBefore(MAX_DATE);
		const errorMessage = !isDateValid ? "age not valid" : null;
		setErrorMessage(errorMessage);
		setErrors(ProfileFields.Dob, errorMessage);
		onChange(key, moment(value).unix());
	};

	return (
		<div className="wrapInputValidation">
			<DatePicker
				field={ProfileFields.Dob}
				onChange={_onChange}
				initValue={moment.unix(profile[ProfileFields.Dob]).format("YYYY-MM-DD")}
				title={i18next.t("Account Settings.Birthday")}
			/>
			<Warnings
				warning={pending?.status === PendingStatus.Wait && i18next.t("Edit Profile.Pending")}
				error={
					(!!errorMessage && i18next.t(`Validations.${errorMessage}`)) ||
					(pending?.status === PendingStatus.Rejected &&
						i18next.t("Edit Profile.Rejected Birthday"))
				}
				errorClass="birthday"
			/>
		</div>
	);
};
