import firebase from "firebase/app";
import "firebase/database";
import { Observable } from "rxjs";
import httpsCallable from "./httpsCallable";

class BroadcastService {
	BASE_REF = "users_broadcast";

	async getUserBroadcast() {
		const uid = firebase.auth().currentUser?.uid;
		if (!uid) return;

		return (
			(
				await firebase
					.database()
					.ref(this.BASE_REF)
					.child(uid)
					.orderByChild("timeStamp")
					.limitToLast(100)
					.once("value")
			).val() || {}
		);
	}
	async sendBroadcastAnswer(key, userAnsKey) {
		if (!key || !userAnsKey) return;
		return await httpsCallable.call("sendBroadcastAnswer", { key, userAnsKey });
	}
	async sendDynamicBroadcastAnswer(key, userAnsKey, type) {
		if (!key || !userAnsKey || !type) return;
		return await httpsCallable.call("sendDynamicBroadcastAnswer", {
			key,
			userAnsKey,
			typeMsg: type,
		});
	}
	async resetBroadcastUnread() {
		return await httpsCallable.call("resetBroadcastUnread", {});
	}
}

export default new BroadcastService();
