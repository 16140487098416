import { useState, useEffect } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import i18next from "i18next";
import SelectButtonsQuestion from "components/login/Questions/ButtonGroupQuestion";

import nameImage from "assets/images/registerImg/name.png";
import { getUserProfile } from "redux/profile/selectors";
import isMobile from "helpers/isMobile";
import Children from "consts/children";
import oldD4dService from "services/oldD4d.service";

export default ({
	stepName,
	steps,
	goToNamedStep,
	onFinalStepFinish,
	handleRefresh = () => {},
}) => {
	const index = steps.indexOf(stepName);
	const loggedInUserProfile = useSelector(getUserProfile);
	const [isUserExist, setIsUserExist] = useState(false);
	const [loading, setLoading] = useState(false);

	const mainClass =
		process.env.REACT_APP_ENV === "shlish" ? "loginHolder nameHolder" : "loginHolder";

	const handleOnSubmit = async (value) => {
		if (value) {
			setLoading(true);

			const res = await oldD4dService.updateUserFromD4d();
			//if (res.data?.success){
			// onFinalStepFinish() or goToNamedStep(steps[index + 1]);
			// }
			// handleRefresh();
			return window.location.reload();
			setLoading(false);
		}
		goToNamedStep(steps[index + 1]);
	};

	const handleOnBackButtonClick = () => {
		goToNamedStep(steps[index - 1]);
	};

	useEffect(async () => {
		const userExist = loggedInUserProfile?.uid && loggedInUserProfile?.approved !== undefined;
		const res = await oldD4dService.isUserExistInD4d();
		setIsUserExist(!!res.data?.success);

		if (userExist || !res.data?.success) return goToNamedStep(steps[index + 1]);

		if (!isMobile()) return;
		const heightW = document.documentElement.clientHeight;
		const loginHolder = document.querySelector(".loginHolder");
		loginHolder && (loginHolder.style.height = heightW + "px");
	}, []);

	return (
		<div className={mainClass}>
			{isUserExist && (
				<>
					<div className="imageForPage fullWidth left">
						<img src={nameImage} />
					</div>
					<SelectButtonsQuestion
						options={[true, false]}
						title={i18next.t("Login.HasProfileQuestion")}
						onSubmit={handleOnSubmit}
						showBackButton={index > 0}
						onBackButtonClick={handleOnBackButtonClick}
						translationPrefix="HasProfile"
						isLoading={loading}
					/>
				</>
			)}
		</div>
	);
};
