import React, { useState, useEffect } from "react";
import _ from "lodash";
import i18next from "i18next";
import { useDispatch } from "react-redux";

import SelectButtonsQuestion from "components/login/Questions/ButtonGroupQuestion";
import StanceJsmart from "consts/stance";
import StanceD4d from "consts/stanceD4d";
import ProfileFields from "consts/profileFields";
import { updateProfile } from "redux/profile/actions";
import LoginSteps from "consts/loginSteps";
import children from "consts/children";

export default ({ profile, stepName, steps, goToNamedStep, onFinalStepFinish }) => {
	const [Stance, setStance] = useState(
		process.env.REACT_APP_ENV === "shlish" ? StanceD4d : StanceJsmart
	);
	const index = steps.indexOf(stepName);
	const total = steps.length;

	const dispatch = useDispatch();
	const [isClickedNext, setIsClickedNext] = useState(false);

	const handleOnSubmit = (value) => {
		dispatch(updateProfile(ProfileFields.Stance, value));
		setIsClickedNext(true);
	};

	const handleOnBackButtonClick = () => {
		if (
			profile[ProfileFields.Stance] !== Stance.Mesorati &&
			profile[ProfileFields.Stance] !== Stance.Datlash &&
			steps[index - 1] === LoginSteps.KosherFood
		) {
			goToNamedStep(steps[index - 3]);
		} else {
			if (
				profile[ProfileFields.HasChildren] === children.None &&
				steps[index - 1] === LoginSteps.ChildrenCount
			) {
				goToNamedStep(steps[index - 2]);
			} else {
				goToNamedStep(steps[index - 1]);
			}
		}
	};

	useEffect(() => {
		if (isClickedNext && !_.isNil(profile[ProfileFields.Stance])) {
			if (total - 1 <= index) {
				onFinalStepFinish();
			} else {
				if (
					profile[ProfileFields.Stance] === Stance.Mesorati ||
					profile[ProfileFields.Stance] === Stance.Datlash
				) {
					goToNamedStep(steps[index + 1]);
				} else if (index + 2 < total) {
					goToNamedStep(steps[index + 3]);
				} else {
					onFinalStepFinish();
				}
			}
		}
	}, [isClickedNext, profile]);

	const openList = () => {
		const list = document.querySelector(".buttonGroup");
		const listBtn = document.querySelector(".buttonToOpen");
		const listBtnSpan = document.querySelector(".buttonToOpen SPAN");
		const media_query = "screen and (max-width:780px)";
		const matched = window.matchMedia(media_query).matches;
		if (listBtn.classList.contains("ri-arrow-up-s-line")) {
			list.style.height = "18rem";
			if (matched) {
				list.style.height = "18rem";
			}
			list.style.overflowY = "hidden";
			listBtnSpan.innerHTML = i18next.t("Login.Additional Options");
			listBtn.classList.remove("ri-arrow-up-s-line");
		} else {
			list.style.height = "52vh";
			if (matched) {
				list.style.height = "calc(100vh - 355px)";
			}
			list.style.overflowY = "auto";
			listBtnSpan.innerHTML = i18next.t("Close");
			listBtn.classList.add("ri-arrow-up-s-line");
		}
	};

	useEffect(() => {
		openList();
	}, []);

	return (
		<div className="loginHolder collapseBtnGroup">
			<SelectButtonsQuestion
				options={Object.values(Stance)}
				title={i18next.t(`Login.What is your stance ${profile[ProfileFields.Gender] || 0}`)}
				onSubmit={handleOnSubmit}
				showBackButton={index > 0}
				onBackButtonClick={handleOnBackButtonClick}
				translationPrefix={`Stance_${profile[ProfileFields.Gender] || 0}`}
			/>
			<div className="buttonToOpen ri-arrow-down-s-line" onClick={openList}>
				<span>{i18next.t("Login.Additional Options")}</span>
			</div>
		</div>
	);
};
