import { useSelector } from "react-redux";
import { Button, Modal, ModalBody } from "reactstrap";

import usePopups from "hooks/usePopups";
import { getPopups } from "redux/popups/selectors";
import ReceivedGiftCardPage from "pages/ReceivedGiftCardPage";
import { useEffect } from "react";
import unreadNotification from "services/unreadNotification.service";

export default () => {
	const { closePopup } = usePopups();
	const { data } = useSelector(getPopups);

	useEffect(async () => {
		if (!data?.keyGiftCard) return;
		return await unreadNotification.updateShowPopupGiftCard(data?.keyGiftCard);
	}, []);
	return (
		<Modal isOpen={true} toggle={closePopup} className="simpleDialog receivedGiftCardModal">
			<div className="user-chat-nav ">
				<Button
					color="none"
					type="button"
					onClick={closePopup}
					className="nav-btn"
					id="user-profile-hide">
					<i className="ri-close-line"></i>
				</Button>
			</div>
			<ModalBody className="">
				<ReceivedGiftCardPage giftCardData={data} isPopup={true} />
			</ModalBody>
		</Modal>
	);
};
