import "firebase/database";
import httpsCallable from "./httpsCallable";

class RatingUsService {
	BASE_REF = "users_rate_us";

	async updateUserRatingUs(rating, platform) {
		const result = await httpsCallable.call("updateUserRatingUs", { rating, platform });
		return result;
	}
	async updateUserCommentRatingUs(comment) {
		const result = await httpsCallable.call("updateUserCommentRatingUs", { comment });
		return result;
	}
}

export default new RatingUsService();
