import "assets/scss/custom/pages/commonTemplateSpeedDate.scss";
import { Box, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { Button } from "reactstrap";

export default function FooterBtn({
	loading = false,
	handleClick = () => {},
	text = "",
	addClass = "",
}) {
	const [isLoading, setIsLoading] = useState(loading);

	useEffect(() => {
		if (loading !== isLoading) setIsLoading(loading);
	}, [loading]);

	return (
		<Box className={`speedDateFooterBtn${addClass ? ` ${addClass}` : ""}`}>
			{!!isLoading ? (
				<CircularProgress size={40} style={{ color: "#f65368" }} />
			) : (
				<Button color="none" type="button" onClick={handleClick}>
					<span>{text}</span>
				</Button>
			)}
		</Box>
	);
}
