import * as types from "./actionTypes";

export const purchasePromotionWithCardcom = (plan, showSuccessfullyPopup) => {
	return {
		type: types.PROMOTIONS_SALE_PURCHASE_WITH_CARDCOM,
		plan,
		showSuccessfullyPopup,
	};
};

export const purchaseWithCardcomSuccessfully = (finishPurchaseResponse, showSuccessfullyPopup) => {
	showSuccessfullyPopup();
	return {
		type: types.PROMOTIONS_SALE_CARDCOM_PURCHASE_SUCCESSFULLY,
		response: finishPurchaseResponse,
	};
};
