import moment from "moment";
import LocalStorageService from "services/localStorage";
import quotaService from "services/quota.service";

export const checkRelevantQuota = async () => {
	const storedQuota = LocalStorageService.get("quota");
	if (!!storedQuota && storedQuota > moment().valueOf()) return true;

	const res = await quotaService.checkIfQuotaIsOver();
	if (res.data?.success && !!res?.data?.data) {
		storeTheQuotaTime();
		return true;
	}
	return false;
};

export const storeTheQuotaTime = () => {
	const nextDayAtNoon = moment().add(1, "days").hour(12).minute(0).second(0);
	LocalStorageService.set("quota", nextDayAtNoon.valueOf());
};
