import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Progress } from "reactstrap";
import _ from "lodash";
import { useSelector } from "react-redux";
import Wizard from "components/login/Steps/Wizard";
import { getUserProfile } from "redux/profile/selectors";
import { getLoginSteps, getIsAuthChecked, isUserLoggedIn } from "redux/auth/selectors";
import useNavigator from "hooks/useNavigator";
import Loader from "components/Loader";
import { updateCompleteRegistration } from "redux/profile/actions";
import httpsCallable from "services/httpsCallable";
//import LoginSteps from "consts/loginSteps";

export default () => {
	const profile = useSelector(getUserProfile);
	const navigator = useNavigator();
	useEffect(() => {
		if (profile?.uid && !_.isNil(profile?.allowPullData)) {
			setTimeout(() => {
				navigator.navigateToLoginQuestionsPage();
			}, 100);
		}

		return () => {};
	}, [profile?.allowPullData]);

	const pullDataFromD4d = async (allow) => {
		const result = await httpsCallable.call("pullDataFromD4d", {
			allow,
		});
	};
	return (
		<>
			<div>
				<b>
					המשתמש של ך זהה כמשתמש בשליש גן עדן האם תרצה למשוך את פרטי המשתמש שלך מהאתר שליש כן עדן?
					תמיד יהיה ניתן לערוך את הנתונים אחר כך!
				</b>
				<div onClick={() => pullDataFromD4d(true)}>yes</div>
				<div onClick={() => pullDataFromD4d(false)}>no</div>
			</div>
		</>
	);
};
