import firebase from "firebase/app";
import "firebase/database";
import localStorage from "./localStorage";

class QueueService {
	BASE_REF = "queue/tasks";

	constructor() {}
	async getIp() {
		try {
			const response = await fetch("https://api.ipify.org?format=json");
			const { ip } = await response.json();
			return ip ?? "";
		} catch (error) {
			console.error("Error fetching the IP address:", error);
			return "";
		}
	}

	async sendEvent(uid, eventName, extraData = {}) {
		const loginFromManager = localStorage.get("loginFrommanager");
		const boId = localStorage.get("boId");
		try {
			if (loginFromManager) {
				extraData.loginFromManager = true;
			}
			if (loginFromManager) {
				extraData.boId = boId;
			}
			const event = {
				uid: uid,
				eventName: eventName,
				timeStamp: firebase.database.ServerValue.TIMESTAMP,
				extraData: extraData,
				ip: await this.getIp(),
			};
			console.log(`event: ${JSON.stringify(event)}`);
			return firebase.database().ref(this.BASE_REF).push(event);
		} catch (error) {
			console.error(error);
		}
	}
}

export default new QueueService();
