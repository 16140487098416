import firebase from "firebase/app";
import "firebase/database";

import * as types from "./actionTypes";
import * as showPopupTypes from "../popups/actionTypes";
import { getLoggedInUserUID } from "../auth/selectors";
import ChatMessagesService from "services/chatMessages";
import QueueService from "services/queue";
import BaseChatEvents from "consts/baseChatEvents";
import moment from "moment";
import { getUserProfile } from "redux/profile/selectors";
import { getAgeByDob } from "helpers/date";
import { getPublicProfiles, getUserPublicProfile } from "redux/publicProfiles/selectors";
import subscription from "consts/subscription";
import { getUserSubscriptionType } from "redux/subscription/selectors";
import usePopups from "hooks/usePopups";
import popupsTypes from "consts/popupsTypes";
import userChatSentService from "services/chatSent";
import ProfileService from "services/profile";

export const fetchChatMessagesSuccessfully = (chatID, messages) => {
	return {
		type: types.CHAT_MESSAGES_FETCH_SUCCESSFULLY,
		chatID,
		messages,
	};
};

export const fetchChatMessagesFailed = (chatID, error) => {
	return { type: types.CHAT_MESSAGES_FETCH_FAILED, chatID, error };
};

export const fetchChatMessages = (collegueUid, chatID) => {
	return { type: types.CHAT_MESSAGES_CLICK_NEW_USER, collegueUid, chatID };
};

export const sendChatMessage = (
	chatId = ChatMessagesService.generateNewChatId(),
	messageText,
	colleagueUid,
	isNewChat,
	system = false,
	isTemplate = false,
	classImg = null,
	audio = false
) => {
	return async (dispatch, getState) => {
		const state = getState();
		const uid = getLoggedInUserUID(state);
		const { dob: userDob, gender: userGender } = getUserProfile(state);

		let shouldOpenCantSEndMsgPopup = false;
		if (userGender === 0) {
			const subscriptionType = getUserSubscriptionType(state);
			const isUserSubscribed = [subscription.PAID, subscription.FREE].includes(subscriptionType);

			if (!isUserSubscribed) {
				//const publicProfiles = getPublicProfiles(state);
				const userProfile = await ProfileService.fetchUserProfile(colleagueUid);
				const { dob: colleagueDob } = userProfile;

				if (colleagueDob) {
					const AGE_DIFFERENCE_LIMIT = -8; //8 years bigger
					const MAX_CHAT_LIMIT = 20;

					const userDobMoment = moment.unix(userDob);
					const colleagueDobMoment = moment.unix(colleagueDob);
					const ageDifference = userDobMoment.diff(colleagueDobMoment, "years");

					if (ageDifference < AGE_DIFFERENCE_LIMIT) {
						const userChats = await userChatSentService.fetchChatSent();
						const chatCount = Object.keys(userChats || {}).length;

						if (chatCount >= MAX_CHAT_LIMIT) {
							shouldOpenCantSEndMsgPopup = true;
						}
					}
				}
			}
		}

		if (shouldOpenCantSEndMsgPopup) {
			return dispatch({
				type: showPopupTypes.SHOW_POPUP,
				payload: {
					popupType: popupsTypes.NonSubscriberCantSendMessagesPopup,
					colleagueProfile: getPublicProfiles(state)[colleagueUid],
				},
			});
		}

		sendMessage(
			dispatch,
			chatId,
			uid,
			messageText,
			colleagueUid,
			isNewChat,
			system,
			audio,
			isTemplate,
			classImg
		);
	};
};

export const readChatMessage = (userUid, colleagueUid, chatID) => {
	QueueService.sendEvent(userUid, BaseChatEvents.ChatMessageRead, {
		uidOfUserToChatWith: colleagueUid,
		chatID,
	});
	return { type: types.CHAT_MESSAGES_READ, user: colleagueUid, chatID };
};

const sendMessage = async (
	dispatch,
	chatId,
	uid,
	messageText,
	currentCollageUid,
	isNewChat,
	system = false,
	audio = false,
	isTemplate = false,
	classImg = null
) => {
	const message = {
		image: "",
		read: false,
		text: messageText,
		timeStamp: firebase.database.ServerValue.TIMESTAMP,
		uid: uid,
		collageUid: currentCollageUid ?? null,
		system: system,
		audio: audio,
		expired: audio ? moment().add(7, "days").valueOf() : null,
		isTemplate,
		classImg,
	};

	dispatch({ type: types.CHAT_MESSAGES_SEND_MESSAGE, payload: messageText });

	try {
		await ChatMessagesService.sendMessage(chatId, message);
		QueueService.sendEvent(
			uid,
			isNewChat ? BaseChatEvents.NewChatMessage : BaseChatEvents.ChatMessage,
			{
				uidOfUserToChatWith: currentCollageUid,
				chatID: chatId,
				timeStamp: message.timeStamp,
				text: message.text,
				image: message.image,
				messageObj: message,
			}
		);
		if (isNewChat) {
			setTimeout(() => {
				dispatch({ type: types.CHAT_MESSAGES_START_NEW_CHAT, chatID: chatId });
			}, 1000);
		}
		dispatch(sendChatMessageSuccessfully());
	} catch (error) {
		dispatch(sendChatMessageFailed(error));
	}
};

const sendChatMessageSuccessfully = () => {
	return { type: types.CHAT_MESSAGES_SEND_MESSAGE_SUCCESSFULLY };
};

const sendChatMessageFailed = (error) => {
	return { type: types.CHAT_MESSAGES_SEND_MESSAGE_FAILED, error };
};

export const updateChatMessageSuccessfully = (chatID, message) => {
	return {
		type: types.CHAT_MESSAGES_UPDATED,
		chatID,
		message,
	};
};

export const updateChatMessageFailed = (chatID, error) => {
	return {
		type: types.CHAT_MESSAGES_UPDATED_ERROR,
		chatID,
		error,
	};
};

export const leaveChatMessagesScreen = () => {
	return { type: types.LEAVE_CHAT_MESSAGES_SCREEN };
};
