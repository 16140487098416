import { Box } from "@mui/material";
import moment from "moment";
import blogService from "services/blogService";
import DeleteIcon from "@mui/icons-material/Delete";
import { getUserProfile } from "redux/profile/selectors";
import { useSelector } from "react-redux";
import { getFirstCharImg } from "helpers/getFirstCharImg";
import usePopups from "hooks/usePopups";
import localStorage from "services/localStorage";

export default function BlogCommentBox({ comment = {}, articleData = {}, comProfile = {} }) {
	const userProfile = useSelector(getUserProfile) || {};
	const { showDeleteCommentPopup } = usePopups();
	const loginFromManager = localStorage.get("loginFrommanager");

	const mainPictureUrl =
		comProfile.pictures?.[comProfile?.mainPictureName]?.url ||
		getFirstCharImg(comment?.name ? comment : comProfile);

	const deleteComment = async () => {
		showDeleteCommentPopup(articleData.key, comment.key, userProfile?.gender, comProfile?.uid);
	};

	return (
		<Box className="commentBox">
			<Box className="wrapCommentHeader">
				<Box className="userImg">
					<img width={"50px"} height={"50px"} src={mainPictureUrl} />
				</Box>

				<Box className="innerCommentHeader">
					<Box className="textDate">
						<span style={{ fontWeight: 600 }}>{comment?.name || comProfile?.name}</span>
						{` | ${moment(comment.created).format("DD.MM.YYYY")}`}
					</Box>
				</Box>

				<Box className="wrapDeleteBtn">
					{(userProfile?.uid === comment?.uid || !!loginFromManager) && (
						<DeleteIcon onClick={deleteComment} sx={{ width: "14px", color: "#2d5086" }} />
					)}
				</Box>
			</Box>

			<Box className="wrapTxtComment">
				<div dangerouslySetInnerHTML={{ __html: comment?.text ?? "" }} />
			</Box>
		</Box>
	);
}
