import "assets/scss/blog/blogCategory.scss";
import BaseHelmet from "components/seo/BaseHelmet";
import blogService from "services/blogService";
import useNavigator from "hooks/useNavigator";
import { Box } from "@mui/system";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import Skeleton from "@mui/material/Skeleton";
import moment from "moment";
import { useDispatch } from "react-redux";
// import LandingFooter from "components/landing/LandingFooter";
import theBlog from "assets/images/blog/theBlogGreen.png";
import publicProfile from "services/publicProfile";
import articlesStatus from "consts/articlesStatus";
import BlogBox from "components/blog/BlogBox";

export default function BlogCategoryPage({ isBlogPage = false }) {
	const dispatch = useDispatch();
	const { navigateToPath } = useNavigator();
	const { urlName } = useParams();

	const [currntCategoryBlog, setCurrntCategoryBlog] = useState({});
	const [blogsCategory, setBlogsCategory] = useState([]);
	const [usersIds, setUsersIds] = useState([]);
	const [articles, setArticles] = useState([]);
	const [publicUser, setPublicUser] = useState({});
	const [slugsName, setSlugsName] = useState({});

	const goToArticle = (slug) => {
		if (!slug) return;
		navigateToPath(`/blog/${slug}`);
	};
	const handleUsersIds = (d) => {
		if (!d?.users) return;

		const uidsToDisplay = Object.entries(d?.users)
			.filter(([key, value]) => value === true)
			.map(([key, value]) => key);

		setUsersIds(uidsToDisplay);
	};

	useEffect(async () => {
		if (!urlName && !isBlogPage) return navigateToPath("/blog");

		await blogService.getBlogsCategory().then((data) => {
			if (!data) return navigateToPath("/blog");
			const blogsCategoryList = Object.values(data || {});

			if (!!urlName) {
				const myBlogCategory = blogsCategoryList.find((blog) => blog?.slug === urlName);
				if (!myBlogCategory) return navigateToPath("/blog");
				setCurrntCategoryBlog(myBlogCategory);
			}

			const blogSlugNames = {};
			blogsCategoryList.forEach((element) => {
				if (element?.slug && element?.name) {
					blogSlugNames[element?.slug] = element?.name;
				}
			});
			setSlugsName(blogSlugNames);
			setBlogsCategory(blogsCategoryList);
		});
	}, [urlName]);

	useEffect(async () => {
		if (!currntCategoryBlog?.key) return;

		await blogService.getArticleByCategoryKey(currntCategoryBlog?.key).then((data) => {
			if (!data) return;
			const listArticles = Object.values(data || {})
				.filter((art) => art?.status === articlesStatus.published)
				.sort((a, b) => b?.publishDate - a?.publishDate);
			setArticles(listArticles);
		});
	}, [currntCategoryBlog]);

	useEffect(async () => {
		if (!!isBlogPage) {
			await blogService.getBlogs().then((data) => {
				if (!data) return;
				const listArticles = Object.values(data || {})
					.filter((art) => art?.status === articlesStatus.published)
					.sort((a, b) => b?.publishDate - a?.publishDate);
				setArticles(listArticles);
			});
		}
	}, []);

	useEffect(async () => {
		if (!!articles && !!!!articles?.length && !!articles[0]?.linkedAuthor) {
			publicProfile.fetchProfile(articles[0]?.linkedAuthor).then((pUser) => {
				if (!pUser || !pUser?.uid) return;
				setPublicUser(pUser);
			});
		}
	}, [articles]);

	return (
		<>
			{!!currntCategoryBlog && (
				<>
					<BaseHelmet
						title={
							currntCategoryBlog?.name
								? `${process.env.REACT_APP_ENV_NAME} - בלוג ${currntCategoryBlog?.name}`
								: `${process.env.REACT_APP_ENV_NAME}  - בלוג`
						}
						description={
							currntCategoryBlog?.name ? currntCategoryBlog?.name : "קטגוריה"
						}></BaseHelmet>
					<Box
						className="wrapBlogCategoryPage"
						style={{ paddingTop: !isMobile ? "100px" : "calc(80px + (env(safe-area-inset-top)))" }}>
						<Box className="blogCategoryPage">
							<Box className="blogCategoryWrapper">
								<Box className="categoriesWrapper">
									{!!blogsCategory && !!blogsCategory?.length ? (
										<>
											<h2
												onClick={() => {
													navigateToPath(`/blog`);
												}}
												style={{ margin: "0", fontWeight: "700", cursor: "pointer" }}>
												{"המגזין"}
											</h2>
											<Box className="wrapCategoriesNames">
												{blogsCategory.map((item) => (
													<span
														key={item.key}
														style={{ fontWeight: urlName === item?.slug && "700" }}
														onClick={() => {
															if (urlName !== item?.slug)
																navigateToPath(`/blog/category/${item?.slug}`);
														}}>
														{item?.name}
													</span>
												))}
											</Box>
										</>
									) : (
										<Skeleton
											animation="pulse"
											className="skeleton mb-3"
											width={"30%"}
											height={"24px"}
										/>
									)}
								</Box>
								<Box className="wrapContentBlogs">
									<Box
										className="blogHeader noSelection"
										onClick={() => {
											if (!!articles && !!articles?.length) goToArticle(articles[0]?.slug);
										}}>
										{!!articles && !!articles?.length ? (
											<>
												<Box className="wrapBlogHeaderImg">
													{!!(articles[0]?.panoramicImage || articles[0]?.mainImage) && (
														<img
															src={articles[0]?.panoramicImage ?? articles[0]?.mainImage}
															alt="blog-header"
														/>
													)}
													{!!articles[0]?.categorySlug &&
														!!slugsName[articles[0]?.categorySlug] && (
															<span className="categorySlugHeader">
																{slugsName[articles[0]?.categorySlug]}
															</span>
														)}
												</Box>
												<Box className="innerHeader">
													{!!articles[0]?.title && (
														<h1 dangerouslySetInnerHTML={{ __html: articles[0]?.title }} />
													)}
													{!!articles[0]?.description && (
														<h2 dangerouslySetInnerHTML={{ __html: articles[0]?.description }} />
													)}
													<div
														className="innerContentHeader"
														dangerouslySetInnerHTML={{ __html: articles[0]?.content }}></div>
													<Box className="authorAndDate">
														<span
															className={`name ${
																!!publicUser?.uid && !!publicUser?.profileImage?.url
																	? "withImg"
																	: ""
															}`}>
															<>
																{!!publicUser?.uid && !!publicUser?.profileImage?.url && (
																	<Box className="wrapPublicUserImg">
																		<img src={publicUser?.profileImage?.url} />
																	</Box>
																)}
															</>
															{articles[0]?.author}
														</span>

														<Box className="wrapViewNDate">
															<span>{moment(articles[0]?.publishDate).format("DD.MM.YYYY")}</span>
															{/* <span>{`${articles[0]?.views ? articles[0]?.views : 0} צפיות`}</span> */}
														</Box>
													</Box>
												</Box>
											</>
										) : (
											<>
												<Skeleton
													animation="pulse"
													className="skeleton mb-4"
													width={"100%"}
													height={"350px"}
												/>
												<Skeleton
													animation="pulse"
													className="skeleton mb-4"
													width={"40%"}
													height={"70px"}
												/>
												<Skeleton
													animation="pulse"
													className="skeleton "
													width={"100%"}
													height={"100px"}
												/>
											</>
										)}
									</Box>
									<Box className="wrapContent">
										{!!articles && !!articles?.length && (
											<>
												<BlogBox
													blogsFromOut={articles.slice(1, articles.length)}
													countBlogs={articles.length - 1}
													withoutHeader={true}
												/>
											</>
										)}
									</Box>
								</Box>
							</Box>
						</Box>
						{/* <LandingFooter /> */}
					</Box>
				</>
			)}
		</>
	);
}
