import { Box } from "@mui/material";
import "assets/scss/giftCard/giftCard.scss";
import HeaderLandingGiftCard from "components/giftCard/HeaderLandingGiftCard";
import ContentLandingGiftCard from "components/giftCard/ContentLandingGiftCard";
import { Button } from "reactstrap";
import useNavigator from "hooks/useNavigator";
import { useEffect } from "react";
import localStorage from "services/localStorage";

export default function LandingGiftCardPage() {
	const urlParams = new URLSearchParams(window.location.search);
	const affId = urlParams.get("f");

	const navigate = useNavigator();
	const handleClick = () => {
		navigate.navigateToPath("/purchaseGiftCard");
	};
	const openInNewTab = (e) => {
		e.preventDefault();
		window.open("/terms", "_blank", "toolbar=0,location=0,menubar=0,width=600,height=600");
	};

	useEffect(() => {
		if (affId) return localStorage.set("giftAff", affId, 1 / 24); //1 hour
	}, []);

	return (
		<Box className="LandingGiftCardPage">
			<HeaderLandingGiftCard />
			<ContentLandingGiftCard />
			<Box className="purchGiftCardWrapper">
				<div className="termsGift" style={{ fontSize: "17px" }}>
					{`השובר בתוקף למשך 24 חודשים מיום הרכישה. בכפוף ל`}
					<a className="underLine" onClick={openInNewTab}>
						{"תקנון"}
					</a>
				</div>

				<Button
					outline
					size="sm"
					color="primary"
					onClick={handleClick}
					className="btn-rounded goToPurchBtn">
					{"לרכישת שובר מתנה"}
				</Button>
				<Box>
					<span>{"מחיר מיוחד: "}</span>
					<b>{"199₪"}</b>
				</Box>
			</Box>
		</Box>
	);
}
