import { Button, ModalBody, Modal, ModalHeader, ModalFooter } from "reactstrap";
import usePopups from "hooks/usePopups";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getPopups } from "redux/popups/selectors";
import dynamicPopupsService from "services/dynamicPopups.service";

export default () => {
	const { closePopup } = usePopups();
	const { data } = useSelector(getPopups);

	useEffect(() => {
		if (data?.key) dynamicPopupsService.setUserShowPopup(data.key).then((res) => console.log(res));
	}, []);
	return (
		<Modal
			isOpen={true}
			toggle={closePopup}
			className={`simpleDialog dynamicModal${data?.classDialog ? ` ${data.classDialog}` : ""}`}>
			{data?.withExit && (
				<div className="user-chat-nav ">
					<Button
						color="none"
						type="button"
						onClick={closePopup}
						className="nav-btn"
						id="user-profile-hide">
						<i className="ri-close-line"></i>
					</Button>
				</div>
			)}
			<ModalHeader>
				<div
					dangerouslySetInnerHTML={{ __html: data?.headerText }}
					style={{ whiteSpace: "pre-wrap" }}
				/>
			</ModalHeader>

			<ModalBody>
				<div
					dangerouslySetInnerHTML={{ __html: data?.bodyText }}
					style={{ whiteSpace: "pre-wrap", lineHeight: "1.3" }}
				/>
			</ModalBody>
			<ModalFooter>
				<Button
					outline
					size="sm"
					color="primary"
					onClick={closePopup}
					className="btn-rounded littleBtn">
					{data?.footerBtnText || "הבנתי"}
				</Button>
			</ModalFooter>
		</Modal>
	);
};
