import Button from "components/Button";
import useNavigator from "hooks/useNavigator";
import { useEffect, useState } from "react";

export default function NotFound() {
	const { navigateToHomePage } = useNavigator();
	const [show, setShow] = useState();

	useEffect(() => {
		setTimeout(() => {
			setShow(true);
		}, 1000);
	}, []);
	return (
		<>
			{show && (
				<div
					style={{
						width: "100vw",
						height: "100vh",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						flexDirection: "column",
					}}>
					<h1>404 - Not Found</h1>
					<p>{`העמוד שחיפשת לא קיים`}</p>
					<Button text={"חזור לדף הבית"} onClick={() => navigateToHomePage()} />
				</div>
			)}
		</>
	);
}
