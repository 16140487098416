import React, { useEffect, useState } from "react";
import moment from "moment";

export default function EventCountdown({ eventDate }) {
	const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

	const timeRemainingOption = {
		seconds: "שניות",
		minutes: "דקות",
		hours: "שעות",
		days: "ימים",
	};

	useEffect(() => {
		const interval = setInterval(() => {
			setTimeRemaining(calculateTimeRemaining());
		}, 1000);

		return () => clearInterval(interval);
	}, []);

	function calculateTimeRemaining() {
		const now = moment();
		const eventTime = moment(eventDate);
		const duration = moment.duration(eventTime.diff(now));

		const days = Math.floor(duration.asDays());
		const hours = duration.hours();
		const minutes = duration.minutes();
		const seconds = duration.seconds();

		return { days, hours, minutes, seconds };
	}

	return (
		<ul className="eventCountDown">
			{Object.entries(timeRemainingOption).map(([key, val]) => {
				if (!timeRemaining[key] && timeRemaining[key] != 0) return <></>;
				return (
					<li key={key}>
						<span className="num">{timeRemaining[key]}</span>
						<span>{val}</span>
					</li>
				);
			})}
		</ul>
	);
}
