import firebase from "firebase/app";
import "firebase/functions";
import AnonymousCallTypes from "../consts/anonymousCallTypes";
import httpsCallable from "./httpsCallable";
const functions = firebase.functions();

class AnonymousCallService {
	call = async (type, data) => {
		const _data = { type, data };
		const result = await functions.httpsCallable("anonymousCall")(_data);
		return result.data;
	};

	unsubscribeEmail = async (type, data) => {
		const _data = { type, data };
		const result = await httpsCallable("unsubscribeEmailsByUidEncrypt")(_data);
		return result.data;
	};

	clickEvent = async (affId) => {
		return await this.call(AnonymousCallTypes.click_event, {
			affId,
		});
	};
	getAffName = async (affId) => {
		return await this.call(AnonymousCallTypes.getAffiliateName, {
			affId,
		});
	};
	createGiftCardSale = async (data) => {
		return await this.call(AnonymousCallTypes.createGiftCardSale, data);
	};

	getGiftCardPlan = async () => {
		return await this.call(AnonymousCallTypes.getGiftCardPlan, {});
	};
	getGiftCardBlessing = async (keyGiftCard) => {
		return await this.call(AnonymousCallTypes.getGiftCardBlessing, { keyGiftCard });
	};
}

export default new AnonymousCallService();
