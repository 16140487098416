import "firebase/database";
import httpsCallable from "./httpsCallable";

class QuotaService {
	async checkIfQuotaIsOver() {
		const result = await httpsCallable.call("checkIfQuotaIsOver", {});
		return result;
	}
}

export default new QuotaService();
