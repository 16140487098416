import "assets/scss/custom/components/email.scss";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Button } from "reactstrap";
import { emailSkip } from "redux/auth/actions";
import { Box } from "@mui/material";
import _ from "lodash";
import i18next from "i18next";
import ProfileFields from "consts/profileFields";
import Email from "components/account/AccountSettings/Email";
import TextInput from "components/TextInput";
import profileFields from "consts/profileFields";
import ButtonBack from "../Button";
import DoneIcon from "@mui/icons-material/Done";
import qMark from "assets/images/registerImg/qMark.png";
import LocalStorageService from "services/localStorage";

export default ({ profile, stepName, steps, goToNamedStep, onFinalStepFinish }) => {
	const dispatch = useDispatch();
	const index = steps.indexOf(stepName);
	const total = steps.length;
	const [isClickedNext, setIsClickedNext] = useState(false);

	const mainClass =
		process.env.REACT_APP_ENV === "shlish" ? "loginHolder nameHolder" : "loginHolder";

	const handleOnSubmit = () => {
		setMpd();
		setIsClickedNext(true);
	};

	const setMpd = () => {
		const now = new Date().getTime();
		LocalStorageService.set("mpd", now);
	};

	const handleOnBackButtonClick = () => {
		goToNamedStep(steps[index - 1]);
	};

	const handleOnSkipButtonClick = () => {
		setMpd();
		dispatch(emailSkip());

		if (total - 1 <= index) {
			onFinalStepFinish();
		} else {
			goToNamedStep(steps[index + 1]);
		}
	};

	useEffect(() => {
		if (isClickedNext && !_.isNil(profile[ProfileFields.Email])) {
			if (total - 1 <= index) {
				onFinalStepFinish();
			} else {
				goToNamedStep(steps[index + 1]);
			}
		}
	}, [isClickedNext, profile]);

	useEffect(() => {
		const storedLastDateMailPopupOpen = LocalStorageService.get("mpd");
		if (_.isNil(profile[ProfileFields.Email]) && storedLastDateMailPopupOpen) {
			LocalStorageService.remove("mpd");
		}
		return () => {};
	}, []);

	return (
		<div className={mainClass}>
			<Button onClick={handleOnSkipButtonClick} className="skipBtn" outline color="primary">
				{i18next.t("Skip")}
			</Button>

			{/* <div style={{ height: "30%" }}>
				<img src={qMark} style={{ height: "100%" }} />
			</div> */}

			{/* {!profile?.email && (
				<Box className="wrapTitle">
					<h2 className="emailDesc" style={{ color: "#8b8b8d", marginTop: "30px" }}>
						{i18next.t("email.subtitle")}1
					</h2>
				</Box>
			)} */}
			{index > 0 && (
				<ButtonBack onClick={handleOnBackButtonClick} className="backBtn ri-arrow-right-s-line" />
			)}
			<Box className="mb-3 wrapEmailPopup" style={{ width: "100%" }}>
				<Header profile={profile} />
				{/* {!profile?.email ? <Header profile={profile} /> : <HeaderWithEmail profile={profile} />} */}
				{!!profile && (
					<Box className="wrapMailVerified" style={{ margin: "10px 0", position: "relative" }}>
						<Email profile={profile} handleOnSubmit={handleOnSubmit} isEmailStep={true} />
					</Box>
				)}
				{/* {!profile?.email && (
					<span
						style={{
							color: "#8b8b8d",
							fontSize: "1.2rem",
						}}>
						{i18next.t("email.acceptTerms")}
					</span>
				)} */}
			</Box>
		</div>
	);
};

const Header = ({ profile }) => {
	return (
		<Box className="wrapTitle">
			<h1>{i18next.t("email.whatsYourEmail")}?</h1>
			<h2>{i18next.t("email.whatsYourEmailDesc")}</h2>
		</Box>
	);
	return (
		<Box className="wrapTitle">
			{/* <h1>{i18next.t("email.Hi", { name: profile?.name })},</h1> */}
			<h2>{i18next.t("email.subtitle")}</h2>
		</Box>
	);
};

const HeaderWithEmail = ({ profile }) => {
	return (
		<Box className="wrapMailVerified">
			<Box className="editMail">
				<Box>{i18next.t("email.editMail")}</Box>
			</Box>
			{!!profile && (
				<div className="wrapInputValidation" style={{ position: "relative" }}>
					<TextInput
						field={profileFields.Email}
						initValue={profile?.email || ""}
						title={i18next.t("email.current")}
						disabled={true}
					/>
					{!!profile?.email && (
						<span
							style={{ position: "absolute", left: "0.5rem", top: "26px", width: "min-content" }}>
							{<DoneIcon sx={{ color: "green" }} />}
						</span>
					)}
				</div>
			)}
		</Box>
	);
};
