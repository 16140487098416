import isMobile from "helpers/isMobile";
import React, { useState } from "react";

export default ({ children }) => {
	const [isMobileDevice] = useState(isMobile());
	return (
		<div
			style={{ width: isMobileDevice ? "48%" : "100%" }}
			data-aos="fade-up"
			data-aos-offset="-400"
			data-aos-duration="300"
			data-aos-easing="ease-in-out"
			data-aos-mirror="false"
			data-aos-once="true">
			{children}
		</div>
	);
};
