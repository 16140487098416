import profile from "./profile";

import "firebase/database";
import { Geolocation } from "@capacitor/geolocation";

class LocationService {
	setLocation = async (uid) => {
		try {
			const returnData = await Geolocation.requestPermissions();
			if (returnData?.location === "granted") {
				const coordinates = await Geolocation.getCurrentPosition();
				console.log("Current position:", coordinates);
				const location = {
					lat: coordinates?.coords?.latitude,
					lng: coordinates?.coords?.longitude,
					created: Date.now(),
				};
				await profile.updateProfile(uid, { location });
				return location;
			} else {
			}
			//	return await Contacts.getPermissions();
		} catch (error) {
			console.error(error);
		}
	};
}

export default new LocationService();
