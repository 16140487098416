import React from "react";
import ButtonGroup from "../ButtonGroup";
import Question from "./Question";
import { CircularProgress } from "@mui/material";

export default ({
	options,
	title,
	subTitle,
	onSubmit,
	onBackButtonClick,
	showBackButton,
	translationPrefix,
	isLoading = false,
}) => {
	return (
		<Question
			title={title}
			subTitle={subTitle}
			showSubmitButton={false}
			onBackButtonClick={onBackButtonClick}
			showBackButton={showBackButton}>
			{!!isLoading ? (
				<div style={{ display: "flex", justifyContent: "center" }}>
					<CircularProgress size={40} className="text-secondary" />
				</div>
			) : (
				<ButtonGroup onClick={onSubmit} options={options} translatePrefix={translationPrefix} />
			)}
		</Question>
	);
};
