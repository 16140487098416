import { Modal, ModalBody, ModalHeader, UncontrolledCarousel, Button } from "reactstrap";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import i18next from "i18next";
import { isNil } from "lodash";

import usePopups from "hooks/usePopups";
import { PopoverBody, Popover } from "reactstrap";
import SelectPlanBody from "./SelectPlanBody";
import { purchaseWithCardcom, purchaseWithGoogle } from "redux/subscription/actions";
import { getCardComUrl, getFetchCardcomApiStatus, getSale } from "redux/subscription/selectors";
import IframeContainer from "./IframeContainer";
import { getUserProfile } from "redux/profile/selectors";
import { getGoogleApiStatus } from "redux/subscription/selectors";
import ApiRequestStatus from "consts/apiRequestStatus";
import Loader from "components/Loader";
import { getPopups, isSale } from "redux/popups/selectors";
import tokenService from "services/token.service";

export default () => {
	const [desc, setDesc] = useState(null);
	const [hasToken, setHasToken] = useState("");
	const [tokenData, setTokenData] = useState("");
	const [selectedPlan, setSelectedPlan] = useState();
	const ref = useRef(false);
	const [showIframe, setShowIframe] = useState(false);
	const [selectedPlanKey, setSelectedPlanKey] = useState();
	const fetchCardcomApiStatus = useSelector(getFetchCardcomApiStatus);
	const cardComUrl = useSelector(getCardComUrl);
	const googleApiStatus = useSelector(getGoogleApiStatus);
	const loggedInUserProfile = useSelector(getUserProfile);
	const sale = useSelector(getSale);
	const _isSale = useSelector(isSale);
	const { cardcomClearing, tokenWithoutReturn } = useSelector(getPopups);

	const dispatch = useDispatch();

	const { _closePopupUnderPopup, showSubscriptionSuccessfullyPopup, showBuyWithTokenPopup } =
		usePopups();

	const onSelectPlan = (plan, initSelected) => {
		setSelectedPlan(plan);
		!initSelected && plan?.key === selectedPlan?.key && onContinuePurchase();
	};

	const onContinuePurchase = () => {
		if (hasToken)
			return showBuyWithTokenPopup({ tokenData, selectedPlan, withoutReturn: tokenWithoutReturn });

		if (selectedPlan?.google_only) {
			dispatch(purchaseWithGoogle(selectedPlan));
		} else {
			dispatch(purchaseWithCardcom(selectedPlan, showSubscriptionSuccessfullyPopup));
			setShowIframe(true);
		}
	};

	useEffect(() => {
		if (!!cardcomClearing?.selectedPlan) {
			dispatch(
				purchaseWithCardcom(cardcomClearing.selectedPlan, showSubscriptionSuccessfullyPopup)
			);
			setShowIframe(true);
		}
	}, [cardcomClearing]);

	useEffect(async () => {
		const res = await tokenService.getUserTokenData();
		console.log(res.data);
		setHasToken(!!res.data?.success);

		if (res.data?.success) {
			setTokenData(res.data?.data || {});
		}
	}, []);

	useEffect(() => {
		if (!ref.current) {
			ref.current = true;
		} else {
			if (googleApiStatus === ApiRequestStatus.SUCCESSFULLY) {
				_closePopupUnderPopup();
			}
		}
	}, [googleApiStatus]);

	const subscriptionAds = [
		{ header: "showContacts" },
		{ header: "showLikedMe" },
		{ header: "freeBoost" },
		{ header: "suggestForOther" },
	];

	const items = subscriptionAds.map((ad) => ({
		header: i18next.t(`Subscription.Ads.${ad.header}_${loggedInUserProfile.gender}`),
	}));

	const items1 = [
		{
			header: (
				<div className="contentCarousel">
					<h5 style={{ textAlign: "center", width: "100%" }}>
						<span>עשרות אלפי אנשים כבר הכירו בשליש גן עדן</span>
						<br />
						{`אל ${loggedInUserProfile.gender ? "תחכי תני" : "תחכה תן"} לזה פשוט לקרות`}
						<br />
						<span>{`${loggedInUserProfile.gender ? "רכשי" : "רכוש"} עכשיו מנוי במבצע!`}</span>
					</h5>
					<h3>מה נותן לי מנוי?</h3>
					<h4 style={{ marginTop: "1rem" }}>קריאת כל ההודעות שקבלת</h4>
					<h4 style={{ textAlign: "start" }}>{`כל המשתמשים יוכלו לקרוא את ההודעות ${
						loggedInUserProfile.gender ? "שאת שולחת" : "שאתה שולח"
					}`}</h4>
				</div>
			),
		},
	];

	const getSaleItems = (subtitle1, subTitle2) => {
		return [
			{
				header: (
					<div>
						<h5>{subtitle1}</h5>
						<h4 style={{ marginTop: "1rem" }}>{subTitle2}</h4>
					</div>
				),
			},
		];
	};

	const [isOpen, setIsOpen] = useState(false);
	const toggle = () => setIsOpen(!isOpen);

	useEffect(() => {
		const _desc = {};
		if (_isSale && sale && sale.switch) {
			_desc.title = sale.content.title;
			_desc.subItems = getSaleItems(sale.content.subTitle1, sale.content.subTitle2);
		} else {
			_desc.title = i18next.t("Subscription.Header");
			_desc.subItems = items1;
		}
		setDesc(_desc);
	}, [sale, _isSale]);

	return (
		desc && (
			<Modal isOpen={true} toggle={_closePopupUnderPopup} className="subscriptionDialog">
				{!showIframe ? (
					<>
						{googleApiStatus === ApiRequestStatus.PENDING && <Loader />}
						<Button
							color="none"
							type="button"
							onClick={_closePopupUnderPopup}
							className="nav-btn closeButton">
							<i className="ri-close-line"></i>
						</Button>
						<div className="wrapTopPopupSub">
							<ModalHeader>{desc.title}</ModalHeader>
							<div className="loopSentences">
								<UncontrolledCarousel
									items={desc.subItems}
									indicators={false}
									controls={false}
									autoPlay={false}
								/>
							</div>
							<ModalBody>
								<SelectPlanBody
									onSelect={onSelectPlan}
									selectedPlan={selectedPlan}
									selectedPlanKey={selectedPlanKey}
									setSelectedPlanKey={setSelectedPlanKey}
								/>
							</ModalBody>
						</div>
						<div className="bottomPart">
							<Button
								onClick={onContinuePurchase}
								disabled={isNil(selectedPlanKey || googleApiStatus !== ApiRequestStatus.PENDING)}>
								<span>
									{i18next.t(`Subscription.Continue Purchase_${loggedInUserProfile.gender}`)}
								</span>
							</Button>
							<h5>
								ניתן לרכוש מנוי ליום בעלות של 50 ש"ח בשיחה עם נציג, המנוי מאפשר פתיחת הודעות
								רטרואקטיבית וקריאת כל ההודעות במהלך תקופת המנוי
							</h5>
							{/* <h5 className="payTrigSubs" id="payTrig">
								אמצעי תשלום נוספים
							</h5>
							<div className="morePay mobileMorePay">
								<Popover
									className="payConditionsPopover"
									isOpen={isOpen}
									placement="top"
									target="payTrig"
									trigger="legacy"
									toggle={toggle}>
									<div className="user-chat-nav ">
										<Button
											color="none"
											type="button"
											onClick={toggle}
											className="nav-btn"
											id="user-profile-hide">
											<i className="ri-close-line"></i>
										</Button>
									</div>
									<PopoverBody>
										<div className="morePayContent">
											<h6>
												<b>תשלום באמצעות חיוב חשבון הסלולר </b>- אין לכם כרטיס אשראי? חדש! רכישת
												מנוי באמצעות חיוב הטלפון הנייד! התשלום מבוצע בחיוב הנייד ממנו בוצעה השיחה
												בתשלום חד פעמי ולא מתחדש. למעבר לרכישה לחצו:<a href="#"> יום אחד ב 50₪ </a>
												<br />
												<b>הפקדה / העברה בנקאית </b> - לחשבון הבנק של "סמארט להכיר חכם" לבנק
												הפועלים, סניף 636 מס' חשבון 44444. יש לשלוח את אישור ההפקדה/העברה בנקאית עם
												פרטי המייל למייל: info@jsamrt.app. תשלום בטלפון באמצעות כרטיס אשראי - חייגו
												08-6223925
											</h6>
										</div>
									</PopoverBody>
								</Popover>
							</div> */}
						</div>
					</>
				) : (
					<ModalBody>
						<IframeContainer
							apiStatus={fetchCardcomApiStatus}
							url={cardComUrl}
							onBack={() => setShowIframe(false)}
						/>
					</ModalBody>
				)}
			</Modal>
		)
	);
};
