import React, { useState, useEffect } from "react";
import _ from "lodash";
import i18next from "i18next";
import { useDispatch } from "react-redux";

import ProfileFields from "consts/profileFields";
import { updateProfile } from "redux/profile/actions";
import DropdownQuestion from "../Questions/DropdownQuestion";
import height from "assets/images/registerImg/height.png";
import Stance from "consts/stance";
import LoginSteps from "consts/loginSteps";

export default ({ profile, stepName, steps, goToNamedStep, onFinalStepFinish }) => {
	const index = steps.indexOf(stepName);
	const total = steps.length;
	const dispatch = useDispatch();
	const [isClickedNext, setIsClickedNext] = useState(false);
	const [range] = useState(_.range(130, 210));
	const mainClass =
		process.env.REACT_APP_ENV === "shlish" ? "loginHolder nameHolder" : "loginHolder";

	const handleOnSubmit = (value) => {
		dispatch(updateProfile(ProfileFields.Height, value));
		setIsClickedNext(true);
	};

	const handleOnBackButtonClick = () => {
		if (
			profile[ProfileFields.Stance] !== Stance.Mesorati &&
			steps[index - 1] === LoginSteps.KosherFood
		) {
			goToNamedStep(steps[index - 3]);
		} else {
			goToNamedStep(steps[index - 1]);
		}
	};

	useEffect(() => {
		if (isClickedNext && !_.isNil(profile[ProfileFields.Height])) {
			if (total - 1 <= index) {
				onFinalStepFinish();
			} else {
				goToNamedStep(steps[index + 1]);
			}
		}
	}, [isClickedNext, profile]);
	return (
		<div className={mainClass}>
			<div className="imageForPage">
				<img src={height} />
			</div>
			<DropdownQuestion
				options={range}
				title={i18next.t("Login.What is your height")}
				onSubmit={handleOnSubmit}
				showBackButton={index > 0}
				onBackButtonClick={handleOnBackButtonClick}
				defaultTitle={i18next.t(`Select_${profile[ProfileFields.Gender] || 0}`)}
				initValue={170}
				className="height"
				leaveScrollMode={true}
			/>
		</div>
	);
};
