import { Box } from "@mui/material";
import FooterBtn from "../FooterBtn";
import { useState } from "react";
import i18next from "i18next";

export default function EmptySpeedDate({}) {
	const [loading, setLoading] = useState(false);

	const registerToNextVideo = async () => {
		// await speedDatingService.registerToNextVideo();

		setLoading(false);
	};

	const handleClick = () => {
		setLoading(true);
		registerToNextVideo();
	};
	return (
		<>
			<h3 className="title">{i18next.t("speedDate.howExciting")}</h3>
			<Box className="content">
				<Box className="nearestEvent">
					{/* <span>
						<span>{i18next.t("speedDate.reservePlaceForEvent")}</span>
						<span className="calligraffitti-regular">{i18next.t("speedDate.videoDatingEn")}</span>
						<span>{` ${i18next.t("speedDate.closePressHere")}`}</span>
					</span> */}
					{"האירוע הבא יהיה בקרוב, פרטים בהמשך.."}
				</Box>
			</Box>
			{/* <Box className="footer">
				<FooterBtn
					handleClick={handleClick}
					text={i18next.t("speedDate.registerForEvent")}
					loading={loading}
				/>
			</Box> */}
		</>
	);
}
