import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";

import CardsView from "components/CardsView";
import { getSearchBlockUsers } from "redux/search/selectors";
import {
	getIsLoadingMore,
	getHasMoreResults,
	getSearchFreeTextFilter,
	getStatus,
} from "redux/search/selectors";
import { searchLoadMore } from "redux/search/actions";
import API_REQUEST_STATUS from "consts/apiRequestStatus";
import Loader from "components/Loader";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import usePopups from "hooks/usePopups";

export default ({ searchLocation }) => {
	const users = useSelector(getSearchBlockUsers);
	const isLoadingMore = useSelector(getIsLoadingMore);
	const hasMoreResults = useSelector(getHasMoreResults);
	const status = useSelector(getStatus);
	const freeText = useSelector(getSearchFreeTextFilter);
	const dispatch = useDispatch();
	const [loader, setLoader] = useState(true);

	const history = useHistory();
	const currentPath = history.location.pathname;
	const { showLoginPopup } = usePopups();

	const fetchData = () => {
		const MAX_USERS_IN_HOME_PAGE = 60;
		if (currentPath === "/" && users?.length >= MAX_USERS_IN_HOME_PAGE) return showLoginPopup();

		if (!isLoadingMore && hasMoreResults) {
			dispatch(searchLoadMore(freeText, searchLocation));
		}
	};

	useEffect(() => {
		if (status === API_REQUEST_STATUS.SUCCESSFULLY) {
			setLoader(false);
		} else {
			setLoader(true);
		}
	}, [status]);

	const memoUsers = useMemo(() => users, [users]);

	return (
		<>
			<InfiniteScroll dataLength={memoUsers ? memoUsers.length : 0} next={fetchData} hasMore={true}>
				{status === API_REQUEST_STATUS.SUCCESSFULLY && <CardsView users={memoUsers} />}
			</InfiniteScroll>
			{!!loader && <Loader className="loaderBeforeResults" />}
		</>
	);
};
