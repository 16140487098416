import produce from "immer";

import * as types from "./actionTypes";
import * as authTypes from "redux/auth/actionTypes";
import ApiRequestStatus from "consts/apiRequestStatus";

const initialState = {
	selectedPlan: null,
	fetchCardcomApiStatus: ApiRequestStatus.INIT,
	cardComUrl: "",
	purchaseStatus: ApiRequestStatus.INIT,
};

export default produce((state, action) => {
	switch (action.type) {
		case types.PROMOTIONS_SALE_PURCHASE_WITH_CARDCOM:
			state.fetchCardcomApiStatus = ApiRequestStatus.PENDING;
			state.selectedPlan = action.plan;
			break;
		case types.PROMOTIONS_SALE_GET_CARDCOM_RESPONSE_SUCCESSFULLY:
			state.fetchCardcomApiStatus = ApiRequestStatus.SUCCESSFULLY;
			state.cardComUrl = action.response.url;
			break;
		case types.PROMOTIONS_SALE_GET_CARDCOM_RESPONSE_FAILED:
			state.fetchCardcomApiStatus = ApiRequestStatus.FAILED;
			break;
		case types.PROMOTIONS_SALE_CARDCOM_PURCHASE_SUCCESSFULLY:
			state.purchaseStatus = ApiRequestStatus.SUCCESSFULLY;
			break;
		case types.PROMOTIONS_SALE_CARDCOM_PURCHASE_FAILED:
			state.purchaseStatus = ApiRequestStatus.FAILED;
			break;
		case types.PROMOTIONS_SALE_POPUP_INIT_SUCCESSFULLY:
			state.fetchCardcomApiStatus = ApiRequestStatus.INIT;
			break;
		case authTypes.AUTH_LOG_OUT:
			return initialState;
	}
}, initialState);
