import i18next from "i18next";
import { Modal, ModalHeader, Button, ModalBody } from "reactstrap";
import usePopups from "hooks/usePopups";
import { Box } from "@mui/material";
import { getPopups } from "redux/popups/selectors";
import { useSelector } from "react-redux";
import { getIsPlatformIOS } from "redux/init/selectors";
import { AndroidSettings, IOSSettings, NativeSettings } from "capacitor-native-settings";

export default () => {
	const { closePopup } = usePopups();
	const { data } = useSelector(getPopups);
	const isPlatformIOS = useSelector(getIsPlatformIOS);

	const handleShareLocation = async () => {
		if (!isPlatformIOS) {
			await NativeSettings.openAndroid({ option: AndroidSettings.ApplicationDetails });
		} else {
			await NativeSettings.openIOS({
				option: IOSSettings.App,
			});
		}
	};

	return (
		<Modal isOpen={true} toggle={closePopup} className="simpleDialog" style={{ maxWidth: "450px" }}>
			<div className="user-chat-nav ">
				<Button
					color="none"
					type="button"
					onClick={closePopup}
					className="nav-btn"
					id="user-profile-hide">
					<i className="ri-close-line"></i>
				</Button>
			</div>
			<ModalHeader>
				{data?.title ? data.title : `בכדי לצפות ב"קרוב אלי" צריך לשתף מיקום`}
			</ModalHeader>
			<ModalBody>
				<Box
					style={{
						margin: "0 0 20px",
						display: "flex",
						justifyContent: "space-around",
						alignItems: "center",
					}}>
					<Button
						style={{
							borderRadius: "20px",
						}}
						onClick={handleShareLocation}>{`שתף מיקום`}</Button>
				</Box>
			</ModalBody>
		</Modal>
	);
};
