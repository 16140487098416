import baseRegisterEvents from "consts/baseRegisterEvents";
import useNavigator from "hooks/useNavigator";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { loginWithToken } from "redux/auth/actions";
import localStorage from "services/localStorage";
import QueueService from "../../services/queue";

export default ({}) => {
	let { id } = useParams();
	const didMount = useRef(false);
	const urlParams = new URLSearchParams(window.location.search);
	const uid = urlParams.get("uid");
	const boId = urlParams.get("boId");
	const dispatch = useDispatch();
	const loginWithTokenStatus = useSelector((state) => state.auth.loginWithTokenStatus);
	const login = () => {
		dispatch(loginWithToken(id));
	};
	const navigator = useNavigator();

	useEffect(() => {
		if (id) {
			login();
		}
	}, [id]);

	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		const isManager = params.get("isManager");
		if (isManager) {
			localStorage.set("loginFrommanager", "1", 30);
			if (boId) localStorage.set("boId", boId, 30);
		} else {
			localStorage.remove("loginFrommanager");
			localStorage.remove("boId");
		}
		if (loginWithTokenStatus) {
			if (didMount.current) return;
			didMount.current = true;
			QueueService.sendEvent(uid, baseRegisterEvents.LoginAsManager, {
				boId: boId ?? null,
			});
			navigator.redirectToHomePage();
		}
	}, [loginWithTokenStatus]);

	return <></>;
};
