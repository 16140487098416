import i18next from "i18next";
import { Input } from "reactstrap";
import Notes from "components/Notes";

export default ({ title, description = false, value, onChange }) => {
	return (
		<div className="switchDesc">
			<div className="wrapSwitch toggleSwitch">
				<Input
					checked={value}
					onChange={onChange}
					placeholder={i18next.t("Type")}
					type="checkbox"
				/>
				<h6>{title}</h6>
			</div>
			{description && <Notes className>{description}</Notes>}
		</div>
	);
};
