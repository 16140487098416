import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import i18next from "i18next";
import useNavigator from "hooks/useNavigator";
import TermsLink from "components/login/TermsLink";
import PhoneQuestion from "components/login/Questions/PhoneQuestion";
import { loginWithPhone } from "redux/auth/actions";
import {
	getLoginWithPhoneStatus,
	isUserLoggedIn,
	getIsAuthChecked,
	getLoginWithPhoneErrorMessage,
} from "redux/auth/selectors";
import { getPhoneWithPrefix, isIsraeliPhoneNumber } from "helpers/mobileNumber";
import LOGIN_WITH_PHONE_STATUS from "consts/loginWithPhoneStatus";
import phone1Image from "assets/images/registerImg/hello_man.png";
import Loader from "components/Loader";
import Button from "components/Button";
import { getUseAlternativeLogin } from "redux/system/selectors";

export default ({ isFromPopup = false }) => {
	const dispatch = useDispatch();
	const loginStatus = useSelector(getLoginWithPhoneStatus);
	const isLoggedIn = useSelector(isUserLoggedIn);
	const isAuthChecked = useSelector(getIsAuthChecked);
	const navigator = useNavigator();
	const [hasError, setHasError] = useState();
	const [errMsg, setErrMsg] = useState();
	const [phone, setPhone] = useState();
	const [showAlternativeLogin, setShowAlternativeLogin] = useState(false);
	const loginWithPhoneErrorMessage = useSelector(getLoginWithPhoneErrorMessage);
	const useAlternativeLogin = useSelector(getUseAlternativeLogin);
	const [secondsRemaining, setSecondsRemaining] = useState(10);

	const handleSubmit = (value) => {
		// const phoneWithPrefix = getPhoneWithPrefix(value);
		setPhone(value);
		if (!useAlternativeLogin || !isIsraeliPhoneNumber(value) || !!isFromPopup) {
			dispatch(loginWithPhone(value));
		} else {
			navigateAlternativeLogin();
		}
	};

	useEffect(() => {
		if (isLoggedIn && isAuthChecked) {
			navigator.navigateToLoginQuestionsPage();
		}
	}, [isLoggedIn, isAuthChecked]);

	useEffect(() => {
		switch (loginStatus) {
			case LOGIN_WITH_PHONE_STATUS.WAIT_FOR_VERIFICATION:
				if (!isFromPopup) navigator.navigateToLoginPhoneVerificationPage();
				break;
			case LOGIN_WITH_PHONE_STATUS.LOGIN_FAILED:
				setHasError(true);
				setErrMsg(
					i18next.t(
						`Phone Error.${
							loginWithPhoneErrorMessage.indexOf("blocked") !== -1 ? "Many Requests" : ""
						}`
					)
				);
				break;
			default:
				break;
		}
	}, [loginStatus]);

	const [hasFocus, setFocus] = useState(false);
	const handleChange = (value) => {
		setPhone(value);
	};
	const navigateAlternativeLogin = () => {
		if (!phone) return alert("יש להזין מספר פלאפון");
		navigator.navigateToAlternativeLogin({ phone });
	};

	useEffect(() => {
		if (hasError) {
			const timerId = setInterval(() => {
				console.log(secondsRemaining);
				if (secondsRemaining > 1) {
					setSecondsRemaining((prevSecondsRemaining) =>
						prevSecondsRemaining > 0 ? prevSecondsRemaining - 1 : 1
					);
					//setSecondsRemaining(secondsRemaining - 1);
				} else {
					setShowAlternativeLogin(true);
					//setSecondsRemaining(0); // Set to 0 explicitly to trigger rendering change
					clearInterval(timerId);
				}
			}, 1000);

			return () => clearInterval(timerId);
		} // Cleanup function to clear timer on unmount
	}, [hasError, secondsRemaining]);

	return isAuthChecked ? (
		<div className="loginPagesWrapper">
			<div className="loginPopup">
				<div className="loginHolder phoneEnter">
					<div className="imageForPage">
						<img src={phone1Image} />
					</div>
					<PhoneQuestion
						title={i18next.t("Login.Enter your phone")}
						subTitle={i18next.t("Login.Enter phone text")}
						onSubmit={handleSubmit}
						onChange={handleChange}
						hasError={hasError}
						errMsg={errMsg}
						showSubmitButton={!showAlternativeLogin}
						showLoader={loginStatus === LOGIN_WITH_PHONE_STATUS.LOGIN_PENDING}
						onFocus={() => setFocus(true)}
						onBlur={() => setFocus(false)}
					/>
					{!!hasError && !showAlternativeLogin && (
						<div
							style={{
								width: "100%",
								marginTop: "10px",
								textAlign: "left",
								paddingLeft: "5px",
								fontSize: "15px",
								color: "red",
							}}>
							אירעה שגיאה… יש ללחוץ שוב בעוד {secondsRemaining} שניות
						</div>
					)}
					{!!showAlternativeLogin && (
						<div className="innerLoginWrapper">
							<Button
								className="question-button redButton"
								style={{ backgroundColor: "#f65368 !important" }}
								text={i18next.t("Continue")}
								onClick={navigateAlternativeLogin}
							/>
						</div>
					)}

					{/* {showAlternativeLogin && (
						<div
							style={{
								width: "100%",
								marginTop: "10px",
								textAlign: "left",
								paddingLeft: "5px",
								fontSize: "15px",
								color: "red",
							}}>
							נתקלת בבעיה בשליחת קוד האימות?{" "}
							<span style={{ textDecoration: "underLine" }} onClick={navigateAlternativeLogin}>
								לחצ/י כאן לניסיון נוסף
							</span>
						</div>
					)} */}
					<TermsLink />
				</div>
			</div>
		</div>
	) : (
		<Loader />
	);
};
