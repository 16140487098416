import httpsCallable from "./httpsCallable";

class TelegramService {
	async sendConfirmTelegram(source, action = false) {
		const result = await httpsCallable.call("sendConfirmTelegram", { source, action });
		return result;
	}
}

export default new TelegramService();
