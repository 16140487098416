import { createSelector } from "reselect";
import { orderBy, isNil, concat } from "lodash";

import { getPublicProfiles } from "../publicProfiles/selectors";
import { getBlocksUIDs } from "../blocks/selectors";
import { getChatMeta } from "../chatMeta/selectors";
import { getManualyPromotedUsers } from "../promotions/selectors";
import ApiRequestStatus from "consts/apiRequestStatus";
import { getChatSentUser } from "redux/chatSent/selectors";
import { getChatReceivedFilteredUser } from "redux/chatReceivedFiltered/selectors";

export const getChatReceivedData = (state) => state.chatReceived;

export const getChatReceived = (state) => state.chatReceived.users;
export const getResetReceivedUsersOnce = (state) => state.chatReceived.resetUsersOnce;

export const getChatReceivedSortByDate = createSelector(
	getChatReceived,
	getPublicProfiles,
	getBlocksUIDs,
	(chatReceived, publicProfiles, blockedUsers) => {
		if (!publicProfiles || chatReceived.status === ApiRequestStatus.INIT) {
			return [];
		}
		const chatReceiveArray = Object.entries(chatReceived).map(([key, value]) => ({
			...value,
			uid: key,
		}));
		const chatFilteredDeleted = chatReceiveArray.filter((chat) => {
			const res =
				!chat.deleted &&
				chat.chatID &&
				publicProfiles[chat.uid]?.approved &&
				isNil(blockedUsers[chat.uid]);

			return res;
		});
		return orderBy(chatFilteredDeleted, "timeStamp", "desc");
	}
);

export const getChatReceivedUsers = createSelector(
	getChatReceivedSortByDate,
	getChatMeta,
	(chatReceivedSortByDate, chatMeta) => {
		const res = chatReceivedSortByDate.map((chat) => ({
			...chat,
			unread: chatMeta[chat.chatID]?.newMessages || 0,
		}));
		return res;
	}
);

export const getMatchesChatUsers = createSelector(getChatReceivedUsers, (chatUsers) => {
	return chatUsers.filter((chatUser) => chatUser);
});

export const getMatchesUnreadMessages = createSelector(getMatchesChatUsers, (matchesChatUsers) => {
	const res = matchesChatUsers
		.map((matchesChatUser) => matchesChatUser.unread || 0)
		.reduce((a, b) => a + b, 0);
	return res;
});

export const getAllChatUsers = createSelector(
	getChatReceivedUsers,
	getManualyPromotedUsers,
	(chatUsers, manualyPromotedUsers) => {
		if (!manualyPromotedUsers || !manualyPromotedUsers.length) {
			return chatUsers;
		}
		const res = orderBy(
			concat(
				chatUsers,
				manualyPromotedUsers.map((user) => ({ ...user, promotion: true }))
			),
			"timeStamp",
			"desc"
		);
		return res;
	}
);

export const getChatReceivedUser = (userUid) =>
	createSelector(getChatReceived, (chatReceived) => chatReceived[userUid]);

export const getChat = (userUid) =>
	createSelector(
		getChatReceivedUser(userUid),
		getChatSentUser(userUid),
		getChatReceivedFilteredUser(userUid),
		(chatReceivedUser, chatSentUser, chatReceivedFilteredUser) =>
			chatReceivedUser?.chatID || chatSentUser?.chatID || chatReceivedFilteredUser?.chatID
	);

export const getIsUserHasChatButFrozen = (userUid) =>
	createSelector(
		getChat(userUid),
		getPublicProfiles,
		(chatId, publicProfiles) =>
			chatId && publicProfiles[userUid] && !publicProfiles[userUid]?.approved
	);

export const getIsNewChat = (userUid) =>
	createSelector(
		getChatReceivedUser(userUid),
		getChatSentUser(userUid),
		getChatReceivedFilteredUser(userUid),
		(chatReceivedUser, chatSent, chatReceivedFilteredUser) =>
			isNil(chatReceivedUser?.chatID) &&
			isNil(chatSent?.chatID) &&
			isNil(chatReceivedFilteredUser?.chatID)
	);

export const getIsChatReceivedLoaded = createSelector(
	getChatReceived,
	(chatReceived) => chatReceived.status !== ApiRequestStatus.INIT
);

export const getHasMoreChats = createSelector(
	getChatReceivedData,
	(chatReceivedData) => !!chatReceivedData?.hasMoreChats
);

export const getLoadChatsNumber = createSelector(
	getChatReceivedData,
	(chatReceivedData) => chatReceivedData?.loadChatsNumber
);
