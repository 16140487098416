import { Fill, Face } from "consts/cloudinaryOptions";

export const TransformImg = (
	url = "",
	width = 285,
	height = 285,
	fill = Fill.Thumb,
	face = Face.Face,
	extra = "",
	blur = false
) => {
	if (url.includes("cloudinary.com") === false) {
		return url;
	}
	url = url.replace("http:/", "https:/");

	const index = url.indexOf("upload/") + 7;

	let transformation = `f_auto,fl_progressive,${width ? `w_${width},` : ""}${
		height ? `h_${height},` : ""
	}q_auto,${fill}`;

	if (face !== "none") {
		transformation += "," + face + "/";
	} else {
		transformation += "/";
	}

	if (extra !== "") {
		transformation += extra + "/";
	}
	if (blur) {
		transformation += "e_blur:7000" + "/";
	}
	url = url.slice(0, index) + transformation + url.slice(index);
	return url;
};
