import React from "react";
import i18next from "i18next";

export default () => {
	const openInNewTab = (e) => {
		e.preventDefault();
		window.open("/terms", "_blank", "toolbar=0,location=0,menubar=0,width=600,height=600");
	};

	const tel = process.env.REACT_APP_ENV === "shlish" ? "08-6519660" : "08-6223925";
	return (
		<>
			<label className="termsPrivacy">
				<label className="labelPrivacy">
					{i18next.t("Login.Need help")}
					<a href="/contact">{i18next.t("Login.Press to connect")}</a>
					{i18next.t("Login.Connect with us by the phone")}

					<a href={`tel:${tel}`}>{tel}</a>
				</label>
				<br />
				{i18next.t("Login.Agree for terms")}{" "}
				<a onClick={openInNewTab}>{i18next.t("Login.Terms agreement link")}</a>{" "}
				{i18next.t("Login.Advertisement agreement")}
			</label>
		</>
	);
};
