import { useState, useEffect } from "react";
import { Button, Input } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import i18next from "i18next";

import { getUserProfile } from "redux/profile/selectors";
import sendBl from "assets/images/icons/chatW.svg";
import { sendChatMessage } from "redux/chatMessages/actions";
import { getChat, getIsNewChat } from "redux/chatReceived/selectors";
import SendTemplateMessageButton from "./card/SendTemplateMessageButton";
import { getUserSubscriptionType } from "redux/subscription/selectors";
import Subscription from "consts/subscription";
import usePopups from "hooks/usePopups";
import isMobile from "helpers/isMobile";
import templateMsgService from "services/templateMsg.service";
import _ from "lodash";
import { checkRelevantQuota } from "helpers/quota";
import { getUserQuotaStatus } from "redux/general/selectors";
import chatRequestsService from "services/chatRequestsService";

const MAX_LENGTH = 140;

export default ({ user, onAfterSendMessage = () => {}, fromCardUserModal }) => {
	const dispatch = useDispatch();
	const { gender } = useSelector(getUserProfile);
	const [message, setMessage] = useState("");
	const [templates, setTemplates] = useState([]);
	const chatID = useSelector(getChat(user.uid));
	const promotionChatId = user.promotions_received_chatId;
	const isNewChat = useSelector(getIsNewChat(user.uid));
	const subscriptionType = useSelector(getUserSubscriptionType);
	const {
		showNonSubscriberToSubscriberPopup,
		showNonSubscriberToNonSubscriberPopup,
		showMessageSuccessfullySentPopup,
		showQuotaPopup,
		showFittedMessagePopup,
	} = usePopups();
	const userQuotaStatus = useSelector(getUserQuotaStatus);

	const isLoggedInUserSubscribed =
		subscriptionType === Subscription.PAID || subscriptionType === Subscription.FREE;

	const isCollageSubscribed = user.isSubscribed;

	const sendMessage = async () => {
		if (userQuotaStatus && (await checkRelevantQuota())) return showQuotaPopup();
		if (!isNewChat) {
			const res = await chatRequestsService.getCanSendMsg(chatID);
			if (!res?.data || !res?.data?.success || !res?.data?.canSend) {
				if (res?.data?.openPopup)
					return showFittedMessagePopup(
						"היי, לא ניתן לשלוח הודעה נוספת לפני שהצד השני מאשר את השיחה.",
						false
					);
				return;
			}
		}
		dispatch(sendChatMessage(chatID || promotionChatId, message, user.uid, isNewChat));
		onAfterSendMessage();
		!isLoggedInUserSubscribed && isCollageSubscribed && showNonSubscriberToSubscriberPopup(user);
		!isLoggedInUserSubscribed &&
			!isCollageSubscribed &&
			showNonSubscriberToNonSubscriberPopup(user);
		isLoggedInUserSubscribed && showMessageSuccessfullySentPopup();
	};

	useEffect(() => {
		templateMsgService
			.fetchTemplateMsg()
			.then((data) => {
				if (!!data && !_.isEmpty(data)) {
					const newTemplates = Object.values(data).filter((item) => {
						if (!item.active) return false;
						return true;
					});
					setTemplates(newTemplates);
				}
			})
			.catch((e) => console.log(e));
	}, []);

	return (
		<>
			<div className="wrapTextDecoration">
				<span>
					{i18next.t(`Send Hello.Write to_${user?.gender}`, {
						name: user?.name,
					})}
				</span>
				<span style={{ color: "rgb(80,80,80)", fontSize: "12px", fontWeight: "400" }}>
					{`איזה כיף! הודעה ראשונה ל${user?.name} 🥰 חשוב לדעת: ניתן לשלוח הודעה ראשונה אחת בלבד, ולאחריה, על ${user?.name} לאשר את המשך השיחה ביניכם, בשביל שתוכלו להמשיך ולשוחח בחופשיות. כל מילה שלך חשובה! בהצלחה :)`}
				</span>
				<div className="inputAndQuote grow-wrap" data-replicated-value={message}>
					<Input
						value={message}
						onChange={(e) => setMessage(e.target.value)}
						placeholder={i18next.t(`Send Hello.Write a message_${gender}`)}
						type="textarea"
						rows={4}
						maxLength={MAX_LENGTH}
					/>
					<SendTemplateMessageButton
						user={user}
						usePopover={!isMobile() && fromCardUserModal ? true : false}
						className="popoverUser"
						templates={templates}
					/>
				</div>
			</div>
			<Button disabled={message.trim().length < 2} onClick={sendMessage}>
				{i18next.t(`Search Card.Say Hi_${gender}`)} <img src={sendBl} />
			</Button>
		</>
	);
};
