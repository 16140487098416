import "assets/scss/custom/pages/accountVideoBox.scss";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getUserProfile } from "redux/profile/selectors";
import speedDatingService from "services/speedDatingService";
import speedDateStatus from "consts/speedDateStatus";
import date from "assets/images/date.png";
import AccountVideoByStatus from "./innerAccountVideo/AccountVideoByStatus";
import ExplainDrawer from "./innerAccountVideo/ExplainDrawer";
import useNavigator from "hooks/useNavigator";
import { FormControl, MenuItem, Select } from "@mui/material";
import moment from "moment";
import { getAgeByDob } from "helpers/date";

export default function AccountVideoBox({ sdUrl = "" }) {
	const location = useLocation();
	const currentUser = useSelector(getUserProfile) || {};
	const [speedData, setSpeedData] = useState({});
	const [allUserEvents, setAllUserEvents] = useState([]);
	const [noSpeedData, setNoSpeedDate] = useState(false);
	const [userData, setUserData] = useState({});
	const [loadingUser, setLoadingUser] = useState(true);
	const handleNoSpeedData = () => setNoSpeedDate(true);
	const { navigateToPath } = useNavigator();
	const handleSpeedDb = (sData) => {
		if (!sData) return handleNoSpeedData();
		setSpeedData(sData);
	};

	useEffect(() => {
		if (!currentUser?.uid) return;
		let sData = {};

		if (!location.state?.urlName && !sdUrl) {
			speedDatingService.getNextSpeedDate().then((data) => {
				sData = Object.values(data || {}).find((ev) => {
					const canRegister = speedDatingService.checkIfUserCanRegisterToEventByFilters(
						ev,
						currentUser
					);
					return ev?.status !== speedDateStatus.canceled && canRegister;
				});
				handleSpeedDb(sData);
			});
		} else {
			speedDatingService.getSpeedDateInfoByUrlName(sdUrl || location.state.urlName).then((data) => {
				sData = Object.values(data || {})[0];
				handleSpeedDb(sData);
			});
		}
	}, [currentUser?.uid]);

	useEffect(() => {
		if (location.state?.urlName) {
			navigateToPath("", { state: null });
		}
	}, []);
	useEffect(() => {
		if (!currentUser?.uid) return;

		speedDatingService.getUserEvents(currentUser?.uid).then((data) => {
			const promises = [];
			Object.keys(data || {}).map((key) => {
				promises.push(speedDatingService.getSpeedDateInfo(key));
			});
			Promise.all(promises).then((res) => {
				const filterRes = res.filter((speedy) => !!speedy);
				setAllUserEvents(filterRes);
			});
		});
	}, [currentUser?.uid]);

	useEffect(() => {
		if (!speedData?.key || !currentUser?.uid) return;
		setLoadingUser(true);

		const subscription = speedDatingService
			.listenToUserInSpeedDate$(speedData.key, currentUser.uid)
			.subscribe((userData) => {
				setUserData(userData);
				setLoadingUser(false);
			});
		return () => {
			subscription?.unsubscribe();
		};
	}, [speedData?.key, currentUser?.uid]);

	return (
		<>
			{!!allUserEvents?.length && (
				<FormControl className="formEventsSelect">
					<Select
						labelId="eventsSelect"
						id="allUserEvents"
						value={speedData?.key}
						onChange={(event) => {
							setSpeedData(allUserEvents.find((item) => item?.key === event.target.value));
						}}>
						{allUserEvents.map((speedy) => (
							<MenuItem key={speedy} value={speedy?.key}>
								{moment(speedy?.eventDate).format("האירוע שהיה ב D/M/YY")}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			)}
			<Box className={"accountVideoBox"}>
				{noSpeedData && <AccountVideoByStatus noSpeedData={noSpeedData} />}

				{speedData?.key && !loadingUser && (
					<AccountVideoByStatus userData={userData} speedData={speedData} />
				)}

				<Box className="imgDate">
					<img src={date} alt="date" />
				</Box>
			</Box>
			<ExplainDrawer speedData={speedData} />
		</>
	);
}
