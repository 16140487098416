import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import _ from "lodash";
import i18next from "i18next";
import { Button } from "reactstrap";

import UploadImageQuestion from "../Questions/UploadImageQuestion";
import ProfileFields from "consts/profileFields";
import useProfileImages from "hooks/useProfileImages";
import Stance from "consts/stance";
import LoginSteps from "consts/loginSteps";
import { imageSkip } from "redux/auth/actions";

export default ({ profile, stepName, steps, goToNamedStep, onFinalStepFinish }) => {
	const dispatch = useDispatch();
	const index = steps.indexOf(stepName);
	const total = steps.length;
	const [isClickedNext, setIsClickedNext] = useState(false);
	const { saveNewImage } = useProfileImages();

	const handleOnSubmit = (value) => {
		// saveNewImage(ProfileFields.Pictures, value);
		setIsClickedNext(true);
	};

	const handleOnBackButtonClick = () => {
		if (
			profile[ProfileFields.Stance] !== Stance.Mesorati &&
			steps[index - 1] === LoginSteps.KosherFood
		) {
			goToNamedStep(steps[index - 3]);
		} else {
			goToNamedStep(steps[index - 1]);
		}
	};

	const handleOnSkipButtonClick = () => {
		dispatch(imageSkip());
		if (total - 1 <= index) {
			onFinalStepFinish();
		} else {
			goToNamedStep(steps[index + 1]);
		}
	};

	useEffect(() => {
		if (isClickedNext && !_.isNil(profile[ProfileFields.Pictures])) {
			if (total - 1 <= index) {
				onFinalStepFinish();
			} else {
				goToNamedStep(steps[index + 1]);
			}
		}
	}, [isClickedNext, profile]);

	return (
		<div className="loginHolder no-image uploadImage">
			<Button onClick={handleOnSkipButtonClick} className="skipBtn" outline color="primary">
				{i18next.t("Skip")}
			</Button>
			<UploadImageQuestion
				title={i18next.t("Login.Add picture")}
				subTitle={i18next.t("Login.Add image explain")}
				onSubmit={handleOnSubmit}
				showBackButton={index > 0}
				onBackButtonClick={handleOnBackButtonClick}
				isClickedNext={isClickedNext}
			/>
		</div>
	);
};
