import { useEffect, useState } from "react";

export default function CountdownTimer({ initCount, handleFinishCount, fullView = false }) {
	const [remainingTime, setRemainingTime] = useState(initCount); // 10 minutes in seconds
	const [isRunning, setIsRunning] = useState(true);

	useEffect(() => {
		let intervalId;

		if (isRunning) {
			intervalId = setInterval(() => {
				setRemainingTime((prevRemainingTime) => {
					if (prevRemainingTime <= 0) {
						clearInterval(intervalId);
						setIsRunning(false);
						handleFinishCount();
						return 0;
					}
					return prevRemainingTime - 1;
				});
			}, 1000);
		}

		return () => {
			clearInterval(intervalId);
		};
	}, [isRunning]);
	useEffect(() => {
		setRemainingTime(initCount);
	}, [initCount]);

	function formatTime(time) {
		const minutes = Math.floor(time / 60);
		const seconds = time % 60;
		const hours = Math.floor(time / 3600);

		if (!!fullView || time >= 3600)
			return `${hours < 10 ? "0" : ""}${hours}:${minutes % 60 < 10 ? "0" : ""}${minutes % 60}:${
				seconds < 10 ? "0" : ""
			}${seconds}`;

		return `${minutes < 10 ? "0" : ""}${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
	}

	return <div className={remainingTime <= 60 && "lastMinuet"}>{formatTime(remainingTime)}</div>;
}
