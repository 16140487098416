import { useSelector } from "react-redux";
import logoB from "assets/images/icons/logoW.svg";
import heart from "assets/images/icons/heart1W.svg";
import AppServices from "services/app";
import { getIsPlatformWeb } from "redux/init/selectors";

export default () => {
	const isPlatformWeb = useSelector(getIsPlatformWeb);
	const handleOnClick = async (e) => {
		e.preventDefault();
		await AppServices.openInbrowser("https://www.date4dos.co.il/");
	};
	return (
		<div className="wrapMaintenance">
			<div className="wrapGradient">
				<div className="siteLogo">
					<img src={logoB} />
				</div>
				<div className="messageMain">
					<h3>אנחנו מעדכנים פיצ׳רים חדשים באפליקציה, נחזור תוך זמן קצר...</h3>
					<div className="heartWhite">
						<img src={heart} />
					</div>
					{!isPlatformWeb && (
						<>
							<h4 className="desktop">
								ניתן לגלוש לבינתיים באתר שלנו בכתובת https://www.date4dos.co.il/{" "}
							</h4>
							<a className="desktop" onClick={handleOnClick}>
								למעבר לאתר לחצו כאן
							</a>
						</>
					)}
				</div>
			</div>
		</div>
	);
};
