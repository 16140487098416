import alarmClock from "assets/images/speedDate/alarm-clock.png";
import alone from "assets/images/speedDate/alone.png";
import chat from "assets/images/speedDate/chat.png";
import click from "assets/images/speedDate/click.png";
import requirement from "assets/images/speedDate/requirement.png";
import ringing from "assets/images/speedDate/ringing.png";
import sofa from "assets/images/speedDate/sofa.png";
import stars from "assets/images/speedDate/stars.png";
import videoCall from "assets/images/speedDate/video-call.png";

const speedDateExplain = (price = 50) => [
	{
		text: "הספידייט של שליש גן עדן הוא אירוע המתקיים במרחב הוירטואלי - בבית שלך, או בכל מקום שבו אתה נמצא ישירות מהמכשיר הנייד שלך.",
		image: sofa,
	},
	{
		text: "ההרשמה לאירוע הקרוב ממש פשוטה ומתבצעת באופן עצמאי לחלוטין בלחיצה על הכפתור האדום כאן למטה.",
		image: click,
	},
	{
		text: "לאחר ההרשמה תקבל הודעה במייל ובאפליקציה האם אכן נמצאו לך התאמות לדייט לאירוע.",
		image: ringing,
	},
	{
		text: "במידה ונמצאו לך התאמות, תקבל קישור מיוחד במייל ובאפליקציה לכניסה לאירוע, ובו יוצג לך לוח הזמנים הייחודי שלך- מתי ועם מי יתקיים כל דייט.",
		image: requirement,
	},
	{
		text: "באירוע עצמו, כל משתתף מקבל הזדמנות לשוחח בוידאו עם משתתפים אחרים שעשויים להתאים לו.",
		image: videoCall,
	},
	{
		text: "הפגישות מתקיימות ברצף עם עד 6 דייטים פוטנציאליים, וכל דייט כזה נמשך 6 דקות בדיוק.",
		image: alarmClock,
	},
	{
		text: "בסוף כל דייט כזה נשאל אותך על מי שפגשת ועל מידת ההתאמה שלכם לדעתך.",
		image: stars,
	},
	{
		text: "בסיומו של ערב הספידייט המערכת שלנו תשכלל את התוצאות של כלל המשתתפים בערב הספידייט, ובמידה ויש עניין מ2 המשתתפים שנפגשו האחד עם השני, נעביר ביניכם את מספר הטלפון האחד של השני.",
		image: chat,
	},
	{
		text: `נרשמת ולא הגעת לאירוע הספידייט? קודם כל חבל, יש אחלה משתתפים, ודבר שני - ${
			price ? `מעבר לאובדן הכסף ששילמת עבור המפגש (${price} ש"ח) ` : ""
		}פגעת במשתתף אחר שחיכה במיוחד לפגישה איתך. `,
		image: alone,
	},
];

export default speedDateExplain;
