import { Box } from "@mui/material";
import giftCard from "assets/images/giftCard/giftCard.png";
import logoD4d from "assets/images/icons/logoD4dWhite.png";
export default function HeaderLandingGiftCard() {
	return (
		<Box className="headerLandingGiftCard">
			<img src={logoD4d} className="logo" />
			<span>{"רוצה לתת לאדם שחשוב לך"}</span>
			<b className="boldText">{"מתנה לכל החיים?"}</b>
			<img src={giftCard} className="giftCardImg" />
		</Box>
	);
}
