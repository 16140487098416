import { Button, ModalBody, Modal, ModalHeader, ModalFooter } from "reactstrap";
import usePopups from "hooks/usePopups";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getUserProfile } from "redux/profile/selectors";
import telegramService from "services/telegram.service";
import confirmTelegramSourceTypes from "consts/confirmTelegramSourceTypes";
import ProfileService from "services/profile";

export default () => {
	const { closePopup } = usePopups();
	const user = useSelector(getUserProfile);
	const { gender, uid } = user;
	const handleApprove = () => {
		telegramService.sendConfirmTelegram(confirmTelegramSourceTypes.popup, true);
		if (uid)
			ProfileService.updateProfile(uid, {
				isSendShowInTelegram: true,
			});
		closePopup();
	};

	return (
		<Modal isOpen={true} toggle={closePopup} className="simpleDialog enteredPopup">
			<div className="user-chat-nav ">
				<Button
					color="none"
					type="button"
					onClick={closePopup}
					className="nav-btn"
					id="user-profile-hide">
					<i className="ri-close-line"></i>
				</Button>
			</div>
			<ModalHeader style={{ marginTop: "0" }}>{`היי${
				user?.name ? ` ${user?.name} ❤️` : ""
			}`}</ModalHeader>

			<ModalBody>
				<div className="wrapBox">
					<b
						style={{
							fontSize: "24px",
							display: "inline-block",
							width: "100%",
							textAlign: "center",
						}}>{`הבטחנו שנעדכן על דברים חדשים… `}</b>
					<b
						style={{
							fontSize: "24px",
							display: "inline-block",
							width: "100%",
							textAlign: "center",
							marginBottom: "5px",
						}}>{`אז הנה זה קורה!! ובע-נ-ק`}</b>
					<b
						style={{
							display: "inline-block",
						}}>{`הסמארט בוט הדיגיטלי שלנו 😍 ממש עוד רגע עולה לאוויר!`}</b>
				</div>
				<div className="wrapBox">
					<b>{`בסמארט בוט החדש פיתחנו מערכת מיוחדת להתאמה - `}</b>
					<span>{`מערכת שלומדת אותך ומציעה לך בהתאם הצעות לדייט. ההצעות עוברות באמצעות תוכנות מסרים מידיים כדוגמת טלגרם, וואטסאפ, שם תקבלו שניכם פרטים, תמונה, וקישור ישיר לכרטיס המועמד/ת בשליש סמארט, ואם שניכם תסמנו שאתם בעניין 😁 `}</span>
					<span>{`נעביר ביניכם מספרי טלפון ותוכלו להתקדם הלאה… ✨ `}</span>
					<span
						style={{
							display: "inline-block",
						}}>{`אנחנו מאמינים שזה יכול לעשות שינוי משמעותי עבור המון אנשים!`}</span>
				</div>

				<div className="wrapBox">
					<span style={{ color: "#0bc1c7", fontWeight: "600" }}>{`אז מה עכשיו? `}</span>
					<span style={{ color: "#0bc1c7" }}>{`עכשיו פשוט ${gender ? "תהני" : "תהנה"} מהאפליקציה, ${
						gender ? "ותני" : "ותן"
					} לנו ללמוד אותך ולהציע לך כמה שיותר הצעות לשידוך - הצעות לדייט, שהכי מתאימות למה ${
						gender ? "שאת מחפשת" : "שאתה מחפש"
					}.`}</span>
				</div>
				<div className="wrapBox">
					<span
						style={{
							fontSize: "16px",
						}}>{`במידה ${gender ? "ותעדיפי" : "ותעדיף"} לוותר על שירותי הסמארט בוט שלנו, בכל שלב ${
						gender ? "תוכלי" : "תוכל"
					} להיכנס להגדרות שלך באמצעות לחיצה על "פרופיל" > "הגדרות מערכת" > "הגדרות נוספות", ושם לסמן שאינך ${
						gender ? "מעוניינת" : "מעוניין"
					} שנציע לאחרים להכיר אותך ו/או לקבל הצעות מהסמארט בוט בלחיצה על "הצעות שדכן אישי בסמארט בוט".`}</span>
				</div>
				<div className="wrapBox">
					<b>{`אנחנו כבר לא יכולים לחכות!! גם ${gender ? "את" : "אתה"}?? 😍`}</b>
				</div>
			</ModalBody>
			<ModalFooter>
				<Button
					outline
					size="sm"
					color="primary"
					onClick={handleApprove}
					className="btn-rounded littleBtn"
					style={{ fontSize: "20px" }}>
					{`${gender ? "מאשרת" : "מאשר"}, איזה התרגשות 😇`}
				</Button>
			</ModalFooter>
		</Modal>
	);
};
