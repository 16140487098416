import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, ModalBody, Input, ModalFooter } from "reactstrap";
import usePopups from "hooks/usePopups";
import { getPopups } from "redux/popups/selectors";
import { useState } from "react";
import { getUserProfile } from "redux/profile/selectors";
import { reportUser } from "redux/blocks/actions";
import i18next from "i18next";
import { CircularProgress } from "@mui/material";

export default function ReportVideoDateModal() {
	const dispatch = useDispatch();
	const { closePopup, showThanksReportUserPopup } = usePopups();
	const { data } = useSelector(getPopups);
	const [message, setMessage] = useState("");
	const [loading, setLoading] = useState(false);
	const { gender, email } = useSelector(getUserProfile);
	const MAX_LENGTH = 140;

	const sendReport = () => {
		if (!data?.matchUser.uid) return;
		setLoading(true);

		dispatch(reportUser(data?.matchUser.uid, "Report Reasons.VideoDate", message, email ?? null));
		setLoading(false);
		closePopup();
		showThanksReportUserPopup();
	};
	return (
		<Modal isOpen={true} toggle={closePopup} className="simpleDialog ">
			<div className="user-chat-nav ">
				<Button
					color="none"
					type="button"
					onClick={closePopup}
					className="nav-btn"
					id="user-profile-hide">
					<i className="ri-close-line"></i>
				</Button>
			</div>
			<ModalBody className="">
				<div className="wrapTextDecoration">
					<span>{`${!gender ? "דווחי" : "דווח"} על ${data?.matchUser?.name ?? ""}`}</span>
					<div className="inputAndQuote grow-wrap" data-replicated-value={message}>
						<Input
							value={message}
							onChange={(e) => setMessage(e.target.value)}
							placeholder={`פירוט`}
							type="textarea"
							rows={4}
							maxLength={MAX_LENGTH}
						/>
					</div>
				</div>
			</ModalBody>
			<ModalFooter>
				{loading ? (
					<CircularProgress size={40} style={{ color: "#2d5086" }} />
				) : (
					<Button disabled={message.trim()?.length < 2} onClick={sendReport}>
						{`${!!gender ? "שלחי" : "שלח"}`}
					</Button>
				)}
			</ModalFooter>
		</Modal>
	);
}
