import { Button } from "reactstrap";

export default ({ message, onClick, classImg }) => {
	return (
		<>
			<Button
				outline
				className={`${classImg}`}
				color="primary"
				onClick={() => onClick(message, classImg)}>
				<div className="qute"></div>
				<div className="text">{message}</div>
			</Button>
		</>
	);
};
