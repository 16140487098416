import * as types from "./actionTypes";
import { getLoggedInUserUID } from "../auth/selectors";

import QueueService from "services/queue";
import QueueEvents from "consts/baseChatEvents";
import PromotionService from "services/promotion";

export const openPromotion = (colleagueUid, isUnViewed) => {
	return (dispatch, getState) => {
		const state = getState();
		const loggedInUserUID = getLoggedInUserUID(state);
		if (isUnViewed) {
			QueueService.sendEvent(loggedInUserUID, QueueEvents.OpenPromotion, colleagueUid);
			dispatch({
				type: types.PROMOTIONS_VIEWED,
				colleagueUid,
			});
		}
	};
};

export const virtualDeletePromotion = (colleagueUid) => {
	PromotionService.virtualDeletePromotion(colleagueUid);

	return {
		type: types.PROMOTIONS_CHAT_RECEIVED_VIRTUAL_DELETE_CHAT,
		payload: colleagueUid,
	};
};
