import { useDispatch, useSelector } from "react-redux";

import { closePopupUnderPopup, closeSecondPopupUnderPopup, showPopup } from "redux/popups/actions";
import PopupsTypes from "consts/popupsTypes";
import { getUserProfile } from "redux/profile/selectors";
import usePopups from "./usePopups";
import { getIsPlatformAndroid, getIsPlatformIOS, getIsPlatformWeb } from "redux/init/selectors";

const useHelper = () => {
	const dispatch = useDispatch();
	const currentUser = useSelector(getUserProfile);
	const isPlatformAndroid = useSelector(getIsPlatformAndroid);
	const isPlatformIOS = useSelector(getIsPlatformIOS);
	const isPlatformWeb = useSelector(getIsPlatformWeb);
	const {
		showUserCardPopup,
		showMobileStorePopup,
		showForceUploadImagePopup,
		showForceShareFriends,
	} = usePopups();
	const showUserDetails = () => {
		const openApp = async () => {
			if (!isPlatformIOS && !isPlatformAndroid) {
				if (isPlatformWeb) return true;
				showMobileStorePopup({ title: "הורד את האפלקציה על  מנת לראות דף זה" });
			} else {
				//showForceShareFriends({});
			}
		};
		const imageProfile = currentUser.pictures?.[currentUser.mainPictureName]?.url || "";

		if (!imageProfile) {
			showForceUploadImagePopup({ name: currentUser?.name });
		} else if (!currentUser.hasContacts) {
			return openApp();
		} else {
			return true;
			//dispatch(viewUser(user.uid));
			//!isMobile() ? showUserCardPopup(user) : showUserProfileSideBar(user);
		}
	};

	return {
		showUserDetails,
	};
};

export default useHelper;
