import { mergeMap, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";
import { of, map, first, repeat, takeUntil } from "rxjs";

import PromotionService from "services/promotion";
import * as types from "./actionTypes";
import { getLoggedInUserUID } from "redux/auth/selectors";
import AdminActionService from "services/adminAction";
import { purchaseWithCardcomSuccessfully } from "./actions";
import purchaseService from "services/purchase.service";
import purchaseTypes from "consts/purchaseTypes";

export const fetchPromotionCardCom = (action$) =>
	action$.pipe(
		ofType(types.PROMOTIONS_SALE_PURCHASE_WITH_CARDCOM),
		mergeMap(async ({ plan, showSuccessfullyPopup }) => {
			try {
				const response = (await PromotionService.getPromotionPay(plan?.key))?.data;
				purchaseService.updatePurchaseIsStart(purchaseTypes.promotion);

				return {
					type: types.PROMOTIONS_SALE_GET_CARDCOM_RESPONSE_SUCCESSFULLY,
					response,
					showSuccessfullyPopup,
				};
			} catch (error) {
				return {
					type: types.PROMOTIONS_SALE_GET_CARDCOM_RESPONSE_FAILED,
					payload: error?.message,
				};
			}
		}),
		catchError((error) => {
			return of({
				type: types.PROMOTIONS_SALE_GET_CARDCOM_RESPONSE_FAILED,
				payload: error?.message,
			});
		}),
		repeat()
	);

export const listenerPromotionCardcomPurchase = (action$, store) =>
	action$.pipe(
		ofType(types.PROMOTIONS_SALE_GET_CARDCOM_RESPONSE_SUCCESSFULLY),
		mergeMap(({ response, showSuccessfullyPopup }) => {
			try {
				const state = store.value;
				const userUid = getLoggedInUserUID(state);
				return AdminActionService._listenToResponse(userUid, response.responseKey, true).pipe(
					first(),
					map((finishPurchaseResponse) => {
						return purchaseWithCardcomSuccessfully(finishPurchaseResponse, showSuccessfullyPopup);
					})
				);
			} catch (error) {
				return {
					type: types.PROMOTIONS_SALE_CARDCOM_PURCHASE_FAILED,
					payload: error?.message,
				};
			}
		}),
		catchError((error) => {
			return of({
				type: types.PROMOTIONS_SALE_CARDCOM_PURCHASE_FAILED,
				payload: error?.message,
			});
		}),
		repeat()
	);
