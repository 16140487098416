import { Box } from "@mui/material";
import FooterBtn from "../FooterBtn";
import { useState } from "react";
import moment from "moment";
import usePopups from "hooks/usePopups";
import i18next from "i18next";
import { Button } from "reactstrap";
import useNavigator from "hooks/useNavigator";
import userSpeedDateStatus from "consts/userSpeedDateStatus";

export default function ConfirmParticipation({ speedData = {}, userData = {} }) {
	const { showFittedMessagePopup, showCancelVideoDateRegisterPopup } = usePopups();
	const { navigateToPath } = useNavigator();

	const [loading, setLoading] = useState(false);

	const checkIfItsInHalfHour = () => {
		const givenTime = moment(speedData?.eventDate);
		const currentTime = moment();
		const timeAfter30Minutes = moment().add(30, "minutes");

		const isWithin30Minutes = givenTime.isBetween(currentTime, timeAfter30Minutes);
		const datesStart = currentTime.isAfter(givenTime);

		return isWithin30Minutes || datesStart;
	};

	const EntryEvent = () => {
		const itsTime = checkIfItsInHalfHour();
		if (itsTime) {
			return navigateToPath(`/speedDating/${speedData?.urlName}`);
		} else {
			showFittedMessagePopup(i18next.t("speedDate.link will open half an hour before the event"));
		}
		setLoading(false);
	};

	const openCancelParticipantPopup = () => {
		const givenTime = moment(speedData?.endUsersRegister);
		const currentTime = moment();
		const canCancelRegister = givenTime.isAfter(currentTime);

		if (canCancelRegister) {
			showCancelVideoDateRegisterPopup(speedData);
		} else {
			showFittedMessagePopup(
				`${i18next.t("speedDate.deadlineCancellation")}${givenTime.format("D/M/YY בשעה HH:mm")}`
			);
		}
		setLoading(false);
	};

	const handleClickEntry = () => {
		setLoading(true);
		EntryEvent();
	};

	return (
		<>
			<h3 className="title">{i18next.t("speedDate.howExciting")}</h3>

			<Box className="content">
				<Box>{i18next.t("speedDate.confirmed your participation in the upcoming event")}</Box>
				<Box className="bold">{moment(speedData?.eventDate).format("D/M/YY בשעה HH:mm")}</Box>
				<Box
					style={{
						marginTop: "0.7rem",
					}}>
					{i18next.t("speedDate.link sent to your email and available here")}
				</Box>
			</Box>
			<Box className="footer">
				<FooterBtn
					handleClick={handleClickEntry}
					text={i18next.t("speedDate.enterEvent>")}
					loading={loading}
				/>
				{userData?.status === userSpeedDateStatus.confirmed &&
					moment().isBefore(moment(speedData?.endUsersRegister)) && (
						<Button
							className="cancelParticipant"
							color="none"
							type="button"
							onClick={openCancelParticipantPopup}>
							<span>{i18next.t("speedDate.toCancelParticipation")}</span>
						</Button>
					)}
			</Box>
		</>
	);
}
