import { mergeMap, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";
import { of } from "rxjs";
import { getLoggedInUserUID } from "../auth/selectors";

import * as types from "./actionTypes";
import * as authTypes from "../auth/actionTypes";

import SubscriptionCallService from "services/subscriptionCall";

export const fetchCanReadSubscription = (action$, store) =>
	action$.pipe(
		ofType(authTypes.AUTH_LOGGED_IN, types.ENTER_CHAT_PAGE),
		mergeMap(async () => {
			try {
				const payload = await SubscriptionCallService.getCanRead();
				if (payload?.success && payload?.isReadSub) {
					return {
						type: types.ACTIVE_USER_CAN_READ,
						payload: {},
					};
				}
				return {
					type: types.ACTIVE_USER_CAN_READ_FAILED,
					payload: {},
				};
			} catch (error) {
				return {
					type: types.ACTIVE_USER_CAN_READ_FAILED,
					error: error?.message,
				};
			}
		}),
		catchError((error) => {
			return of({
				type: types.ACTIVE_USER_CAN_READ_FAILED,
				payload: error?.message,
			});
		})
	);
