import { map, mergeMap, repeat } from "rxjs/operators";
import { ofType } from "redux-observable";

import * as authTypes from "redux/auth/actionTypes";
import * as types from "./actionTypes";

import contactsService from "services/contactsService";
import { getLoggedInUserUID } from "../auth/selectors";
import { getHasContact, getUserProfile } from "redux/profile/selectors";
import { PROFILE_FETCH_PROFILE_SUCCESSFULLY } from "../profile/actionTypes";
import locationService from "../../services/locationService";
import { updateProfile } from "redux/profile/actions";
import profileFields from "consts/profileFields";

export const setLocation = (action$, store) => {
	return action$.pipe(
		ofType(PROFILE_FETCH_PROFILE_SUCCESSFULLY),
		mergeMap(async () => {
			try {
				const state = store.value;
				const profile = getUserProfile(state);
				if (
					!!profile.uid &&
					(!profile.location ||
						!profile?.location?.created ||
						profile?.location?.created + 2 * 60 * 1000 < Date.now())
				) {
					const location = await locationService.setLocation(profile.uid);

					return {
						type: "setLocation",
						payload: location,
					};
				} else {
					return {
						type: "setLocation",
						payload: [],
					};
				}
			} catch (error) {
				console.log(error);
				return {
					type: "error",
					error: error?.message,
				};
			}
		})
	);
};
