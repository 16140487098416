import "assets/scss/giftCard/giftCard.scss";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import giftCard from "assets/images/giftCard/giftCard.png";
import { useHistory, useLocation, useParams } from "react-router-dom";
import moment from "moment";
import useNavigator from "hooks/useNavigator";
import { Button } from "reactstrap";
import anonymousCall from "services/anonymousCall";

export default function ThanksGiftCardPage() {
	const { navigateToReceivedGiftCard } = useNavigator();
	const history = useHistory();
	const location = useLocation();
	const stateData = location.state;
	const [data, setData] = useState();
	let { keyGiftCard } = useParams();

	const handleClickNextBtn = () => {
		history.replace({
			pathname: location.pathname, // Stay on the same path
			state: null, // Clear the state
		});
		return navigateToReceivedGiftCard({ keyGiftCard: data?.keyGiftCard || keyGiftCard, p: "t" });
	};

	useEffect(async () => {
		window.scrollTo(0, 0);

		if (stateData) {
			setData(stateData);
		}
		if (keyGiftCard) {
			const res = await anonymousCall.getGiftCardBlessing(keyGiftCard);
			if (res?.success) {
				setData(res?.data);
			}
		}
	}, []);

	return (
		<Box className="thanksGiftCardPage">
			<img src={giftCard} className="giftCardImg" />
			<Box className="contentThanks">
				<h2 className="blessingBox" style={{ fontWeight: "bold", fontSize: "25px" }}>
					{"הרכישה בוצעה בהצלחה !"}
				</h2>
				<Box className="blessingBox">
					<Box>
						<span>{`המתנה שלך תישלח ל`}</span>
						<b>{data?.name_receiver}</b>
					</Box>
					{!!data?.methods_send_gift?.includes("sms") && (
						<Box>
							<span>{`למספר `}</span>
							<b>{data?.phone_receiver}</b>
						</Box>
					)}
					{!!data?.methods_send_gift?.includes("email") && (
						<Box>
							<span>{`למייל `}</span>
							<b>{data?.email_receiver}</b>
						</Box>
					)}
					<Box>
						<span>{`בתאריך `}</span>
						<b>{moment(data?.scheduled_date).format("DD.MM.YYYY בשעה HH:mm")}</b>
					</Box>
				</Box>

				{!!data?.blessing && (
					<Box className="yourBlessing">
						<span>{"בצירוף הברכה שלך:"}</span>

						<Box className="blessing">{data?.blessing}</Box>
					</Box>
				)}

				<h2>
					<span>{"מרגש"}</span>
					<b>{"נכון?"}</b>
				</h2>

				<Button className="nextBtn" onClick={handleClickNextBtn}>
					<span>{"להדפסת המתנה"}</span>
				</Button>
			</Box>
		</Box>
	);
}
