import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { App } from "@capacitor/app";
import AppService from "services/app";
import firebase from "firebase/app";
import "firebase/database";
import AppPlatforms from "consts/AppPlatforms";

const checkStateApp = async () => {
	const platform = await AppService.getPlatform();

	if (platform === AppPlatforms.IOS) {
		App.addListener("appStateChange", ({ isActive }) => {
			// Handle state change
			console.log("App state changed:", isActive ? "active" : "inactive");
			if (isActive) {
				firebase.database().goOnline();
			} else {
				firebase.database().goOffline();
			}
			// Perform actions based on the state change
		});
	}
};
const AppUrlListener = () => {
	let history = useHistory();
	useEffect(() => {
		App.addListener("appUrlOpen", (event) => {
			const slug = event.url.split(process.env.REACT_APP_DOMAIN).pop();
			if (slug) {
				history.push(slug);
			}
		});
	}, []);

	useEffect(() => {
		checkStateApp();
	}, []);

	return null;
};

export default AppUrlListener;
