import { Button, ButtonGroup } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import i18next from "i18next";
import { useEffect, useState } from "react";
import _ from "lodash";

import { getSearchFreeTextFilter, getUpdateRedPoint } from "redux/search/selectors";
import {
	getSearchFilters,
	setSearchFilters,
	getFiltersIsEmpty,
	updateRedPoint,
} from "redux/search/actions";
import { search } from "redux/search/actions";
import Age from "./SearchFiltersInputs/Age";
import Height from "./SearchFiltersInputs/Height";
import MaritalStatus from "./SearchFiltersInputs/MaritalStatus";
import { getUserProfile, getUserProfileWithDefaultVal } from "redux/profile/selectors";
import Children from "./SearchFiltersInputs/Children";
import Stance from "./SearchFiltersInputs/Stance";
import Range from "./SearchFiltersInputs/Range";
import Collapse from "components/Collapse";
import ProfileFields from "consts/profileFields";
import SearchFilterKeys from "consts/searchFilterKeys";
import PrayerTime from "consts/prayerTime";
import Origin from "consts/origin";
import Education from "consts/education";
import Smoking from "consts/smoking";
import MultiSelect from "components/MultiSelect";
import Gender from "consts/gender";
import StanceOptions from "consts/stance";
import { updateProfile } from "redux/profile/actions";
import profileFields from "consts/profileFields";

export default ({ scrollToTop, searchLocation, setSearchLocation }) => {
	const [filters, setFilters] = useState({});
	const freeText = useSelector(getSearchFreeTextFilter);
	const profile = useSelector(getUserProfileWithDefaultVal);
	const filtersIsEmpty = getFiltersIsEmpty(profile);
	const __ = useSelector(getUpdateRedPoint); //In order to render this component after closing searchBar and update red point

	const dispatch = useDispatch();

	useEffect(() => {
		const initialFilters = _.isEmpty(getSearchFilters())
			? profile?.searchFilters || {}
			: getSearchFilters();
		setFilters(initialFilters);
	}, []);

	useEffect(() => {
		if (_.isEmpty(filters) || !profile?.uid) return;
		setSearchFilters(filters);
		dispatch(updateRedPoint());
	}, [filters]);

	const hideSearchFilterBar = () => {
		document.querySelector(".sideBarSearchFilter").classList.remove("showSearchFilterSlide");
		document.querySelector(".dimmer").classList.remove("showDimmer");
		document.querySelector(".toBlur").classList.remove("blurMe");
		document.querySelector(".header").classList.remove("blurMe");
		document.querySelector("body").classList.remove("modal-open");
		//document.querySelector("#root").classList.remove("fixedBackground");
		dispatch(updateRedPoint());
	};

	const onChangeFilter = (key, value) => {
		const newVal = _.isArray(value) && value.length === 0 ? undefined : value;
		setFilters({
			...filters,
			[key]: newVal,
		});
	};

	const handleCleanFilter = () => {
		setFilters({});
		setSearchFilters({});
		onSearch(false);
		dispatch(updateProfile(profileFields.SearchFilters, {}));
	};

	const onChangeSearchLocation = (newLoc) => {
		setSearchLocation(newLoc);
	};

	const onSearch = (hidePandel = true) => {
		dispatch(search(freeText, null, searchLocation));
		hidePandel && hideSearchFilterBar();
		scrollToTop();
		dispatch(updateProfile(profileFields.SearchFilters, filters));
	};

	return (
		<>
			<div className="searchFilterWrapper ">
				<div className="section fixed titleWithBtn">
					<Button
						disabled={filtersIsEmpty}
						color=""
						className="clearFilter"
						onClick={handleCleanFilter}>
						{i18next.t("Search.clean")}
						{!filtersIsEmpty && <span className="dot"></span>}
					</Button>
					<h4>{i18next.t("Search.Filter settings")}</h4>
					<div className="closePanel">
						<div className="user-chat-nav ">
							<Button
								color="none"
								type="button"
								onClick={hideSearchFilterBar}
								className="nav-btn"
								id="user-profile-hide">
								<i className="ri-close-line"></i>
							</Button>
						</div>
					</div>
				</div>
				<div className="wrapInnerFilterSearch">
					<ButtonGroup style={{ display: "none" }}>
						<Button active="true" color="primary">
							Basic
						</Button>
						<Button color="light"> Advanced </Button>
					</ButtonGroup>

					<Age onChange={onChangeFilter} profile={profile} filter={filters[SearchFilterKeys.Age]} />
					<Height onChange={onChangeFilter} filter={filters[SearchFilterKeys.Height]} />
					<MaritalStatus
						onChange={onChangeFilter}
						profile={profile}
						filter={filters[SearchFilterKeys.MaritalStatus]}
					/>
					<Children onChange={onChangeFilter} filter={filters[SearchFilterKeys.Children]} />
					<Stance
						onChange={onChangeFilter}
						profile={profile}
						filter={filters[SearchFilterKeys.Stance]}
					/>
					{(profile.location || profile[ProfileFields.CityLoc]) && (
						<Range
							onChange={onChangeFilter}
							filter={filters[SearchFilterKeys.Range]}
							onChangeSearchLocation={onChangeSearchLocation}
						/>
					)}
					{/* <Collapse
						title={i18next.t("Search.Additional Filters")}
						className="moreFilters "
						collapsHeight={0}>
						{profile.gender === Gender.Female &&
							profile.stance !== StanceOptions.Datlash &&
							profile.stance !== StanceOptions.Mesorati && (
								<MultiSelect
									className="wrapFilterSelect"
									field={SearchFilterKeys.PrayerTime}
									fieldOptions={Object.values(PrayerTime)}
									onChange={onChangeFilter}
									initSelected={filters.PrayerTime}
									title={i18next.t("Edit Profile.PrayerTime")}
									translatePrefix="PrayerTime"
								/>
							)}

						<MultiSelect
							className="wrapFilterSelect"
							field={SearchFilterKeys.Origin}
							fieldOptions={Object.values(Origin)}
							onChange={onChangeFilter}
							initSelected={filters[SearchFilterKeys.Origin]}
							title={i18next.t("Edit Profile.Origin")}
							translatePrefix={`Origin_${profile.gender ? 0 : 1}`}
						/>
						<MultiSelect
							className="wrapFilterSelect"
							field={SearchFilterKeys.Education}
							fieldOptions={Object.values(Education)}
							onChange={onChangeFilter}
							initSelected={filters[SearchFilterKeys.Education]}
							title={i18next.t("Edit Profile.Education")}
							translatePrefix="Education"
						/>
						<MultiSelect
							className="wrapFilterSelect"
							field={SearchFilterKeys.Smoking}
							fieldOptions={Object.values(Smoking)}
							onChange={onChangeFilter}
							initSelected={filters[SearchFilterKeys.Education]}
							title={i18next.t("Edit Profile.Smoking")}
							translatePrefix={`Smoking_${profile.gender ? 0 : 1}`}
						/>
					</Collapse> */}
				</div>
			</div>
			<div className="section fixed bottom">
				<Button
					onClick={onSearch}
					size="lg"
					color="secondary"
					className="mainAccountBtn "
					style={{ whiteSpace: !profile?.isDefaultProfile && "nowrap" }}>
					{profile?.isDefaultProfile
						? "לתוצאות נוספות"
						: i18next.t(`Search.Search_${profile.gender}`)}
				</Button>
			</div>
		</>
	);
};
