import { Button } from "reactstrap";
import i18next from "i18next";
import { useSelector } from "react-redux";

import ProfileFields from "consts/profileFields";
import currentLanguage from "helpers/currentLanguage";
import { getAgeByDob } from "helpers/date";
import share from "assets/images/icons/share.svg";
import usePopups from "hooks/usePopups";
import UserStatus from "./UserStatus";
import { isUserOnline } from "redux/presence/selectors";
import { getUserProfile } from "redux/profile/selectors";
import { isEmpty } from "lodash";

export default ({ profile, className = "" }) => {
	const city = profile[ProfileFields.Cites[currentLanguage()]];
	const myUser = useSelector(getUserProfile);

	const isOnline = useSelector(isUserOnline(profile.uid));

	const shareUser = async () => {
		const myId = !!myUser?.internalID ? myUser?.internalID : myUser?.uid;
		const long_city = profile[ProfileFields.Cites[currentLanguage()]];
		const city = long_city?.split(",")[0];
		if (!!profile?.uid) {
			const text = `היי מה קורה?😉\nיש פה כרטיס בדיוק עבורך באפליקציית ההכרויות של ${
				process.env.REACT_APP_ENV_NAME
			} :\n${profile?.name}, ${i18next.t(`Stance_${profile.gender || 0}.${profile.stance}`)}, ${
				!!profile?.gender ? "בת" : "בן"
			} ${profile.age || getAgeByDob(profile.dob) || ""} מ${
				!!city ? city : ""
			} 😍\nמה דעתך?\n\nלצפיה בכרטיס :\n ${process.env.REACT_APP_DOMAIN}/user/${profile?.uid}?f=${
				myId ?? ""
			}`;

			const shareUrl = `https://api.whatsapp.com/send?text=${text}`;
			return window.open(shareUrl);
		}
	};

	return (
		<div className={className}>
			<div className="detailsProfile">
				<span color="primary" className="font-size-24 mb-1 text-truncate jsmartProfileName">
					{profile.name}
				</span>
				<span color="primary" className="font-size-23 mb-1 text-truncate jsmartProfileName">
					{profile.age || getAgeByDob(profile.dob)}
				</span>
				<Button onClick={() => shareUser()} className="shareButtonProfile">
					<img src={share} alt="main picture" />
				</Button>
			</div>
			<div className="detailsProfile">
				<UserStatus isOnline={isOnline} />
				<p color="primary" className="font-size-14 mb-1 text-truncate ">
					{i18next.t(`Stance_${profile.gender || 0}.${profile.stance}`)}
				</p>
				<p color="primary" className="font-size-14 mb-1 text-truncate city">
					{city && ", " + city}
				</p>
			</div>
		</div>
	);
};
