import { mergeMap, map, takeUntil, catchError, repeat, filter } from "rxjs/operators";
import { concat, from, of } from "rxjs";
import { ofType } from "redux-observable";
import firebase from "firebase/app";
import "firebase/auth";
import moment from "moment";
import { isNil } from "lodash";

import { getFacebookAccessToken, getLoggedInUserUID, getLoginPhone } from "../auth/selectors";
import * as AuthTypes from "../auth/actionTypes";
import * as types from "./actionTypes";
import ProfileService from "services/profile";
import QueueService from "services/queue";
import ProfileEvents from "consts/profileEvents";
import UserStatus from "consts/userStatus";
import { getMainPicture, getNotRejectedPictures, getPictures, getUserProfile } from "./selectors";
import ProfileFields from "consts/profileFields";
import { getUserGeneralStatus } from "../general/selectors";
import { autoUpdateChildrenCount, autoUpdateKeepShabbat, autoUpdateModestyFilter } from "./actions";
import LocationService from "services/location";
import { updateProfile } from "redux/profile/actions";

export const fetchProfileEffect = (action$, store) =>
	action$.pipe(
		ofType(AuthTypes.AUTH_LOGGED_IN),
		mergeMap(() => {
			const state = store.value;
			const loggedInUserUID = getLoggedInUserUID(state);
			return ProfileService.fetchUserProfile(loggedInUserUID).pipe(
				map((profile) => ({
					type: types.PROFILE_FETCH_PROFILE_SUCCESSFULLY,
					profile,
				}))
			);
		}),
		takeUntil(action$.pipe(ofType(AuthTypes.AUTH_LOG_OUT))),
		catchError((error) => {
			return of({
				type: types.PROFILE_FETCH_PROFILE_FAILED,
				payload: error?.message,
			});
		}),
		repeat()
	);

export const updateProfileEffect = (action$, store) =>
	action$.pipe(
		ofType(
			types.PROFILE_UPDATE_PROFILE,
			types.PROFILE_GET_FACEBOOK_PROFILE_DATA_SUCCESSFULLY,
			AuthTypes.AUTH_REGISTER_WITH_GOOGLE_SUCCESSFULLY
		),
		mergeMap(({ user, oldValue }) => {
			const state = store.value;
			const loggedInUserUID = getLoggedInUserUID(state);
			const profile = getUserProfile(state);
			const userStatus = getUserGeneralStatus(state);
			const fieldName = Object.keys(user)[0];
			const newVal = user[fieldName];
			try {
				if (sendSaveProfileDetail(fieldName, userStatus)) {
					QueueService.sendEvent(loggedInUserUID, ProfileEvents.SaveProfileDetail, {
						fieldName,
						newVal,
						oldVal: oldValue || null,
					});
				}
				const updateProfile$ = from(ProfileService.updateProfile(loggedInUserUID, user)).pipe(
					map((payload) => ({
						type: types.PROFILE_UPDATE_PROFILE_SUCCESSFULLY,
						payload,
					}))
				);
				return concat(
					updateProfile$,
					autoUpdateKeepShabbat(profile, fieldName, newVal),
					autoUpdateModestyFilter(fieldName, newVal),
					autoUpdateChildrenCount(fieldName, newVal)
				);
			} catch (error) {
				return {
					type: types.PROFILE_UPDATE_PROFILE_FAILED,
					error: error?.message,
				};
			}
		}),
		catchError((error) => {
			return of({
				type: types.PROFILE_UPDATE_PROFILE_FAILED,
				payload: error?.message,
			});
		}),
		repeat()
	);

const sendSaveProfileDetail = (fieldName, userStatus) => {
	// For save pending field to that just registered
	const FIELDS_SKIP_QUEUE_EVENTS = [
		//ProfileFields.Name,
		ProfileFields.MaritalStatus,
		ProfileFields.Dob,
		ProfileFields.Gender,
		ProfileFields.Stance,
	];
	if (
		userStatus === UserStatus.PendingApproval &&
		FIELDS_SKIP_QUEUE_EVENTS.indexOf(fieldName) !== -1
	) {
		return false;
	}
	return true;
};

export const deleteImage = (action$, store) =>
	action$.pipe(
		ofType(types.PROFILE_DELETE_IMAGE),
		mergeMap(async ({ payload: imageKey }) => {
			const state = store.value;
			const userUid = getLoggedInUserUID(state);
			const pictures = getPictures(state);
			const imageData = pictures[imageKey];
			const notRejectedImages = getNotRejectedPictures(state);
			const mainPicture = getMainPicture(state);
			try {
				await ProfileService.deleteImage(userUid, imageKey, imageData);
				QueueService.sendEvent(userUid, ProfileEvents.DeleteUserPicture, imageKey);
				if (mainPicture === imageKey) {
					const newMainPicture = notRejectedImages.find((key) => key !== imageKey) || null;
					await ProfileService.setMainImage(newMainPicture);
				}
				return {
					type: types.PROFILE_DELETE_IMAGE_SUCCESSFULLY,
					imageKey,
				};
			} catch (error) {
				return {
					type: types.PROFILE_DELETE_IMAGE_FAILED,
					error: error?.message,
					imageKey,
				};
			}
		})
	);

export const updateUserPhone = (action$, store) =>
	action$.pipe(
		ofType(AuthTypes.AUTH_LOGIN_WITH_PHONE_VERIFICATION_SUCCESSFULLY),
		mergeMap(async () => {
			const state = store.value;
			const userUid = getLoggedInUserUID(state);
			const userPhone = getLoginPhone(state).replace(/\s0*/g, "");
			try {
				await ProfileService.updateProfile(userUid, { uid: userUid, phone: userPhone });
				return {
					type: types.PROFILE_UPDATE_PROFILE_SUCCESSFULLY,
					payload: { profileName: "phone", value: userPhone },
				};
			} catch (error) {
				return {
					type: types.PROFILE_UPDATE_PROFILE_FAILED,
					error: error?.message,
				};
			}
		}),
		catchError((error) => {
			return of({
				type: types.PROFILE_UPDATE_PROFILE_FAILED,
				payload: error?.message,
			});
		})
	);

export const addImage = (action$, store) =>
	action$.pipe(
		ofType(types.PROFILE_UPDATE_IMAGE),
		mergeMap(async ({ payload }) => {
			const { file } = payload;
			const state = store.value;
			const userUid = getLoggedInUserUID(state);

			try {
				const image = await ProfileService.addImage(userUid, file, false);
				QueueService.sendEvent(userUid, ProfileEvents.SavePicture, {
					name: image.name,
					url: image.url,
					isMain: true,
				});
				return {
					type: types.PROFILE_UPDATE_IMAGE_SUCCESSFULLY,
					payload,
					image,
				};
			} catch (error) {
				return {
					type: types.PROFILE_UPDATE_IMAGE_FAILED,
					error: error?.message,
				};
			}
		})
	);

export const saveImage = (action$, store) =>
	action$.pipe(
		ofType(types.PROFILE_SAVE_EDITED_IMAGE),
		mergeMap(async ({ path, key }) => {
			const state = store.value;
			const userUid = getLoggedInUserUID(state);
			const mainPictureKey = getMainPicture(state);
			try {
				const url = await ProfileService.saveEditImage(userUid, key, path);
				QueueService.sendEvent(userUid, ProfileEvents.SavePicture, {
					name: key,
					url: url,
					isMain: mainPictureKey === key,
				});
				return {
					type: types.PROFILE_SAVE_EDITED_IMAGE_SUCCESSFULLY,
					key,
				};
			} catch (error) {
				return {
					type: types.PROFILE_SAVE_EDITED_IMAGE_FAILED,
					error: error?.message,
					key,
				};
			}
		})
	);

export const updateLocation = (action$, store) =>
	action$.pipe(
		ofType(types.PROFILE_FETCH_PROFILE_SUCCESSFULLY),
		mergeMap(({ profile }) => {
			console.log("2", Date.now());
			/* return LocationService.getCurrentLocation().pipe(
				map((location) => {
					if (location.lat !== profile.location?.lat || location.lng !== profile.location?.lng) {
						return updateProfile(ProfileFields.Location, location);
					}
					return {
						type: types.PROFILE_LOCATION_EXISTS,
					};
				})
			); */
			return {
				type: types.PROFILE_LOCATION_EXISTS,
			};
		}),
		catchError((error) => {
			return of({
				type: types.PROFILE_UPDATE_PROFILE_FAILED,
				payload: error,
			});
		})
	);

export const updateProfileRegisterWithFacebookEffect = (action$, store) =>
	action$.pipe(
		ofType(AuthTypes.AUTH_REGISTER_WITH_FACEBOOK_SUCCESSFULLY),
		mergeMap(async ({ payload }) => {
			const state = store.value;
			const facebookAccessToken = getFacebookAccessToken(state);
			try {
				const { name, email, birthday, id, picture } = payload.facebookData;
				const profile = {
					[ProfileFields.FacebookName]: name || null,
					[ProfileFields.Email]: email || null,
					[ProfileFields.FacebookEmail]: email || null,
					[ProfileFields.Auth_UserID]: id,
					[ProfileFields.FacebookDob]: birthday ? moment(birthday).unix() : null,
				};
				return {
					type: types.PROFILE_GET_FACEBOOK_PROFILE_DATA_SUCCESSFULLY,
					user: {
						...profile,
						[ProfileFields.FbToken]: facebookAccessToken,
					},
					picture,
				};
			} catch (error) {
				return {
					type: types.PROFILE_GET_FACEBOOK_PROFILE_DATA_FAILED,
					error: error?.message,
				};
			}
		})
	);

export const updateUserProviders = (action$) =>
	action$.pipe(
		ofType(
			AuthTypes.AUTH_REGISTER_WITH_FACEBOOK_SUCCESSFULLY,
			AuthTypes.AUTH_REGISTER_WITH_GOOGLE_SUCCESSFULLY
		),
		map(() => {
			const providers =
				firebase.auth().currentUser?.providerData?.map((provider) => provider.providerId) || null;
			return updateProfile(ProfileFields.Providers, providers);
		}),
		catchError((error) => {
			return of({
				type: types.UPDATE_USER_PROVIDERS_FAILED,
				payload: error?.message,
			});
		}),
		repeat()
	);

export const setFirstImageAsMain = (action$, store) =>
	action$.pipe(
		ofType(
			types.PROFILE_UPDATE_IMAGE_SUCCESSFULLY,
			types.PROFILE_IMPORT_FACEBOOK_IMAGE_SUCCESSFULLY
		),
		filter(() => {
			const state = store.value;
			const pictures = getPictures(state);
			const mainPicture = getMainPicture(state);
			const hasPictures = !isNil(pictures);
			const hasNoMainPicture = isNil(mainPicture);
			return (hasPictures && hasNoMainPicture) || isNil(pictures[mainPicture]);
		}),
		mergeMap(async (action) => {
			const mainImageName = action.image.name;
			try {
				await ProfileService.setMainImage(mainImageName);
				return {
					type: types.PROFILE_UPDATE_PROFILE_MAIN_PICTURE_SUCCESSFULLY,
					mainImageName,
				};
			} catch (error) {
				return {
					type: types.PROFILE_UPDATE_PROFILE_MAIN_PICTURE_FAILED,
					mainImageName,
					error: error?.message,
				};
			}
		})
	);
