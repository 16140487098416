import { Box, SwipeableDrawer } from "@mui/material";
import { useState, useEffect } from "react";
import ExplanationSpeedDate from "pages/ExplanationSpeedDate";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
export default function ExplainDrawer({ speedData = {} }) {
	const [isOpen, setIsOpen] = useState(false);
	const [drawerOpen, setDrawerOpen] = useState(false);

	const setIfDrawerOpen = (open) => {
		if (open) return setDrawerOpen(open);
		setTimeout(() => {
			setDrawerOpen(open);
		}, 200);
	};

	const toggleDrawer = (event, open) => {
		if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
			return;
		}
		setIsOpen(open);
		setIfDrawerOpen(open);
	};

	return (
		<Box className="drawerVideoDate">
			<Box
				className="explainText"
				onClick={(e) => toggleDrawer(e, true)}>{`הסבר כללי ומידע על אירועי הוידאו דייט`}</Box>

			<SwipeableDrawer
				anchor={"bottom"}
				open={isOpen}
				onClose={(e) => toggleDrawer(e, false)}
				onOpen={(e) => toggleDrawer(e, true)}>
				<Box role="presentation" onKeyDown={(e) => toggleDrawer(e, false)}>
					<Box onClick={(e) => toggleDrawer(e, false)} className="puller">
						<KeyboardArrowDownIcon style={{ color: "#2d5086" }} />
					</Box>
					{drawerOpen && <ExplanationSpeedDate url={speedData?.urlName} isDrawer={true} />}
				</Box>
			</SwipeableDrawer>
		</Box>
	);
}
