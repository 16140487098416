import firebase from "firebase/app";
import "firebase/database";
import httpsCallable from "./httpsCallable";

class ChatRequestsService {
	BASE_REF = "chat_requests";

	async getCanSendMsg(chatId) {
		const result = await httpsCallable.call("canSendAnotherMsg", { chatId });
		return result;
	}
	async approveDenyChatRequest(chatId, approve) {
		const result = await httpsCallable.call("approveDenyChatRequest", { chatId, approve });
		return result;
	}
	async showChatRequest(chatId) {
		const result = await httpsCallable.call("showChatRequest", { chatId });
		return result;
	}
}

export default new ChatRequestsService();
